import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AdwayBoost, AdwayJob } from '../model/publishing-platform.interface';
import { SetupService } from './setup.service';
import { IJob } from '../model/job.interface';


@Injectable({ providedIn: 'root' })
export class AdwayService {

  constructor(
    private http: HttpClient,
    private setupService: SetupService
  ) {}

  getBoosts(): Observable<AdwayBoost[]> {
    return this.http.get<AdwayBoost[]>(`${environment.adwayBoosts}`);
  }

  validateAdwayForExistingJob(job: IJob, jobId: number): Observable<any> {
    return this.http.post<AdwayJob>(`${environment.adwayValidateExistingJob}/${jobId}`, job);
  }

  validateAdwayForNewJob(job: IJob): Observable<any> {
    return this.http.post<any>(`${environment.adwayValidateNewJob}`, {...job, company: this.setupService.companyId});
  }
}
