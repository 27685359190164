<div class="perform-action-dialog"
     *ngIf="!message">
  <div class="action-header">
    <h2>
      <span *ngIf="isCompanyTrigger">
        {{ 'STATUS_FLOW.ADD_TRIGGER.TITLE' | translate }}, {{ 'HR.STATUS' | translate }}: {{ companyTriggerHiringStatus | statusName }}
      </span>
      <span *ngIf="isJobTrigger">
        <span >{{ 'JOBS.MANAGE_TRIGGERS' | translate }}</span>
        <span *ngIf="jobTriggerHiringStatus">, {{ 'HR.STATUS' | translate }}: {{ jobTriggerHiringStatus | statusName }}</span>
      </span>

      <span *ngIf="!isCompanyTrigger && !isJobTrigger">{{ titleKey | translate }}</span>

      <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
    </h2>
  </div>
    <div *ngIf="showActionButtons"
         class="action-buttons-holder">
    <ng-container *ngFor="let component of performActionComponents">
      <button *ngIf="component.name !== actionComponents.tilTid &&
                     component.name !== actionComponents.refapp &&
                     component.name !== actionComponents.alva &&
                     (!(isJobTrigger || isCompanyTrigger) || component.name !== actionComponents.hide || component.name !== actionComponents.unhide)"
              class="btn btn-primary border-white"
              [ngClass]="{active: activeComponent === component.name, 'data-deleted': isAppWithDeletedDataSelected}"
              (click)="showComponent(component.name, $event)">
        {{ component.translationKey | translate }}
      </button>
    </ng-container>
    <div class="export-button-container" *ngIf="exportPlatforms.length > 0 && !isAppWithDeletedDataSelected">
      <button class="btn btn-primary border-white"
              [ngClass]="{
                active: activeComponent === actionComponents.tilTid ||
                        activeComponent === actionComponents.refapp ||
                        activeComponent === actionComponents.alva
              }"
              (click)="exportEl.toggleExport()">
        {{ 'BUTTONS.EXPORT' | translate }}
      </button>
      <app-export #exportEl
                  [exportPlatforms]="exportPlatforms"
                  [isTrigger]="isCompanyTrigger"
                  [activeComponent]="activeComponent"
                  (expandExportPlatform)="showExportPlatform($event)"></app-export>
    </div>
  </div>

  <app-hiring-statuses *ngIf="showStatuses"
                       [hiringStatuses]="hiringStatuses"
                       [jobTriggers]="jobTriggersForm?.value || []"
                       [isJobTrigger]="!!isJobTrigger"
                       [excludedJobTriggersHiringStatusIds]="excludedJobTriggersHiringStatusIds?.value || []"
                       (addEditJobTrigger)="setJobTrigger($event)"
                       (removeJobTrigger)="removeJobTrigger($event)"
                       (includeExcludeCompanyLevelTriggerForJob)="includeExcludeCompanyLevelTriggerForJob($event)"
                       (closeModal)="closeModal()"></app-hiring-statuses>

  <app-candidate-sms *ngIf="activeComponent === actionComponents.sms"
                     [isTrigger]="isCompanyTrigger || isJobTrigger"
                     [isJobTrigger]="isJobTrigger"
                     [currentHiringStatus]="jobTriggerHiringStatus || companyTriggerHiringStatus"
                     [hiringStatuses]="hiringStatuses"
                     [trigger]="trigger"
                     (addEditTrigger)="addEditTrigger($event)"
                     (back)="onBack()"
                     (sent)="onSent($event)"></app-candidate-sms>

  <app-candidate-email *ngIf="activeComponent === actionComponents.email"
                       [isTrigger]="isCompanyTrigger || isJobTrigger"
                       [isJobTrigger]="isJobTrigger"
                       [currentHiringStatus]="jobTriggerHiringStatus || companyTriggerHiringStatus"
                       [hiringStatuses]="hiringStatuses"
                       [trigger]="trigger"
                       (addEditTrigger)="addEditTrigger($event)"
                       (back)="onBack()"
                       (sent)="onSent($event)"> </app-candidate-email>

  <app-til-tid-export *ngIf="activeComponent === actionComponents.tilTid"
                      [isTrigger]="isCompanyTrigger || isJobTrigger"
                      [isJobTrigger]="isJobTrigger"
                      [currentHiringStatus]="jobTriggerHiringStatus || companyTriggerHiringStatus"
                      [hiringStatuses]="hiringStatuses"
                      [trigger]="trigger"
                      (addEditTrigger)="addEditTrigger($event)"
                      (back)="onBack()"
                      (sent)="onSent($event)"> </app-til-tid-export>
  <app-alvalabs-export *ngIf="activeComponent === actionComponents.alva"
                      [isTrigger]="isCompanyTrigger || isJobTrigger"
                      [isJobTrigger]="isJobTrigger"
                      [currentHiringStatus]="jobTriggerHiringStatus || companyTriggerHiringStatus"
                      [hiringStatuses]="hiringStatuses"
                      [trigger]="trigger"
                      (addEditTrigger)="addEditTrigger($event)"
                      (back)="onBack()"
                      (sent)="onSent($event)"> </app-alvalabs-export>

<ng-container *ngIf="(isCompanyTrigger || isJobTrigger) && (activeComponent === actionComponents.askForCv || activeComponent === actionComponents.refapp)">
  <app-trigger [currentHiringStatus]="jobTriggerHiringStatus || companyTriggerHiringStatus"
               [hiringStatuses]="hiringStatuses"
               [trigger]="trigger"
               [actionType]="activeComponent"
               [isJobTrigger]="isJobTrigger"
               (addTrigger)="setTrigger($event)"
               (back)="onBack()"></app-trigger>
</ng-container>

<button class="btn btn-primary border-white close-button"
        *ngIf="isJobTrigger && !activeComponent"
        (click)="closeModal()">
  {{ 'SIDEBAR.CLOSE_BUTTON' | translate }}
</button>

</div>

<div *ngIf="message"
     class="successfully-send-holder">
  <i class="icon-paper-plane"></i>
  <p>{{ message }}</p>
</div>

<div (click)="closeModal()" class="close-perform-action-container"></div>
