import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { historyAgreementForm, IAgreement, IAgreementForm, IAgreementListItem } from '../model/agreement.model';
import { map, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SetupService } from './setup.service';
import { AgreementFormClass } from '../classes/agreement-form.class';
import { AgreementClass } from '../classes/agreement.class';
import { Pagination } from '../model/pagination.interface';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AgreementService {
  private _refreshAgreementList$: Subject<void> = new Subject<void>();
  constructor(
    private http: HttpClient,
    private setupService: SetupService,
    private userService: UserService
  ) {}

  get refreshAgreementList$(): Observable<void> {
    return this._refreshAgreementList$.asObservable();
  }

  refreshAgreementList(): void {
    this._refreshAgreementList$.next();
  }

  getAgreementForCandidate(guid: string): Observable<AgreementClass> {
    return this.http.get<IAgreement>(`${environment.api}/applications/application_agreements/${guid}`)
    .pipe(
      map((agreement: IAgreement) => new AgreementClass(agreement))
    );
  }

  getApplicationAgreementForCustomer(id: number): Observable<AgreementClass> {
    return this.http.get<IAgreement>(`${environment.agreements}/${id}`)
    .pipe(
      map((agreement: IAgreement) => new AgreementClass(agreement))
    );
  }

  getApplicationAgreementListForCustomer(id: number): Observable<IAgreementListItem[]> {
    return this.http.get<IAgreementListItem[]>(`${environment.applications}/${id}/application_agreements`);
  }

  getAgreementForm(agreementFormGuid: string): Observable<AgreementFormClass> {
    return this.http.get<IAgreementForm>(`${environment.agreementForms}/${agreementFormGuid}`)
    .pipe(
      map((agreementForm: IAgreementForm) => new AgreementFormClass(agreementForm))
    );
  }

  createAgreementForm(agreementForm: AgreementFormClass): Observable<IAgreementForm> {
    // eslint-disable-next-line max-len
    return this.http.post<IAgreementForm>(`${environment.agreementForms}`, AgreementFormClass.prepareForPublish(agreementForm));
  }

  editAgreementForm(agreementForm: AgreementFormClass, guid: string): Observable<IAgreementForm> {
    return this.http.put<IAgreementForm>(`${environment.agreementForms}/${guid}`, AgreementFormClass.prepareForPublish(agreementForm, guid));
  }

  getAgreementFormHistory(agreementFormGuid: string): Observable<historyAgreementForm[]> {
    return this.http.get<historyAgreementForm[]>(`${environment.agreementForms}/${agreementFormGuid}/agreement_form_versions`);
  }

  editAgreement(agreement: AgreementClass, guid: string): Observable<IAgreement> {
    return this.http.put<IAgreement>(`${environment.agreements}/${guid}`, AgreementClass.prepareForEdit(agreement));
  }

  deleteAgreement(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.agreements}/${id}`);
  }

  getAgreementList(queryParams?: any): Observable<IAgreementForm[]> {
    let params = new HttpParams();
    if (queryParams) {
      if (queryParams.status) {
        if (queryParams.status === 'active') {
          params = params.append('status', queryParams.status);
        } else if (queryParams.status === 'inactive') {
          params = params.append('status', queryParams.status);
        } else if (queryParams.status === 'activeAndInactive') {
          params = params.delete('status');
        }
      }
      if (queryParams.searchBy) {
        params = params.append('title', queryParams.searchBy);
      }
      if (queryParams.creator && queryParams.creator.length > 0) {
        params = params.append('creator', queryParams.creator);
      }
    }
    return this.http.get<IAgreementForm[]>(`${environment.customers}/${this.userService.user.guid}/agreement_forms`, {params});
  }

  editAgreementStatus(id: number, status: string): Observable<any> {
    return this.http
      .put(`${environment.agreementForms}/${id}`, {status})
      .pipe(
        map(({data}: Pagination<any>) => data[0]),
      );
  }
}
