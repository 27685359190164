<ng-container *ngIf="activeModal$ | async as activeModal">
  <div class="move-button" (click)="openPrevious()" [ngClass]="{'not-active': activeModal !== modal}">
    <span class="previous-card" *ngIf="curentlyOpenedApplicationIndex > 0">
      <i class="icon-chevron-right"></i>
    </span>
  </div>
  <div class="candidate-card-wrapper" *ngIf="application">
    <div class="candidate-card notranslate">
      <div class="candidate-card-button-close">
        <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
      </div>
      <div class="general-info">
        <div class="candidate-card-profile-content">
          <div class="candidate-card-profile--flex order-2">
            <div class="profile-image-container">
              <div class="profile-image">
                <div class="profile-image-window">
                  <span>{{ application.candidateName ? (application.candidateName | initials) : '' }}</span>
                </div>
              </div>
            </div>
            <div class="candidate-card-info-container info-container">
              <header class="candidate-card-info-header">
                <h3 class="candidate-card-info-title">
                  {{ application.candidateName || (('SHARED.CANDIDATE' | translate) + ' ' + application.id) }}
                </h3>
              </header>
              <div class="candidate-card-info-content">
                <div class="candidate-card-user-info">
                  <div class="info-wrapper">
                    <i class="icon-mail"></i>
                    <div class="content" *ngIf="application.candidate.email">
                      <a class="mail-link"
                         [href]="'mailto:' + application.candidate.email"
                         target="_blank"
                         rel="noopener noreferrer">{{ application.candidate.email }}</a>
                    </div>
                    <hr *ngIf="!application.candidate.email">
                  </div>
                  <div class="info-wrapper">
                    <i class="icon-linkedin-in-circle"></i>
                    <div class="content" *ngIf="!application.isAnonymous">
                      <a *ngIf="application.linkedinProfile else noLink" class="mail-link"
                         [href]="application.linkedinProfile"
                         target="_blank"
                         rel="noopener noreferrer">{{ application.linkedinProfile }}</a>
                      <ng-template #noLink>N/A</ng-template>
                    </div>
                    <hr *ngIf="application.isAnonymous">
                  </div>
                  <div class="info-wrapper info-wrapper-phone">
                    <i class="icon-phone"></i>
                    <div class="content" *ngIf="application.phoneNumber">
                      <a class="mail-link"
                         [href]="'tel:' + application.phoneNumber">
                        {{ application.phoneNumber }}
                      </a>
                      <i class="icon-pencil orange" (click)="openEditPhoneModal()" *allowForRoles="[roles.companyHR, roles.enterpriseHR, roles.enterpriseRM]"></i>
                    </div>
                    <hr *ngIf="!application.phoneNumber">
                  </div>
                  <div class="info-wrapper">
                    <i class="icon-clock"></i>
                    <div class="content">{{ application.createdAt | date:'dd.MM.yy' }}</div>
                  </div>
                  <div class="info-wrapper" *ngIf="application.candidateEditedViaReminderLinkAt">
                    <i class="icon-pencil-outline"></i>
                    <div class="content">{{ application.candidateEditedViaReminderLinkAt | date:'dd.MM.yy' }}</div>
                  </div>
                  <div class="info-wrapper">
                    <i class="icon-suitcase"></i>
                    <div class="content">{{ application.job.jobTitle }}</div>
                  </div>
                  <div *ngIf="application.job.jobLocation as jobLocation"
                       class="info-wrapper">
                    <i class="icon-location"></i>
                    <div class="content">{{ jobLocation.name[companyLanguage] }}</div>
                  </div>
                </div>
                <div class="candidate-card-user-info test-results">
                  <div *ngIf="modules.knockoutQuestions"
                       class="info-wrapper">
                    <i class="icon-knock-out green"></i>
                    <div class="content">
                      <div class="quiz-title">{{'SHARED.KNOCKOUT_QUESTIONS' | translate }}</div>
                      <div class="quiz-results">
                        {{
                            (application.knockoutQuestionsScore
                              ? 'SHARED.APPROVED'
                              : 'RECRUITMENT.NOT_COMPLETED_BY_APPLICANT') | translate
                        }}
                      </div>
                    </div>
                  </div>
                  <div *ngIf="modules.businessQuestions"
                       class="info-wrapper">
                    <i [ngClass]="application.businessQuestionsStatusIconColor"
                       class="icon-skills">
                    </i>
                    <div class="content">
                      <p class="quiz-title">{{ 'SHARED.SKILLS' | translate }}</p>
                      <p class="quiz-results">
                        {{
                            application.businessQuestionsScore
                              ? application.businessQuestionsScore + '% ' + ('SHARED.CORRECT' | translate)
                              : ('RECRUITMENT.NOT_COMPLETED_BY_APPLICANT' | translate)
                        }}
                      </p>
                    </div>
                  </div>
                  <div *ngIf="modules.personalityTest"
                       class="info-wrapper">
                    <i [ngClass]="application.personalityTestStatusIconColor"
                       class="icon-heart">
                    </i>
                    <div class="content">
                      <div class="quiz-title">{{ 'SHARED.PERSONALITY_TEST' | translate }}</div>
                      <div class="quiz-results"
                           *ngIf="application.personalityTest">
                        {{ 'SHARED.SELF_CONFIDENCE' | translate }}
                        {{ application.personalityTest.selfConfidence }}
                      </div>
                      <div class="quiz-results"
                           *ngIf="application.personalityTest">
                        {{ 'SHARED.MOTIVATION' | translate }}
                        {{ application.personalityTest.motivation }}
                      </div>
                      <div class="quiz-results"
                           *ngIf="!application.personalityTest">
                        {{ 'RECRUITMENT.NOT_COMPLETED_BY_APPLICANT' | translate }}
                      </div>
                    </div>
                  </div>
                  <div *ngIf="integratedWithAlva && application.alvaAssessment"
                       class="info-wrapper">
                    <i [ngClass]="alvaRolefit?.score ? 'green' : 'transparent'"
                       class="icon-alva-fill">
                    </i>
                    <div class="content">
                      <div class="quiz-title">{{ 'ALVALABS.ALVA_TEST_RESULT' | translate }}</div>
                      <div class="quiz-results"
                           *ngIf="alvaRolefit?.score">
                        {{ 'ALVALABS.SCORE' | translate }}
                        {{ alvaRolefit?.score | percent }}
                      </div>
                      <div class="quiz-results"
                           *ngIf="alvaRolefit?.label">
                        {{ 'ALVALABS.SCORE_MATCH' | translate }}
                        {{ alvaScoreMatch[alvaRolefit?.label] | translate }}
                      </div>
                      <div class="quiz-results"
                           *ngIf="!alvaRolefit?.score">
                        {{ 'RECRUITMENT.NOT_COMPLETED_BY_APPLICANT' | translate }}
                      </div>
                    </div>
                  </div>
                  <span class="test-result-link"
                        (click)="scroll('test')">
                    {{ 'SHARED.SEE_FULL_TEST_RESULTS' | translate }} >>
                  </span>
                </div>
              </div>
              <div class="candidate-card-send-buttons">
                <div class="send-buttons-container">
                  <button class="btn btn-primary"
                          (click)="scroll('email')">
                    {{ 'SHARED.SEND_EMAIL' | translate }}
                  </button>
                </div>
                <div class="send-buttons-container">
                  <button class="btn btn-primary"
                          (click)="scroll('sms')">
                    {{ 'SHARED.SEND_SMS' | translate }}
                  </button>
                </div>
                <div class="send-buttons-container">
                  <button class="btn btn-primary"
                          (click)="onAskForCv()">
                    {{ 'SHARED.ASK_FOR_CV' | translate }}
                  </button>
                </div>
                <div *ngIf="displayExportButton && !application.isAnonymous"
                     class="send-buttons-container">
                  <button class="btn btn-primary"
                          (click)="exportEl.toggleExport()">
                    {{ 'BUTTONS.EXPORT' | translate }}
                  </button>
                  <app-export #exportEl
                              [applicationId]="application.id"
                              [exportPlatforms]="exportPlatforms"
                              (expandExportPlatform)="scroll($event)"
                              [isCandidateCard]="true"
                  ></app-export>
                </div>
              </div>
              <app-candidate-tags [applicationId]="application.id"
                                  [tags]="application.tags"></app-candidate-tags>
            </div>
          </div>
          <app-candidate-status class="order-1"
                                [applicationId]="application.id"
                                [currentHiringStatus]="application.hiringStatus">
          </app-candidate-status>
          <div class="candidate-card-info-wrapper order-3">
            <div class="candidate-card-info-container">
              <header class="candidate-card-info-header">
                <h3 class="candidate-card-info-title">{{ 'SHARED.APPLICATION_LINK' | translate }}</h3>
              </header>
              <div class="candidate-card-copy-holder justify-content-between" *ngIf="!application.isAnonymous">
                <p class="application-url">{{ application.applicationUrl }}</p>
                <p class="copy-to-clipboard"
                   appCopyToClipboard
                   [appContent]="application.applicationUrl">{{ 'BUTTONS.COPY' | translate }}</p>
              </div>
              <hr *ngIf="application.isAnonymous" class="application-url-horizontal-line">
            </div>
            <div class="candidate-card-info-container refapp-container" *ngIf="application.refappDetails as refapp">
              <header class="candidate-card-info-header">
                <h3 class="candidate-card-info-title">Refapp</h3>
              </header>
              <div class="candidate-card-copy-holder justify-content-between">
                <p *ngIf="refapp.status === 'completed'">
                  {{ 'RECRUITMENT.REFAPP_COMPLETED' | translate }}
                </p>
                <p *ngIf="refapp.status === 'pending'">
                  {{ 'RECRUITMENT.REFAPP_COMPLETED_PARTIALLY' | translate: {completed: refapp.assessmentCompleted, total: refapp.assessmentTotal} }}
                </p>
                <div *ngFor="let attachment of refapp.attachments">
                  <div *ngIf="attachment.url.includes('candidate') && refapp.status !== 'completed'">
                    <h5 class="candidate-card-info-title">{{ 'RECRUITMENT.REFAPP_CANDIDATE_LINK' | translate }}</h5>
                    <p class="application-url ref-app-link">{{attachment.url}}</p>
                  </div>
                  <div *ngIf="attachment.url.includes('report')">
                    <h5 class="candidate-card-info-title">{{ 'RECRUITMENT.REFAPP_REPORT_LINK' | translate }}</h5>
                    <p class="application-url ref-app-link">{{attachment.url}}</p>
                  </div>
                  <p class="copy-to-clipboard" appCopyToClipboard [appContent]="attachment.url">
                    {{ 'BUTTONS.COPY' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <app-candidate-attach-file [uploadedDocuments]="application.documents"
                                       [applicationId]="application.id">
            </app-candidate-attach-file>
          </div>
        </div>

        <div class="content-holder">
          <app-candidate-interview *ngIf="isInterviewEnabled"
                                    [applicationId]="application.id"
                                    [applicationGuid]="application.guid"
                                    [candidateName]="application.candidateName"
                                    [jobTitle]="application.job.jobTitle"
                                    [isAnonymous]="application.isAnonymous"
                                    (interviewCanceled)="data.onCancelInterview()"
                                    id="interview"></app-candidate-interview>
          <app-candidate-video [videoQuestions]="application.videoQuestions"
                               [isAnonymous]="application.isAnonymous">
          </app-candidate-video>
          <app-candidate-test id="test"
                              [application]="application"
                              [orderOfQuestionsModules]="application.orderOfQuestionsModules"
                              [alvaCandidate]="alvaCandidate">
          </app-candidate-test>
          <app-candidate-agreements *ngIf="areAgreementsEnabled"
            [application]="application"
            (openAgreementModal$)="openAgreementModal($event)">
          </app-candidate-agreements>
          <div class="comments-previous-applications-container">
            <app-candidate-comments id="comment"
                                    [applicationId]="application.id"
                                    [comments]="application.comments"
                                    [branchId]="application.job.branch?.id"
                                    [job]="application.job">
            </app-candidate-comments>
            <app-candidate-previous-applications [openedAsPreviousApp]="data.openedAsPreviousApplication"
                                                 [currentAppId]="applicationId"
                                                 [currentAppCandidateId]="application.candidate.id"
                                                 [isAnonymous]="application.isAnonymous"
                                                 (closeModal)="closeModal()">
            </app-candidate-previous-applications>
          </div>
          <div class="email-sms-container">
            <app-candidate-email id="email"
                                 [application]="application"
                                 [isAnonymous]="application.isAnonymous">
            </app-candidate-email>
            <app-candidate-sms id="sms"
                               [applicationId]="application.id"
                               [messages]="application.smsMessages"
                               [isAnonymous]="application.isAnonymous">
            </app-candidate-sms>
          </div>
          <div class="export-container">
            <app-til-tid-export id="tilTid"
                                [application]="application"
                                *ngIf="integratedWithTilTid && !application.exportedToTilTid"
                                (exported)="exportedToExternalPlatform($event)">
            </app-til-tid-export>
            <app-refapp id="refApp"
                        [application]="application"
                        *ngIf="integratedWithRefapp && !application.exportedToRefapp"
                        (exported)="exportedToExternalPlatform($event)">
            </app-refapp>
            <app-sri-background-check id="sriBgCheck"
                                      [application]="application"
                                      *ngIf="integratedWithSri"
                                      (exported)="exportedToExternalPlatform($event)">
            </app-sri-background-check>
            <app-alvalabs-export id="alva"
                                 [application]="application"
                                 *ngIf="integratedWithAlva"
                                 (exported)="exportedToExternalPlatform($event)"
                                 (alvaCandidateInfo)="setAlvaCandidateInfo($event)">
            </app-alvalabs-export>
          </div>
        </div>

        <div class="action-button">
          <button class="btn btn-primary"
                  *ngIf="application.isHidden"
                  (click)="hideUnhideApplication()">
            {{ 'BUTTONS.UNHIDE' | translate }}
          </button>
          <button class="btn btn-primary"
                  *ngIf="!application.isHidden"
                  (click)="hideUnhideApplication()">
            {{ 'BUTTONS.HIDE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!application" class="candidate-card-wrapper">
    <div class="loader-container">
      <div class="loader-content">
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
      </div>
    </div>
  </div>

  <div class="move-button" (click)="openNext()" [ngClass]="{'not-active': activeModal !== modal}">
    <span class="next-card" *ngIf="curentlyOpenedApplicationIndex < allApplicationIds.length - 1">
      <i class="icon-chevron-right"></i>
    </span>
  </div>
</ng-container>
