<div class="custom-modal-wrapper">
  <div class="close-icon-wrapper">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <div class="custom-modal">
    <h3 class="modal-title">
      {{ title | translate }}
    </h3>
    <div class="modal-body">
      <div class="video">
        <video  #videoPlayer
                class="video-preview media-preview"
                preload="metadata"
                controls>
          <source [src]="videoMp4Url" />
          <source [src]="videoWebmUrl" />
        </video>
      </div>
      <div class="video-poster">
        <img [src]="poster" class="media-preview" >
      </div>
    </div>
    <div class="button-wrapper">
      <button type="button"
              class="btn btn-primary"
              (click)="captureVideoPoster()">
          {{ 'VIDEO_RECORDER.CAPTURE_BTN' | translate }}
      </button>
      <button (click)="closeModal()"
              class="btn btn-outline-primary btn-back">
        <i class="icon icon-arrow-left"></i>
        <span>{{ "BUTTONS.CANCEL" | translate }}</span>
      </button>
      <button class="btn btn-primary border-white"
              (click)="changeVideoPoster()">
        {{ "BUTTONS.APPLY" | translate }}
      </button>
    </div>

  </div>
</div>
