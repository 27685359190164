<div class="custom-modal-wrapper">
  <div class="custom-modal">
    <h3 class="modal-title">
      {{ data.title | translate }}
    </h3>
    <div class="modal-question-wrapper">
      <div *ngIf="data.question" class="modal-question">
        {{ data.question | translate: data.questionTranslateObject }}
      </div>
      <div class="modal-notice" *ngIf="data.notice as notice">
        {{ notice | translate }}
      </div>
      <div class="modal-window-content ql-editor" *ngIf="data.questionHtml">
        <div class="content" [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.questionHtml)"></div>
      </div>
    </div>
    <div class="button-wrapper">
      <button (click)="close()"
              *ngIf="!data.noCancelBtn"
              class="btn btn-outline-primary btn-back">
        <i class="icon icon-arrow-left"></i>
        <span>{{ (data.cancelBtnTitle || "BUTTONS.CANCEL") | translate }}</span>
      </button>
      <button class="btn btn-primary border-white"
              (click)="confirm()">
        {{ (data.confirmBtnTitle || "BUTTONS.YES") | translate }}
      </button>
    </div>
  </div>
</div>
