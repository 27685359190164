import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import ICompany from 'src/app/model/company.interface';
import { ILibraryData } from 'src/app/model/library-data.interface';
import { LibraryModalData, Tab } from 'src/app/model/modal.interface';
import { Roles } from 'src/app/model/role.interface';
import { AbstractModal } from 'src/app/modules/modal/abstract-modal';
import { CompanyService } from 'src/app/services/company.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { SetupService } from 'src/app/services/setup.service';
import { Router } from '@angular/router';
import { EnterpriseService } from 'src/app/services/enterprise.service';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent extends AbstractModal implements OnInit {

  displayAll = false;
  videoElement: HTMLVideoElement;
  selectedFile: string;
  libraryData: ILibraryData[];
  videoLibraryData: ILibraryData[];
  imageLibraryData: ILibraryData[];
  isVideo: boolean;
  isEnterprise: boolean;

  readonly rolesEnum = Roles;

  @HostListener('document:fullscreenchange')
  @HostListener('document:webkitfullscreenchange')
  @HostListener('document:mozfullscreenchange')
  @HostListener('document:MSFullscreenChange')
  exitFromVideo(): void {
    if (!document.fullscreenElement) {
      this.videoElement.controls = false;
      this.videoElement.pause();
    }
  }

  get data(): LibraryModalData {
    return this.modal.data;
  }

  get title(): string {
    return this.data.title;
  }

  get tabs(): Tab[] {
    return this.data.tabs;
  }

  get isStory(): boolean {
    return this.data.isStory;
  }

  constructor(
    modalService: ModalService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private setupService: SetupService,
    private cdr: ChangeDetectorRef,
    private enterpriseService: EnterpriseService,
    private loaderService: LoaderService,
    private router: Router,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.isVideo = this.tabs ? this.tabs[0].isVideo : this.data.isVideo;
    this.getData();
  }

  getData(): void {
    this.loaderService.show();
    const isEnterprise = this.router.url.includes('enterprise');
    const libraryData$ = this.isStory ?
      this.isVideo ? this.companyService.getStoryVideoLibrary() : this.companyService.getStoryImageLibrary() : isEnterprise ?
      this.isVideo ? this.enterpriseService.getEnterpriseVideoLibrary() : this.enterpriseService.getEnterpriseImageLibrary()
      : this.isVideo ? this.companyService.getCompanyVideoLibrary() : this.companyService.getCompanyImageLibrary();

    libraryData$.subscribe((data) => {
      this.libraryData = this.data?.filtering ? this.data.filtering(data) : data;
      if (this.isVideo) {
        this.videoLibraryData = data;
      } else {
        this.imageLibraryData = data;
      }
      this.loaderService.hide();
      this.cdr.detectChanges();
    });
  }

  showMoreData(): void {
    this.displayAll = true;
  }

  removeData(data: ILibraryData): void {
    this.isStory ? this.removeStoryLibraryData(data.guid) : this.removeJobLibraryData(data);
  }

  removeJobLibraryData(data: ILibraryData): void {
    const url = this.isVideo ? data.video : data.picture;
    this.loaderService.show();
    const company = this.setupService.currentCompany;
    const companyUpdate: Partial<ICompany> = {
      removedJobPicturesFromCompanyLibrary: company.removedJobPicturesFromCompanyLibrary || [],
      removedJobVideosFromCompanyLibrary: company.removedJobVideosFromCompanyLibrary || []
    };

    if (this.isVideo) {
      companyUpdate.removedJobVideosFromCompanyLibrary.push(url);
    } else {
      companyUpdate.removedJobPicturesFromCompanyLibrary.push(url);
    }
    this.companyService.removeJobLibraryData(companyUpdate).subscribe(() => {
      this.getData();
      this.loaderService.hide();
    });
  }

  removeStoryLibraryData(guid: string): void {
    this.loaderService.show();

    const request$ = this.isVideo ?
      this.companyService.removeVideoStoryLibraryData(guid) : this.companyService.removeImageStoryLibraryData(guid);

    request$.subscribe(() => {
          this.getData();
          this.loaderService.hide();
        });
  }

  useFile(): void {
    if (this.selectedFile) {
      this.data.confirm(this.selectedFile, this.isVideo);
      this.closeModal();
    } else {
      this.toastr.error(
        this.translateService.instant('VIDEO_RECORDER.YOU_HAVE_TO_SELECT_FILE_YOU_WANT_TO_ADD')
      );
    }
  }

  selectFile(url: string): void {
    this.selectedFile = url;
  }

  openInFullScreen(element: any): void {
    this.videoElement = element;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }

    element.controls = true;
  }

  selectTab(tab: Tab): void {
    this.tabs.forEach((item) => {
      item.active = false;
    });
    this.isVideo = tab.isVideo;
    this.displayAll = false;
    tab.active = true;
    const cachedData = this.isVideo ? this.videoLibraryData : this.imageLibraryData;
    if (!cachedData) {
      this.getData();
    } else {
      this.libraryData = cachedData;
    }
  }
}
