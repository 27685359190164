<div class="custom-modal-wrapper">
  <div class="close-modal-icon" *ngIf="modal" (click)="closeModal()">
    <i class="icon-cancel-in-circle"></i>
  </div>
  <div class="custom-modal">
    <h3 class="modal-title border-top-black">
      {{ 'UPLOAD_RESUME.UPDATE_PHONE_NUMBER' | translate }}
    </h3>
    <div class="modal-question-wrapper tel-wrapper">
      <app-phone-edit [phoneNumber]="this.data.phoneNumber"
                      [form]="phoneNumberForm">
      </app-phone-edit>
    </div>
    <div class="button-wrapper">
      <button (click)="closeModal()" class="btn btn-outline-primary btn-back">
        <i class="icon icon-arrow-left"></i>
        <span>{{ "BUTTONS.CANCEL" | translate }}</span>
      </button>
      <button class="btn btn-primary" (click)="updatePhone()">
        {{ "BUTTONS.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
