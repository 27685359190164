export const PERFORM_ACTION_TYPE = {
  sendSms: {
    translationKey: 'SHARED.SEND_SMS'
  },
  sendEmail: {
    translationKey: 'SHARED.SEND_EMAIL'
  },
  exportToTilTid: {
    translationKey: 'SHARED.SEND_TO_TIL_TID'
  },
  exportToRefapp: {
    translationKey: 'SHARED.SEND_TO_REF_APP'
  },
  exportToAlva: {
    translationKey: 'ALVALABS.SEND_TO_ALVALABS'
  },
  sendAskForCv: {
    translationKey: 'SHARED.ASK_FOR_CV'
  }
};
