<div>
  <div class="psykometrika-language">
    <h2 class="question-title" *ngIf="!enableEdit">{{ selectedLanguage | translate }}</h2>
    <div class="form-group" *ngIf="enableEdit">
      <select class="form-control"
              [formControl]="psykometrikaLanguage"
              [ngClass]="{ 'is-invalid':
              form.get('psykometrikaLanguage').invalid &&
              form.get('psykometrikaLanguage').touched }">
        <option *ngFor="let language of languages"
                [value]="language.key">{{ language.name | translate }}</option>
      </select>
      <div class="invalid-feedback">{{ 'PERSONALITY_TEST.LANGUAGE_REQUIRED_ERROR_MSG' | translate }}</div>
    </div>
  </div>
  <div class="questions-container">
    <div class="question-wrapper" [ngClass]="{ 'disable-edit': !enableEdit }">
      <div class="form-group question-text" *ngFor="let question of questions.get(psykometrikaLanguage.value)" >
<!--        <input class="form-control" [value]="question.text"/>-->
        <span>{{ question.text }}</span>
        <div *ngIf="enableEdit" class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}</div>
      </div>
    </div>
  </div>
</div>
