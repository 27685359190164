import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FileResponse, FileUpload, IFile } from '../model/upload-files.model';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {

  constructor(private http: HttpClient) {}

  uploadEmailFiles(files: FileUpload[]): Observable<FileResponse[]> {
    if (!files.length) {
      return of([]);
    }

    const requests$ = files
      .map((file: FileUpload) => this.uploadFile(file));

    return forkJoin(requests$);
  }

  uploadFile({fileName, base64, context}: FileUpload): Observable<FileResponse> {
    return this.http.post<FileResponse>(environment.storage + '/upload_file', {fileName, base64, context})
      .pipe(
        map((uploadedFile: FileResponse) => {
          return {...uploadedFile, fileName};
        })
      );
  };

  getFileSizes(files: IFile[]): Observable<number[]> {
    const requests$ = files.map((file: IFile) => this.getFileSize(file.file));

    return forkJoin(requests$);
  }

  getFileSize(fileUrl: string): Observable<number> {
    return this.http
      .head(
        fileUrl,
        {observe: 'response'}
      )
      .pipe(
        map((body: HttpResponse<Body>) => {
          return +body.headers.get('content-length'); // size in bytes
        })
      );
  }
}
