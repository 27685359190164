<div class="questions-container">
  <div class="question-wrapper" [ngClass]="{ 'disable-edit': !enableEdit }">
    <div class="form-group question-text">
      <span>{{ videoMessage.value }}</span>
      <input
        class="form-control"
        [formControl]="videoMessage"
        [ngClass]="{
          'is-invalid': videoMessage.invalid && videoMessage.touched
        }"
      />
      <div *ngIf="videoMessage.errors?.required && enableEdit" class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}</div>
      <div *ngIf="videoMessage?.errors?.maxlength && enableEdit" class="invalid-feedback">{{ 'SMS_TEMPLATE.MAX_LENGTH_FIRST_PART' | translate }} {{ maxVideoMessageLength }} {{ 'SMS_TEMPLATE.MAX_LENGTH_SECOND_PART' | translate }}
        {{ videoMessage.value.length }}. {{ 'SMS_TEMPLATE.MAX_LENGTH_THIRD_PART' | translate }} {{videoMessage.value.length - maxVideoMessageLength}}
        {{ 'SMS_TEMPLATE.NAME_MAX_LENGTH_ERROR_MSG_SECOND_PART' | translate }}
      </div>
      <div class="message-length"
           *ngIf="enableEdit"
           [ngClass]="{'limit-reached': videoMessage.errors?.maxlength}">
        {{ videoMessage.value?.length || 0 }} / {{ maxVideoMessageLength }}
      </div>
    </div>
  </div>

  <div class="question-wrapper" [ngClass]="{ 'disable-edit': !enableEdit }">
    <div class="form-group question-text">
      <span>{{ videoMainMessage.value }}</span>
      <textarea [formControl]="videoMainMessage"
                [placeholder]="'JOBS.INTRO_MESSAGE' | translate"
                rows="7"
                class="form-control"
                [ngClass]="{
                  'is-invalid': !videoMainMessage.valid && videoMainMessage.touched
                }">
      </textarea>
      <div *ngIf="videoMainMessage.errors?.required && enableEdit" class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}</div>
      <div *ngIf="videoMainMessage?.errors?.maxlength && enableEdit" class="invalid-feedback">{{ 'SMS_TEMPLATE.MAX_LENGTH_FIRST_PART' | translate }} {{ maxVideoMainMessageLength }} {{ 'SMS_TEMPLATE.MAX_LENGTH_SECOND_PART' | translate }}
        {{ videoMainMessage.value.length }}. {{ 'SMS_TEMPLATE.MAX_LENGTH_THIRD_PART' | translate }} {{videoMainMessage.value.length - maxVideoMainMessageLength}}
        {{ 'SMS_TEMPLATE.NAME_MAX_LENGTH_ERROR_MSG_SECOND_PART' | translate }}
      </div>
      <div class="message-length"
           *ngIf="enableEdit"
           [ngClass]="{'limit-reached': videoMainMessage.errors?.maxlength}">
        {{ videoMainMessage.value?.length || 0 }} / {{ maxVideoMainMessageLength }}
      </div>
    </div>
  </div>

  <div class="video-container" *ngIf="video.value">
    <video  #videoPlayer
            class="video-preview"
            [poster]="videoPosterUrl"
            playsinline
            controls>
      <source [src]="videoMp4Url"/>
      <source [src]="videoWebmUrl"/>
    </video>
  </div>
  <div class="questions-container">
    <div class="question-wrapper" [ngClass]="{ 'disable-edit': !enableEdit }">
      <div class="form-group question-text" *ngFor="let question of questions">
        <span>{{ question.value }}</span>
        <input
          class="form-control"
          [formControl]="question"
          [ngClass]="{
            'is-invalid': question.invalid &&
                          question.touched
          }"
        />
        <div *ngIf="enableEdit" class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}</div>
      </div>
    </div>
  </div>
</div>
