<div class="custom-modal-wrapper">
  <div class="close-icon-wrapper">
    <i class="icon-cancel-in-circle" (click)="onCloseModal()"></i>
  </div>
  <div class="custom-modal">
    <h3 class="modal-title">
      {{ 'JOBS.SHOW_PREVIOUSLY_HIDDEN_QUESTION' | translate }}
    </h3>
    <div class="modal-body">
      <!-- LIST OF QUESTIONS -->
      <app-question-list
        [questionsList]="hiddenQuestionList"
        (callScroll)="onScroll()"
        [hiddenList]="true"
        (hideUnhideQuestion)="unhideSkillsQuestion()">
      </app-question-list>
    </div>
    <div class="button-wrapper">
      <button (click)="onCloseModal()"
              class="btn btn-outline-primary">
        {{ "BUTTONS.CANCEL" | translate }}
      </button>
    </div>

  </div>
</div>
