import { IComment } from '../model/comment.interface';
import { User, UserCommunicator } from '../model/user.interface';

export class Comment {

  id: number;
  createdAt: string;
  content: string;
  creatorName: string;
  anonymousCreator: boolean;
  creatorEmail: string;
  unreadComment: boolean;

  constructor({
    id,
    createdAt,
    content,
    creatorCustomer,
    creatorEnterpriseManager,
    customersWhoSawApplicationComment,
    enterpriseManagersWhoSawApplicationComment,
    customersTaggedInApplicationComment,
    enterpriseManagersTaggedInApplicationComment
  }: IComment,
  currentUser: User
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.content = content;

    const creator = creatorCustomer || creatorEnterpriseManager;
    this.creatorName = creator.name;
    this.creatorEmail = creator.email;
    this.anonymousCreator = creator.anonymousCustomer || creator.anonymousEnterpriseManager;

    const taggedUsers = [...customersTaggedInApplicationComment, ...enterpriseManagersTaggedInApplicationComment];
    const usersSeenTag = [...customersWhoSawApplicationComment, ...enterpriseManagersWhoSawApplicationComment];
    const isTagged = !!taggedUsers.find((user: UserCommunicator) => user.email === currentUser.email);
    const hasSeen = !!usersSeenTag.find((user: UserCommunicator) => user.email === currentUser.email);
    this.unreadComment = isTagged && !hasSeen;
  }
}
