import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PublishingPlatform, PublishingPlatforms } from '../../model/publishing-platform.interface';
import { UniversalOption } from '../../model/universal-option.interface';
import { JOB_PLATFORMS_PUBLISH, JobPlatforms } from '../../resources/job-platforms';
import { UserService } from '../user.service';
import { IJob } from '../../model/job.interface';
import { Pagination } from 'src/app/model/pagination.interface';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../company.service';
import { IAmsDrivingLicence } from 'src/app/model/ams-driving-licence.interface';
import { Countries } from 'src/app/resources/countries';



@Injectable({
  providedIn: 'root'
})
export class PublishingPlatformsService {
  private _allJobPlatforms: PublishingPlatforms;

  get alljobPlatforms(): PublishingPlatforms {
    return this._allJobPlatforms;
  }

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    private translateService: TranslateService,
    private companyService: CompanyService
  ) { }

  getPlatformId(platformName: string): number {
    return this._allJobPlatforms?.find(platform => platform.name === platformName)?.id;
  }

  getPlatformName(platformId: number): string {
    return this._allJobPlatforms?.find(platform => platform.id === platformId)?.name;
  }

  getAllPlatforms(companyId: number): Observable<PublishingPlatforms> {
    let platforms$: Observable<PublishingPlatforms> = of(null);
    if (!this._allJobPlatforms) {
      platforms$ = this.http.get<PublishingPlatforms>(environment.publishingPlatforms)
      .pipe(
        tap((platforms) => this._allJobPlatforms = platforms)
      );
    }

    return platforms$.pipe(
      switchMap(() => this.getAllPlatformsForCompany(companyId))
    );
  }

  getAllPlatformsForCompany(companyId: number): Observable<PublishingPlatforms> {
    const params = new HttpParams().set('company', companyId.toString());

    const getPlatforms = this.http.get<PublishingPlatforms>(environment.publishingPlatforms, { params });

    return forkJoin([getPlatforms, this.companyService.getCompanyInfo()])
      .pipe(
        map(([platforms, company]) => {
          return platforms.map(platform => {
            const { indeedCompany,
                    jobindexCompany,
                    duunitoriCompany,
                    facebookAndInstagramCompany,
                    adwayCompany,
                    besokslivCompany } = company;

            if (platform.name === JobPlatforms.indeed) {
              if (company.location.country.id === Countries.denmark && indeedCompany.paymentMethod === '100 EUR') {
                platform.price = '750 DKK';
              } else {
                platform.price = indeedCompany.paymentMethod;
              }
            }

            if (platform.name === JobPlatforms.jobindex) {
              platform.price = jobindexCompany?.paymentMethod;
            }

            if (platform.name === JobPlatforms.duunitori && duunitoriCompany) {
              platform.price = this.translateService.instant('COMPANY_INFO.REMAINING_CREDITS') +
                ': ' + company.duunitoriCompany.credits;
            }

            if (platform.name === JobPlatforms.facebookAndInstagram) {
              platform.displayName = this.translateService.instant('JOBS.FACEBOOK_AND_INSTAGRAM');
              platform.price = this.translateService.instant('JOBS.PLATFORM_PRICE.SOCIAL_MEDIA');
            }

            if (platform.name === JobPlatforms.adway) {
              platform.displayName = this.translateService.instant('JOBS.SOCIAL_MEDIA');
              platform.price = this.translateService.instant('JOBS.PLATFORM_PRICE.ADWAY');
            }

            if (platform.name === JobPlatforms.besoksliv) {
              platform.displayName = this.translateService.instant('JOBS.BESOKSLIV');
              platform.price = besokslivCompany.isMember ?
                               this.translateService.instant('JOBS.IS_MEMBER') : this.translateService.instant('JOBS.IS_NOT_MEMBER');
            }

            if (platform.name === JobPlatforms.linkedIn) {
              platform.price = '790 EUR';
            }

            if (platform.name === JobPlatforms.higherChargeable) {
              platform.price = '95 EUR';
            }

            if (platform.name === JobPlatforms.tiktok) {
              platform.price = this.translateService.instant('JOBS.PLATFORM_PRICE.TIKTOK');
            }

            platform.order = JOB_PLATFORMS_PUBLISH.get(platform.name as JobPlatforms).order;

            if (JOB_PLATFORMS_PUBLISH.get(platform.name as JobPlatforms).order) {
              return platform;
            }
          });
        }),
        map((platforms: PublishingPlatforms) =>
        platforms.sort((a: PublishingPlatform, b: PublishingPlatform) => (a.order > b.order) ? 1 : (b.order > a.order) ? -1 : 0)
        )
      );
  }

  getPlatforms(companyId: number): Observable<PublishingPlatforms> {
    const params = new HttpParams().set('company', companyId.toString());
    return this.http.get<PublishingPlatforms>(environment.publishingPlatforms, { params })
    .pipe(
      map((platforms) => {
        platforms.forEach((platform: PublishingPlatform) => {
          if (platform.name === 'Ams') {
            platform.name = 'Arbetsförmedlingen';
          }
        });
        return platforms;
      })
    );
  }

  getPlatformSpecificCategories(name: string): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(environment[name.toLowerCase()].categories);
  }

  getSubcategories(name: string): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(environment[name.toLowerCase()].subcategories);
  }

  getMunicipalities(name: string): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(environment[name.toLowerCase()].municipalities);
  }

  getWorkingLanguages(name: string): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(environment[name.toLowerCase()].workingLanuages);
  }

  getRemoteOptions(name: string): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(environment[name.toLowerCase()].remoteOptions);
  }

  getTimeExtents(name: string): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(environment[name.toLowerCase()].timeExtents);
  }

  getDrivingLicences(name: string): Observable<IAmsDrivingLicence[]> {
    return this.http.get<IAmsDrivingLicence[]>(environment[name.toLowerCase()].driving_licences)
      .pipe(
        map(licences => {
          licences.sort((a, b) => {
            if (a.sortOrder < b.sortOrder) {
              return -1;
            }

            if (a.sortOrder > b.sortOrder) {
              return 1;
            }

            return 0;
          });
          return licences;
        })
      );

  }

  getPlatformSpecificTypesOfEmployment(name: string): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(environment[name.toLowerCase()].typeOfEmployment);
  }

  getDurations(name: string): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(environment[name.toLowerCase()].durations);
  }

  unpublishFromHigher(jobId: number): Observable<IJob> {
    return this.http.put(`${environment.job}/${jobId}`, { publishOnHigher: false })
      .pipe(
        map(({data}: Pagination<IJob>) => data[0])
      );
  }

  unpublishFromPlatform(jobGUID: string, platformId: number): Observable<IJob> {
    return this.http.put(`${environment.job}/${jobGUID}/unpublish/${platformId}`, {})
      .pipe(
        map(({data}: Pagination<IJob>) => data[0])
      );
  }

}
