import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal } from '../abstract-modal';
import { ModalService } from '../../../services/modal.service';
import { ConfirmationModal } from '../../../classes/modal.class';
import { ConfirmationModalData } from '../../../model/modal.interface';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationModalComponent extends AbstractModal {

  modal: ConfirmationModal;

  constructor(
    modalService: ModalService,
    public sanitizer: DomSanitizer
  ) {
    super(modalService);
  }

  get data(): ConfirmationModalData {
    return this.modal.data;
  }

  confirm(): void {
    this.data.confirm();
    this.closeModal();
  }

  close(): void {
    if (this.data.close) {
      this.data.close();
    }

    this.closeModal();
  }
}
