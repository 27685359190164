<div class="custom-modal-wrapper">
  <div class="custom-modal">
    <form [formGroup]="responsiblePersonForm">
      <div class="select-responsible-person-wrapper">
        <div>{{ 'USER.SELECT_RESPONSIBLE_PERSON' | translate }}: </div>
        <div class="select-responsible-person">

            <div class="form-group">
              <select formControlName="responsiblePerson"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': responsiblePerson.invalid && responsiblePerson.touched
                      }">
                <option disabled
                      selected
                      [value]="null"
                      class="d-none"></option>
                <option *ngIf="data.responsiblePersonList.length <= 0"
                        [value]=""
                        disabled>{{'USER.NO_ITEMS_FOUND_MESSAGE' | translate}}</option>
                <option *ngFor="let person of data.responsiblePersonList"
                        [ngValue]="person">{{ person.name }}</option>
              </select>
            </div>
        </div>
      </div>
      <div class="assign-interview-wrapper" *ngIf="usersInterviews$ | async">
          <!-- individual interviews  -->
          <div class="individual-interviews-wrapper" formGroupName="videoInterviewsAndNewEmployees" *ngIf="usersIndividualInterviews?.length">
            <div class="interview-title">{{ 'USER.ASSIGN_INDIVIDUAL_INTERVIEWS' | translate }}: </div>
            <div class="interview-group-wrapper">
              <div class="form-group" *ngFor="let interview of usersIndividualInterviews">
                <div class="interview-info">
                  <div class="interview-subject">
                    {{ interview.subject }}
                  </div>
                  <div class="interview-status">
                    <span>
                      {{ ('INTERVIEW.' + interview.status | uppercase) | translate }}
                    </span>
                  </div>
                  <div class="interview-time-slot">
                    <i class="icon-calendar icon"></i>
                    <span class="info" *ngIf="interview.timeSlot; else timeSlotsList">
                      {{ interview.timeSlot.start | dateDayMonth }}
                      {{ interview.timeSlot.start | dateTime }}-
                      {{ interview.timeSlot.end | dateTime }}
                    </span>
                    <ng-template #timeSlotsList>
                      <span class="info time-slots-list">
                        <div *ngFor="let timeSlot of interview.timeSlots">
                          {{ timeSlot.start | dateDayMonth }}
                          {{ timeSlot.start | dateTime }}-
                          {{ timeSlot.end | dateTime }}
                        </div>
                      </span>
                    </ng-template>
                  </div>
                </div>
                <select [formControlName]="interview.guid"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': getFormControl(videoInterviewsAndNewEmployees, interview.guid).invalid &&
                                        getFormControl(videoInterviewsAndNewEmployees, interview.guid).touched
                        }">
                  <option disabled
                          selected
                          [value]="null"
                          class="d-none"></option>
                  <option *ngIf="interview.availableCustomers.length <= 0"
                          [value]=""
                          disabled>{{'USER.NO_ITEMS_FOUND_MESSAGE' | translate}}</option>
                  <option *ngFor="let person of interview.availableCustomers"
                          [ngValue]="person.guid">{{ person.name }}</option>
                </select>
              </div>
            </div>

          </div>
          <!-- group interviews  -->
          <div class="group-interviews-wrapper" formGroupName="groupVideoInterviewsAndNewEmployees" *ngIf="usersGroupInterviews?.length">
            <div class="interview-title">{{ 'USER.ASSIGN_GROUP_INTERVIEWS' | translate }}: </div>
            <div class="interview-group-wrapper">
              <div class="form-group" *ngFor="let interview of usersGroupInterviews">
                <div class="interview-info">
                  <div class="interview-subject">
                    {{ interview.subject }}
                  </div>
                  <div class="interview-status">
                    <span>
                      {{ ('INTERVIEW.' + interview.status | uppercase) | translate }}
                    </span>
                  </div>
                  <div class="interview-time-slot">
                    <i class="icon-calendar icon"></i>
                    <span class="info" *ngIf="interview.timeSlot; else timeSlotsList">
                      {{ interview.timeSlot.start | dateDayMonth }}
                      {{ interview.timeSlot.start | dateTime }}-
                      {{ interview.timeSlot.end | dateTime }}
                    </span>
                    <ng-template #timeSlotsList>
                      <span class="info time-slots-list">
                        <div *ngFor="let timeSlot of interview.timeSlots">
                          {{ timeSlot.start | dateDayMonth }}
                          {{ timeSlot.start | dateTime }}-
                          {{ timeSlot.end | dateTime }}
                        </div>
                      </span>
                    </ng-template>
                  </div>
                </div>
                <select [formControlName]="interview.guid"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': getFormControl(groupVideoInterviewsAndNewEmployees, interview.guid).invalid &&
                                        getFormControl(groupVideoInterviewsAndNewEmployees, interview.guid).touched
                        }">
                  <option disabled
                        selected
                        [value]="null"
                        class="d-none"></option>
                  <option *ngIf="interview.availableCustomers.length <= 0"
                          [value]=""
                          disabled>{{'USER.NO_ITEMS_FOUND_MESSAGE' | translate}}</option>
                  <option *ngFor="let person of interview.availableCustomers"
                          [ngValue]="person.guid">{{ person.name }}</option>
                </select>
              </div>
            </div>
          </div>
      </div>
    </form>
    <h3 class="modal-title">
      {{ "USER.MODAL_DELETE_USER_TITLE" | translate }}
    </h3>
    <div class="modal-question-wrapper">
      <div class="modal-question">
        {{ "USER.MODAL_DELETE_USER_QUESTION" | translate }}
      </div>
      <div class="modal-notice">
        {{ "USER.MODAL_DELETE_USER_NOTICE" | translate }}
      </div>
    </div>
    <div class="button-wrapper">
      <button (click)="close()"
              class="btn btn-outline-primary btn-back">
        <i class="icon icon-arrow-left"></i>
        <span>{{ "BUTTONS.CANCEL" | translate }}</span>
      </button>
      <button class="btn btn-primary border-white"
              (click)="confirm()">
        {{ "BUTTONS.DELETE" | translate }}
      </button>
    </div>
  </div>
</div>
