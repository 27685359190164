export enum NotificationType {
  'email',
  'comment',
  'refApp',
  'uploadedCV',
  'candidateProvidedInfo',
  'sriBgCheck',
  'adway',
  'requisitionNotifications',
  'alva'
}
