import { Pipe, PipeTransform } from '@angular/core';
import { convertStringDateToJSDate } from 'src/app/resources/shared-functions';

@Pipe({
  name: 'stringDateToJsDate'
})
export class StringDateToJsDatePipe implements PipeTransform {

  transform(date: string): Date {
    return convertStringDateToJSDate(date);
  }

}
