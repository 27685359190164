import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InterviewToAccept } from 'src/app/classes/interviewToAccept.class';
import { CancelInterviewModalData } from 'src/app/model/modal.interface';
import { ModalService } from 'src/app/services/modal.service';
import { AbstractModal } from '../../modal/abstract-modal';

@Component({
  selector: 'app-cancel-interview',
  templateUrl: './cancel-interview.component.html',
  styleUrls: ['./cancel-interview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelInterviewComponent extends AbstractModal {
  @Input() interviewToReject: InterviewToAccept;
  @Input() interviewGuid: string;
  @Input() isGroup: boolean;
  @Input() groupInterviewInfo: string;

  @Output() submitted: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  form: FormGroup = this.fb.group({
    infoForCancellation: ['', Validators.required],
  });

  constructor(
    modalService: ModalService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    super(modalService);
  }

  get data(): CancelInterviewModalData {
    return this.modal.data;
  }

  get infoForCancellation(): FormControl {
    return this.form.get('infoForCancellation') as FormControl;
  }

  confirmCancelation(): void {
    if (!this.modal) {
      this.submitted.emit(this.form);
      return;
    }

    this.data.confirm(this.data.interviewToCancel, this.infoForCancellation.value);
    this.closeModal();
  }

  goToAccept(): void {
    this.router.navigate(['/accept-interview/' + this.interviewGuid], { queryParamsHandling: 'merge' });
  }
}
