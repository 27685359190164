import { Component } from '@angular/core';
import { AbstractModal } from '../abstract-modal';
import { UniversalModalData } from 'src/app/model/modal.interface';
import { UniversalModal } from 'src/app/classes/modal.class';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-universal-modal',
  templateUrl: './universal-modal.component.html',
  styleUrls: ['./universal-modal.component.scss']
})
export class UniversalModalComponent extends AbstractModal {

  modal: UniversalModal;

  constructor(
    modalService: ModalService
  ) {
    super(modalService);
  }

  get data(): UniversalModalData {
    return this.modal.data;
  }

  confirm(): void {
    this.data.confirm();
    this.closeModal();
  }

  close(): void {
    if (this.data.close) {
      this.data.close();
    }

    this.closeModal();
  }
}
