import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Job } from '../classes/job.class';

@Injectable({ providedIn: 'root' })
export class JobStoreService {

  private _job$: BehaviorSubject<Job> = new BehaviorSubject<Job>(null);
  private _activeStep$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor() {}

  get activeStep(): Observable<number> {
    return this._activeStep$.asObservable();
  }

  setActiveStep(activeIndex: number): void {
    this.setItem('activeIndex', activeIndex);
    this._activeStep$.next(activeIndex);
  }

  get job(): Job {
    return this._job$.getValue();
  }

  set job(data: Job) {
    this._job$.next(data);
  }

  get job$(): Observable<Job> {
    return this._job$.asObservable();
  }

  updateJob(data: Partial<Job>): void {
    const job = {
      ...this.job,
      ...data,
    };

    sessionStorage.setItem('job', JSON.stringify(job));

    this.job = {
      ...job,
    } as Job;
  }

  getJobFromSessionStorage(): Job {
    const jobObj = JSON.parse(sessionStorage.getItem('job'));

    this.job = jobObj ? { ...jobObj } : null;

    return this._job$.getValue();
  }

  getItem(name: string): any {
    return JSON.parse(sessionStorage.getItem(name));
  }

  setItem(name: string, item: any): void {
    sessionStorage.setItem(name, JSON.stringify(item));
  }

  removeItem(name: string): void {
    sessionStorage.removeItem(name);
  }

  clearStore(): void {
    this._job$.next(null);
    sessionStorage.removeItem('job');
    sessionStorage.removeItem('activeIndex');
    sessionStorage.removeItem('autoSaveDraftId');
    sessionStorage.removeItem('ai');
  }
}
