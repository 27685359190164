import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ALLOWED_IMAGE_FORMATS, ALLOWED_VIDEO_EXTENSIONS, ALLOWED_VIDEO_FORMATS } from '../resources/allowed-file-formats';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Injectable({providedIn: 'root'})
export class UploadImageService {

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService
  ) {}

  readUploadedFileAsURL: (file: File, imageSizeLimit?: number) => Promise<string> = (file: File, imageSizeLimit?: number) => {
    if (imageSizeLimit && file.size > imageSizeLimit) {
      this.toastr.error(this.translateService.instant('SHARED.IMAGE_SIZE_SMALLER_THAN'));
      return Promise.reject();
    }
    if (ALLOWED_IMAGE_FORMATS.includes(file.type)) {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const result = reader.result.toString();
          resolve(result);
        };

        reader.onerror = () => {
          reader.abort();
          this.toastr.error(this.translateService.instant('SHARED.UPLOAD_IMG_ERR_MSG'));
          reject();
        };
      });
    } else {
      this.toastr.error(this.translateService.instant('SHARED.UPLOAD_FILE_TYPE_NOT_ALLOWED'));
      return Promise.reject();
    }
  };

  uploadImage(entries: NgxFileDropEntry[], imageSizeLimit?: number): Promise<string>[] {
    return entries.map(({fileEntry}: NgxFileDropEntry) => {
      if (fileEntry.isFile && !fileEntry.isDirectory) {
        return new Promise((resolve, reject) => {
          (fileEntry as FileSystemFileEntry).file((file: File) => {
            this.readUploadedFileAsURL(file, imageSizeLimit)
              .then((image) => resolve(image))
              .catch(() => reject());
          });
        });
      } else {
        this.toastr.error(this.translateService.instant('SHARED.UPLOAD_DIRECTORIES_NOT_ALLOWED'));
        return Promise.reject();
      }
    });
  }


  checkMediaForUpload(entries: NgxFileDropEntry[]): Promise<{file: File, isVideo: boolean}>[] {
    return entries.map(({fileEntry}: NgxFileDropEntry) => {
      if (fileEntry.isFile && !fileEntry.isDirectory) {
        return new Promise((resolve, reject) => {
          (fileEntry as FileSystemFileEntry).file((file: File) => {
            const isVideo = this.checkIsVideoFile(file);
            resolve({file, isVideo});
          });
        });
      } else {
        this.toastr.error(this.translateService.instant('SHARED.UPLOAD_DIRECTORIES_NOT_ALLOWED'));
        return Promise.reject();
      }
    });
  }

  checkIsVideoFile(file: File): boolean {
    let isVideo: boolean;

    if (file.type === '') {
      const extension = file.name.substring(file.name.lastIndexOf('.'));
      isVideo = ALLOWED_VIDEO_EXTENSIONS.includes(extension);
    } else {
      isVideo = ALLOWED_VIDEO_FORMATS.includes(file.type);
    }

    return isVideo;
  }
}
