import { NotificationType } from '../model/notification.interface';
import { ICommentResponse } from '../model/comment.interface';
import { INotificationEmailResponse } from '../model/email-response.interface';
import { User, UserCommunicator } from '../model/user.interface';
import { IRefAppResponse } from '../model/ref-app.interface';
import { IUploadedCVResponse } from '../model/uploaded-cv-response.interface';
import { Company } from './organization.class';
import { ICandidateProvidedInfo } from '../model/candidate-provided-info.interface';
import { ISriBackgroundCheckResponse } from '../model/sri.interface';
import { ListJob } from '../model/job.interface';
import { IRequisitionNotification } from '../model/requisition.model';
import { IAlvaResponse } from '../model/alva-labs.interface';

export class Notification {

  applicationId: number;
  createdAt: Date;
  icon: string;
  name: string;
  translationKey: string;
  type: NotificationType;

  constructor({applicationId, name, createdAt, type, icon, translationKey}: Notification) {
    this.applicationId = applicationId;
    this.createdAt = createdAt;
    this.icon = icon;
    this.name = name;
    this.translationKey = translationKey;
    this.type = type;
  }
}

export class RequisitionNotification extends Notification {

  notificationId: number;
  requisitionId: number;
  status: string;
  requisitionNotificationType: string;

  constructor(requisitionNotification: IRequisitionNotification) {
    super({
      applicationId: null,
      createdAt: requisitionNotification.createdAt,
      icon: 'icon-mail',
      name: requisitionNotification.requisition.requisitionTitle,
      translationKey: 'INCOMING_REQUISITION',
      type: NotificationType.requisitionNotifications
    });

    this.status = requisitionNotification.requisition.requisitionStatus;
    this.requisitionId = requisitionNotification.requisition.id;
    this.notificationId = requisitionNotification.id;
    this.requisitionNotificationType = requisitionNotification.requisitionNotificationType;
  }
}

export class EmailNotification extends Notification {

  id: number;
  readBy: string[];
  sender: User;

  constructor(
    {applicationEmail, readByCustomers, readByEnterpriseManagers, createdAt, id}: INotificationEmailResponse
  ) {
    const { application, senderCustomer, senderEnterpriseManager} = applicationEmail;

    super({
      applicationId: application.id,
      createdAt,
      icon: 'icon-mail',
      name: application.candidateAppliedWithName,
      translationKey: 'INCOMING_EMAIL',
      type: NotificationType.email
    });

    this.sender = senderCustomer || senderEnterpriseManager;
    this.id = id;

    this.readBy = [];
    readByCustomers.forEach((user: UserCommunicator) => this.readBy.push(user.email));
    readByEnterpriseManagers.forEach((user: UserCommunicator) => this.readBy.push(user.email));
  }
}

export class TagNotification extends Notification {

  constructor(
    {creatorCustomer, creatorEnterpriseManager, application, createdAt, id}: ICommentResponse,
    company: Company
  ) {

    const customer = creatorCustomer || creatorEnterpriseManager;

    const anonymousCustomer = customer.anonymousCustomer || customer.anonymousEnterpriseManager;
    const name = anonymousCustomer ? company.name : customer.name;

    super({
      applicationId: application.id,
      createdAt,
      icon: 'icon-monkey',
      name,
      translationKey: 'TAG_NOTIFICATION',
      type: NotificationType.comment
    });
  }
}

export class RefAppNotification extends Notification {

  constructor({application, updatedAt}: IRefAppResponse) {

    super({
      applicationId: application.id,
      createdAt: updatedAt,
      icon: 'icon-refapp',
      name: application.candidateAppliedWithName,
      translationKey: 'REF_APP_NOTIFICATION',
      type: NotificationType.refApp
    });
  }
}

export class UploadedCVNotification extends Notification {

  constructor({application, updatedAt}: IUploadedCVResponse) {

    super({
      applicationId: application.id,
      createdAt: updatedAt,
      icon: 'icon-attach',
      name: application.candidateAppliedWithName,
      translationKey: 'UPLOADED_CV_NOTIFICATION',
      type: NotificationType.uploadedCV
    });
  }
}

export class CandidateProvidedInfoNotification extends Notification {

  constructor({application, updatedAt}: ICandidateProvidedInfo) {

    super({
      applicationId: application.id,
      createdAt: updatedAt,
      icon: 'icon-account-lock',
      name: application.candidateAppliedWithName,
      translationKey: 'CANDIDATE_PROVIDED_INFO',
      type: NotificationType.candidateProvidedInfo
    });
  }
}

export class SriBackgroundCheckNotification extends Notification {

  constructor({application, updatedAt}: ISriBackgroundCheckResponse) {

    super({
      applicationId: application.id,
      createdAt: updatedAt,
      icon: 'icon-sri',
      name: application.candidateAppliedWithName,
      translationKey: 'SRI_BG_CHECK_NOTIFICATION',
      type: NotificationType.sriBgCheck
    });
  }
}

export class AdwayNotification extends Notification {
  recruiterPageUrl: string;

  constructor(job: ListJob) {
    super({
      applicationId: null,
      createdAt: job.adwayCampaignInfo.updatedAt,
      icon: 'icon-share',
      name: job.jobTitle,
      translationKey: 'ADWAY_NOTIFICATION',
      type: NotificationType.adway
    });
    const link = job.adwayCampaignInfo.recruiterPageUrl;
    this.recruiterPageUrl = link.startsWith('http://') || link.startsWith('https://') ?
                            link : `http://${link}`;
  }
}

export class AlvaNotification extends Notification {

  constructor({application, updatedAt}: IAlvaResponse) {

    super({
      applicationId: application.id,
      createdAt: updatedAt,
      icon: 'icon-alva',
      name: application.candidateAppliedWithName,
      translationKey: 'ALVA_NOTIFICATION',
      type: NotificationType.alva
    });
  }
}
