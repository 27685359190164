import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ArchiveJobModalData } from 'src/app/model/modal.interface';
import { ModalService } from 'src/app/services/modal.service';
import { AbstractModal } from '../abstract-modal';

@Component({
  selector: 'app-archive-job-modal',
  templateUrl: './archive-job-modal.component.html',
  styleUrls: ['./archive-job-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveJobModalComponent extends AbstractModal {

  constructor(
    modalService: ModalService
  ) {
    super(modalService);
  }

  get data(): ArchiveJobModalData {
    return this.modal.data;
  }

  archive(): void {
    this.data.archive();
    this.closeModal();
  }

  archiveAndSendFeedback(): void {
    this.data.archiveAndSendFeedback();
    this.closeModal();
  }

  close(): void {
    if (this.data.close) {
      this.data.close();
    }

    this.closeModal();
  }

}
