export const LANGUAGES = [
  {
    key: 'en',
    name: 'JOBS.PERSONALITY_TEST_LANGUAGES.ENGLISH'
  },
  {
    key: 'no',
    name: 'JOBS.PERSONALITY_TEST_LANGUAGES.NORWEGIAN'
  },
  {
    key: 'sv',
    name: 'JOBS.PERSONALITY_TEST_LANGUAGES.SWEDISH'
  },
  {
    key: 'de',
    name: 'JOBS.PERSONALITY_TEST_LANGUAGES.GERMAN'
  },
  {
    key: 'da',
    name: 'JOBS.PERSONALITY_TEST_LANGUAGES.DANISH'
  },
  {
    key: 'fi',
    name: 'JOBS.PERSONALITY_TEST_LANGUAGES.FINNISH'
  },
];
