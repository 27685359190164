<div class="custom-modal-wrapper">
  <div class="close-icon-wrapper">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <div class="custom-modal" [ngClass]="{'no-questions' : !questionsList.length}">
    <h3 class="modal-title" *ngIf="!generatedAiQuestions; else generateQuestions">
      {{ 'JOBS.ADD_AI_QUESTION' | translate }}
    </h3>
    <p *ngIf="!questionsList.length">{{ 'JOBS.KEY_WORDS_DISCRIPTION' | translate }}</p>
    <ng-template #generateQuestions>{{ 'JOBS.USE_GENERATED_AI_SKILLS_QUESTIONS' | translate }}</ng-template>
    <div class="modal-body">

      <div class="list-wrapper" *ngIf="!generatedAiQuestions">
        <div class="form-group">
            <input [placeholder]="'JOBS.ADD_KEY_WORDS' | translate" class="form-control" [(ngModel)]="keyWord" (keyup.enter)="onEnter()" type="text" >
        </div>

        <div *ngIf="keyWords.length">
          <p class="list-title">{{ 'JOBS.KEY_WORDS_LIST_TITLE' | translate }}</p>
          <ul class="list-items">
            <li *ngFor="let word of keyWords; let i" class="item">{{ word }} <span (click)="removeKeyWord(i)">x</span></li>
          </ul>
        </div>
      </div>

      <!-- LIST OF QUESTIONS -->
      <app-question-list class="question-list"
                          *ngIf="questionsList"
                          [questionsList]="questionsList"
                          [hideShowIcon]="false"
                          [noDataMessage]="false"
                          [selectQuestionWithoutId]="true"
                          [selectAll]="true"
                          (chosenItem)="onChosenItem($event)">
      </app-question-list>
    </div>

    <div class="button-wrapper" >
      <div *ngIf="generatedAiQuestions; else generate">
        <button (click)="closeModal()"
                class="btn btn-outline-primary">
          {{ "BUTTONS.CANCEL" | translate }}
        </button>
        <button class="btn btn-primary border-white"
                (click)="useQuestions()">
          {{ "BUTTONS.APPLY" | translate }}
        </button>
      </div>
      <ng-template #generate>
        <button (click)="getData()"
          class="btn btn-primary border-white">
          {{ "BUTTONS.GENERATE_AI_QUESTIONS" | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</div>
