import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';

export enum ScrollToComponents {
  'email' = 'CandidateEmailComponent',
  'sms' = 'CandidateSmsComponent',
  'interview' = 'CandidateInterviewComponent',
  'test' = 'CandidateTestComponent',
  'tilTid' = 'TilTidComponent',
  'comments' = 'CandidateCommentsComponent',
  'refApp' = 'RefAppComponent',
  'sriBgCheck' = 'SriBackgroundCheckComponent',
  'alvalabs' = 'AlvaLabsComponent'
}

@Injectable({ providedIn: 'root' })
export class ScrollToService {
  candidateCardScrollTo$: Subject<ScrollToComponents> = new Subject<ScrollToComponents>();
}
