import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { PerformActionService } from 'src/app/services/perform-action.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { PERFORM_ACTION_TYPE } from 'src/app/resources/perform-action-type';
import { StatusFlowService } from 'src/app/services/status-flow.service';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { AbstractModal } from '../../modal/abstract-modal';
import { ModalService } from 'src/app/services/modal.service';
import { PerformActionModalData, UniversalModalData } from 'src/app/model/modal.interface';
import { Trigger, TriggerValue } from 'src/app/model/trigger.interface';
import { TriggerService } from 'src/app/services/trigger.service';
import {
  ExportPlatform,
  ExportPlatforms,
  PERFORM_ACTION_COMPONENTS,
  PerformActionComponents
} from 'src/app/model/perform-action.interface';
import { RefAppExportedInfo } from 'src/app/model/ref-app.interface';
import { ExportService } from 'src/app/services/export.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { createJobTriggerForm } from 'src/app/resources/job-forms';
import { HiringStatus } from 'src/app/classes/hiring-status.class';
import { UniversalModalComponent } from '../../modal/universal-modal/universal-modal.component';
import { Modal } from 'src/app/classes/modal.class';
import { RecruitmentService } from 'src/app/services/recruitment.service';

@Component({
  selector: 'app-perform-action',
  templateUrl: './perform-action.component.html',
  styleUrls: ['./perform-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerformActionComponent extends AbstractModal implements OnInit, OnDestroy {

  titleKey = 'SHARED.PERFORM_ACTION';
  showActionButtons = false;
  activeComponent: PerformActionComponents;
  message: string;
  showStatuses = true;
  isAppWithDeletedDataSelected = false;

  hiringStatuses: HiringStatus[] = [];
  trigger: Trigger;
  jobTriggerHiringStatus: HiringStatus;

  exportPlatforms: ExportPlatform[] = this.exportService.getExportPlatforms();

  readonly actionComponents = PerformActionComponents;
  performActionComponents = PERFORM_ACTION_COMPONENTS;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    modalService: ModalService,
    private loaderService: LoaderService,
    private performActionService: PerformActionService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private statusFlowService: StatusFlowService,
    private errorHandlingService: ErrorHandlingService,
    private triggerService: TriggerService,
    private cdr: ChangeDetectorRef,
    private recruitmentService: RecruitmentService,
    private exportService: ExportService
  ) {
    super(modalService);
  }

  get data(): PerformActionModalData {
    return this.modal.data;
  }

  get isCompanyTrigger(): boolean {
    return this.data.isCompanyTrigger;
  }

  get isJobTrigger(): boolean {
    return this.data.isJobTrigger;
  }

  get jobTriggersForm(): FormArray {
    return this.data.jobTriggersForm;
  }

  get excludedJobTriggersHiringStatusIds(): FormControl {
    return this.data.excludedJobTriggersHiringStatusIds;
  }

  get companyTriggerHiringStatus(): HiringStatus {
    return this.data.companyTriggerHiringStatus;
  }

  get alvaTriggerData(): FormGroup {
    return this.jobTriggersForm.get('alvaLabsData') as FormGroup;
  }

  ngOnInit(): void {
    this.getHiringStatuses();
    if (this.isCompanyTrigger || this.isJobTrigger) {
      this.performActionComponents = this.performActionComponents
        .filter(component => component.name !== PerformActionComponents.hide && component.name !== PerformActionComponents.unhide);
    }

    this.checkIfAppWithDeletedDataIsSelected();
  }

  getHiringStatuses(): void {
    this.loaderService.show();
    this.statusFlowService
      .getHiringStatuses()
      .subscribe((hiringStatuses: HiringStatus[]) => {
        this.hiringStatuses = hiringStatuses;
        this.initializeTriggers();
        this.cdr.detectChanges();
        this.loaderService.hide();
      });
  }

  initializeTriggers(): void {
    if (this.isJobTrigger) {
      this.showStatuses = true;
      this.showActionButtons = false;
      this.cdr.detectChanges();
    }

    if (this.isCompanyTrigger) {
      this.showStatuses = false;
      this.showActionButtons = true;
      this.activeComponent = PerformActionComponents.sms;
      this.cdr.detectChanges();
    }

    if (!this.isCompanyTrigger && !this.isJobTrigger) {
      this.showStatuses = true;
      this.showActionButtons = true;
      this.cdr.detectChanges();
      return;
    }

    if (this.companyTriggerHiringStatus?.companyTrigger) {
      this.getTrigger();
      return;
    }
  }

  getTrigger(): void {
    this.loaderService.show();
    this.triggerService
      .getTrigger(this.companyTriggerHiringStatus.companyTrigger.id)
      .subscribe((trigger: Trigger) => {
        this.activeComponent = trigger.actionType;
        this.trigger = trigger;
        this.cdr.detectChanges();

        if (this.activeComponent === PerformActionComponents.askForCv || this.activeComponent === PerformActionComponents.refapp) {
          this.loaderService.hide();
        }
      });
  }

  askForCv(): void {
    if (this.isCompanyTrigger || this.isJobTrigger) {
      this.activeComponent = PerformActionComponents.askForCv;
      return;
    }

    this.openAskForCVPreview(false);
  }

  sendAskForCV(): void {
    this.loaderService.show();
    this.performActionService.batchAskForCv()
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(() => {
        this.toastr.success(this.translateService.instant('JOBS.SUCCESS'));
        this.loaderService.hide();
      });
  }

  setJobTrigger({trigger, status}: { trigger: Trigger, status: HiringStatus }): void {
    this.jobTriggerHiringStatus = status;
    this.trigger = trigger;

    if (this.trigger) {
      this.trigger.applicationHiringStatus = status;
    }

    this.showStatuses = false;
    this.showActionButtons = true;
    this.activeComponent = trigger?.actionType || PerformActionComponents.sms;
    this.cdr.detectChanges();
  }

  removeJobTrigger(status: HiringStatus): void {
    this.jobTriggerHiringStatus = status;
    const index = this.findExistingJobTriggerIndex();

    if (index >= 0) {
      this.jobTriggersForm.removeAt(index);
      this.jobTriggersForm.markAsDirty();
    }

    this.jobTriggerHiringStatus = null;
  }

  includeExcludeCompanyLevelTriggerForJob(status: HiringStatus): void {

    const ignoredTriggersHiringStatusIds: number[] = this.excludedJobTriggersHiringStatusIds.value || [];
    const index = ignoredTriggersHiringStatusIds.indexOf(status.id);

    if (index >= 0) {
      ignoredTriggersHiringStatusIds.splice(index, 1);
    } else {
      ignoredTriggersHiringStatusIds.push(status.id);
    }

    this.excludedJobTriggersHiringStatusIds.setValue(ignoredTriggersHiringStatusIds);
    this.jobTriggerHiringStatus = null;
    this.jobTriggersForm.markAsDirty();
  }

  addEditJobTrigger(trigger: Trigger): void {
    this.showStatuses = true;
    this.showActionButtons = false;
    this.activeComponent = null;

    const existingJobTriggerIndex = this.findExistingJobTriggerIndex();
    if (existingJobTriggerIndex >= 0) {
      if (trigger.actionType === PerformActionComponents.alva) {
        this.jobTriggersForm.reset();
      }
      this.jobTriggersForm.at(existingJobTriggerIndex).patchValue(trigger);
    } else {
      const triggerForm = createJobTriggerForm();
      triggerForm.patchValue(trigger);
      this.jobTriggersForm.push(triggerForm);
    }
    this.jobTriggersForm.markAsDirty();

    this.jobTriggerHiringStatus = null;
    this.excludedJobTriggersHiringStatusIds.setValue([]);
    this.cdr.detectChanges();
  }

  findExistingJobTriggerIndex(): number {
    const jobTriggers: Trigger[] = this.jobTriggersForm.value;
    return jobTriggers
      .findIndex((trigger: Trigger) => {
        return (trigger.applicationHiringStatus as HiringStatus).id === this.jobTriggerHiringStatus.id;
      });
  }

  openAskForCVPreview(isTrigger: boolean, isJobTrigger?: boolean, trigger?: Trigger): void {
    this.loaderService.show();
    this.performActionService.getAskForCvEmailPreview(isTrigger)
    .pipe(
      catchError((errorResponse: HttpErrorResponse) =>
        this.errorHandlingService.handleBackendError(errorResponse)
      )
    )
    .subscribe((content: string) => {
      const data: UniversalModalData = {
        title: this.translateService.instant('BUTTONS.EMAIL_PREVIEW'),
        htmlContent: content,
        disableHtmlContent: true,
        confirmBtnTitle: this.translateService.instant('BUTTONS.PROCEED'),
        cancelBtnTitle: this.translateService.instant('BUTTONS.CANCEL'),
        isEmailPreview: true,
        confirm: () => !isTrigger ? this.sendAskForCV() :
                       isJobTrigger ? this.addEditJobTrigger(trigger) : this.addEditCompanyLevelTrigger(trigger)
      };
      this.modalService.addModal(new Modal(UniversalModalComponent, data));
      this.loaderService.hide();
    });
  }

  addEditTrigger(trigger: Trigger): void {
    if (this.isJobTrigger) {
      trigger.applicationHiringStatus = this.jobTriggerHiringStatus;

      if (this.activeComponent === PerformActionComponents.askForCv) {
        this.openAskForCVPreview(true, true, trigger);
        return;
      }

      this.addEditJobTrigger(trigger);
      return;
    }

    if (!this.trigger) {
      trigger.applicationHiringStatus = this.companyTriggerHiringStatus.id;
    }

    if (this.activeComponent === PerformActionComponents.email
        || this.activeComponent === PerformActionComponents.askForCv
        || this.activeComponent === PerformActionComponents.refapp
        || this.activeComponent === PerformActionComponents.alva) {
      trigger.nextHiringStatus = (trigger.nextHiringStatus as HiringStatus)?.id || null;
    }

    if (this.activeComponent === PerformActionComponents.askForCv) {
      this.openAskForCVPreview(true, false, trigger);
      return;
    }

    this.addEditCompanyLevelTrigger(trigger);
  }

  addEditCompanyLevelTrigger(trigger: Trigger): void {
    this.loaderService.show();

    const request$ = this.trigger
      ? this.triggerService.editTrigger(trigger, this.trigger.id)
      : this.triggerService.addTrigger(trigger);

    request$.pipe(
      catchError((errorResponse: HttpErrorResponse) =>
        this.errorHandlingService.handleBackendError(errorResponse)
      ))
      .subscribe(() => {
        this.loaderService.hide();

        if (this.isCompanyTrigger) {
          this.statusFlowService.refreshStatuses();
        }

        this.onSent();
      });
  }

  setTrigger(triggerValue: TriggerValue): void {
    const trigger: Trigger = {
      ...triggerValue,
      actionType: this.activeComponent
    };

    this.addEditTrigger(trigger);
  }

  exportToRefApp(): void {
    if (this.isCompanyTrigger || this.isJobTrigger) {
      this.activeComponent = PerformActionComponents.refapp;
      return;
    }

    this.loaderService.show();

    this.performActionService
      .batchExportToRefApp()
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.onSent(null);
          return this.errorHandlingService.handleBackendError(errorResponse);
        })
      )
      .subscribe((response: HttpResponse<RefAppExportedInfo>) => {
        this.loaderService.hide();
        const message = this.setRefAppMessage(response);
        this.onSent(message);
      });
  }

  setRefAppMessage({status, body}: HttpResponse<RefAppExportedInfo>): string {
    if (status !== 206) {
      this.toastr.success(
        this.translateService.instant('JOBS.SUCCESS')
      );

      return this.translateService.instant('RECRUITMENT.SENT_SUCCESS_MSG.REF_APP');
    }

    if (body.numberOfCandidatesWhoWereExportedToRefapp === 0) {
      return this.translateService.instant('RECRUITMENT.EXPORT_ALL_CANDIDATES_REF_APP_FAILED');
    }

    return this.translateService
      .instant(
        'RECRUITMENT.EXPORT_FAILED',
        {
          exported: body.numberOfCandidatesWhoWereExportedToRefapp,
          notExported: body.numberOfCandidatesForExportingToRefapp,
          names: body.applicationsThatWereNotExported.map(app => app.candidate).join(', '),
        }
      );
  }

  onBack(): void {
    if (this.isJobTrigger) {
      this.showStatuses = true;
      this.showActionButtons = false;
      this.activeComponent = null;
      this.jobTriggerHiringStatus = null;
    }

    if (!this.isCompanyTrigger && !this.isJobTrigger) {
      this.titleKey = 'SHARED.PERFORM_ACTION';

      this.showActionButtons = true;
      this.showStatuses = true;
      this.activeComponent = null;
    }

    this.cdr.detectChanges();
  }

  onSent(message?: string): void {
    if (this.isJobTrigger) {
      this.showActionButtons = false;
      this.showStatuses = true;
      this.activeComponent = null;
      this.cdr.detectChanges();

      return;
    }

    if (message) {
      this.message = message;
      this.cdr.detectChanges();

      setTimeout(() => {
        this.closeModal();
      }, 5000);
    } else {
      this.activeComponent = null;
      this.showActionButtons = false;
      this.closeModal();
    }

  }

  showExportPlatform(platform: ExportPlatforms): void {
    const component = PerformActionComponents[platform];

    if (component === PerformActionComponents.refapp) {
      this.exportToRefApp();
      return;
    }

    this.showComponent(component);
  }

  hideUnhideApplications(hide: boolean): void {
    this.loaderService.show();
    this.performActionService.batchHideUnhideApplications(hide)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(() => {
        const statusIds = this.hiringStatuses.map(hStatus => hStatus.id);
        this.recruitmentService.setChangedStatuses(statusIds);

        this.toastr.success(this.translateService.instant('JOBS.SUCCESS'));
        this.loaderService.hide();
      });
  }

  showComponent(activeComponent: PerformActionComponents, event?: Event): void {
    event?.stopPropagation();

    if (activeComponent === PerformActionComponents.askForCv) {
      this.askForCv();
      return;
    }

    if (activeComponent === PerformActionComponents.hide) {
      this.hideUnhideApplications(true);
      return;
    }

    if (activeComponent === PerformActionComponents.unhide) {
      this.hideUnhideApplications(false);
      return;
    }

    if (!this.isCompanyTrigger && !this.isJobTrigger) {
      this.showActionButtons = false;
      this.showStatuses = false;
    }

    this.activeComponent = activeComponent;
    this.titleKey = PERFORM_ACTION_TYPE[activeComponent].translationKey;
    this.cdr.detectChanges();
  }

  checkIfAppWithDeletedDataIsSelected(): void {
    const componentIfDataIsDeleted = [
      {
        name: PerformActionComponents.hide,
        translationKey: 'BUTTONS.HIDE'
      },
      {
        name: PerformActionComponents.unhide,
        translationKey: 'BUTTONS.UNHIDE'
      }
    ];

    const applications = this.performActionService.getSelectedApplications();

    if (applications.some(app => app.isCandidateDataDelated)) {
      this.isAppWithDeletedDataSelected = true;
      this.performActionComponents = componentIfDataIsDeleted;
      this.cdr.detectChanges();
      return;
    }

    const appThatNeedIsDataDeletedCheck = applications.filter(app => app.doesNeedDetaDelatedCheck);

    this.loaderService.show();

    this.performActionService.deletedCheck(appThatNeedIsDataDeletedCheck.map(app => app.appId))
    .subscribe((value) => {
      this.isAppWithDeletedDataSelected = value;
      if (this.isAppWithDeletedDataSelected) {
        this.performActionComponents = componentIfDataIsDeleted;
        this.cdr.detectChanges();
      }
      this.loaderService.hide();
    });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
