import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDayMonth'
})
export class DateDayMonthPipe implements PipeTransform {

  transform(dateString: Date): string {
    const date = new Date(dateString);
    return (date.getDate()<10?'0':'') + date.getDate() + '.' + (date.getMonth()<9?'0':'') + (date.getMonth()+1) + '.' + date.getFullYear();
  }
}
