<div class="custom-modal-wrapper" [ngClass]="{'email-preview': data.isEmailPreview}">
  <div class="custom-modal-header">
    <h3 class="custom-modal-title">
      {{ data.title | translate }}
    </h3>
    <div class="close-icon-wrapper">
      <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
    </div>
  </div>
  <div class="custom-modal">
    <div class="modal-question-wrapper">
      <div class="modal-question" *ngIf="data.content">
        <div class="content">{{ data.content | translate }}</div>
        <a [href]="data.link" target="_blank">{{ data.link }}</a>
      </div>
      <div class="modal-question-html" *ngIf="data.htmlContent">
        <div class="content" [ngClass]="{'disable-buttons': data.disableHtmlContent}" [innerHTML]="data.htmlContent | domSanitizer: 'html'"></div>
      </div>
    </div>
    <div class="button-wrapper">
      <button (click)="close()"
              *ngIf="!data.hideCancelButton"
              class="btn btn-outline-primary">{{ data.cancelBtnTitle | translate }}</button>
      <button (click)="confirm()"
              *ngIf="!data.hideConfirmButton"
              class="btn btn-primary">{{ data.confirmBtnTitle | translate }}</button>
    </div>
  </div>
</div>
