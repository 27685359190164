/* eslint-disable quotes */
import { AgreementDinamicControl, AgreementFormBranch, AgreementFormUser, IAgreementForm } from '../model/agreement.model';

export class AgreementFormClass {

  customersAllowed?: AgreementFormUser[];
  title: string;
  dinamicForm: AgreementDinamicControl[];
  id?: number;
  creator?: AgreementFormUser | string;
  createdAt?: string;
  terms: string;
  branch: number | AgreementFormBranch;
  status: string;

  constructor(agreementForm: IAgreementForm) {
    this.customersAllowed = agreementForm.customersAllowed as AgreementFormUser[] || [];
    this.title = agreementForm.title;
    this.dinamicForm = agreementForm.agreementFormElements;
    this.id = agreementForm.id;
    this.createdAt = agreementForm.createdAt;
    this.creator = agreementForm.creator;
    this.terms = agreementForm.terms;
    this.branch = agreementForm.branch;
    this.status = agreementForm.status;
  }

  static prepareForPublish(agreementFormClass: AgreementFormClass, agreementFormGuid?: string): IAgreementForm {
    const customersAllowed = [];

    if (agreementFormClass.customersAllowed?.length) {
      agreementFormClass.customersAllowed?.forEach(user => {
        customersAllowed.push(user.id);
      });
    }

    agreementFormClass.dinamicForm.forEach(item => {
      Object.keys(item).forEach(key => {
        if (((!item[key] || !item[key].length) && key !== 'required')) {
          item[key] = null;
        };
      });
      delete item['selectedValues'];
      delete item['textFieldEditorValue'];
    });

    const agreementForm: IAgreementForm = {
      title: agreementFormClass.title,
      agreementFormElements: agreementFormClass.dinamicForm,
      customersAllowed: customersAllowed,
      terms: agreementFormClass.terms,
      branch: agreementFormClass.branch,
      ...(agreementFormGuid && {status: agreementFormClass.status})
    };

    return agreementForm;
  }
}
