import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RequisitionListModal, RequisitionModal } from 'src/app/classes/modal.class';
import { RequisitionClass } from 'src/app/classes/requisition.class';
import { ModalService } from 'src/app/services/modal.service';
import { AbstractModal } from '../../modal/abstract-modal';
import { JobStoreService } from '../../../services/job-store.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-requisitions-list-modal',
  templateUrl: './requisitions-list-modal.component.html',
  styleUrls: ['./requisitions-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequisitionsListModalComponent extends AbstractModal implements OnInit {
  modal: RequisitionListModal;
  requisitions: RequisitionClass[];

  constructor(
    modalService: ModalService,
    private jobStore: JobStoreService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private translateService: TranslateService
    ) {
    super(modalService);
   }

  ngOnInit(): void {
    this.requisitions = this.modal.data.data;
    this.setRequisition();
  }

  setRequisition(): void {
    const requistion = this.jobStore.getJobFromSessionStorage().requisition;
    if (requistion) {
      this.requisitions = this.modal.data.data.map((req: RequisitionClass) => {
        if (req.id === requistion.id) {
          return {...req, attached: true};
        } else {
          return {...req, attached: false};
        }
      });
    }
    this.modal.data.confirm();
    this.cdr.detectChanges();
  }

  openRequisition(requisition: RequisitionClass): void {
    const data = {
      ...requisition,
      confirm: () => this.setRequisition()
    };

    this.modalService.addModal(new RequisitionModal(data));
  }

  removeRequisition(): void {
    const jobData = this.jobStore.getJobFromSessionStorage();
    delete jobData.requisition;
    this.jobStore.updateJob(jobData);

    this.requisitions = this.requisitions.map((req: RequisitionClass) => {
      return {...req, attached: false};
    });

    this.toastr.success(
      this.translateService.instant('REQUISITION_LIST.REMOVE_REQUISITION_SUCCESS_MSG')
    );
    this.modal.data.confirm();
  }
}
