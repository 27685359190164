import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string): any {
    if (value !== '' && value !== null) {
      const arr: string[] = value.trim().replace(/\s\s+/g, ' ').split(' ');
      let initials = '';
      arr.forEach((el: string, index: number) => initials += (el[0].toString()));
      return initials.trim().toUpperCase().slice(0, 2);
    } else {
      return null;
    }
  }
}
