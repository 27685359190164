import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, forkJoin, Subject, takeUntil } from 'rxjs';
import { RequisitionModal } from 'src/app/classes/modal.class';
import { ControlType, RequisitionMode, RequisitionStatus } from 'src/app/model/requisition.model';
import { ModalService } from 'src/app/services/modal.service';
import { RequisitionService } from 'src/app/services/requisition.service';
import { AbstractModal } from '../../modal/abstract-modal';
import { PublishingPlatformsService } from 'src/app/services/publishing-platforms/publishing-platforms.service';
import { SetupService } from 'src/app/services/setup.service';
import { PublishingPlatforms } from 'src/app/model/publishing-platform.interface';
import { LoaderService } from 'src/app/services/loader.service';
import { RequisitionFormClass } from 'src/app/classes/requisition-form.class';
import { JobStoreService } from 'src/app/services/job-store.service';
import { RequisitionModalData } from 'src/app/model/modal.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-requisition',
  templateUrl: './requisition.component.html',
  styleUrls: ['./requisition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequisitionComponent extends AbstractModal implements OnInit, OnDestroy {
  form: FormGroup = this.fb.group({
    dinamicForm: this.fb.array([]),
    title: [''],
    comment: [''],
    platforms: [[]]
  });

  modal: RequisitionModal;
  mode: RequisitionMode;
  data: RequisitionModalData;
  platformsList: PublishingPlatforms;
  companyName: string;
  REQUISITION_MODE = RequisitionMode;
  CONTROL_TYPE = ControlType;
  REQUISITION_STATUS = RequisitionStatus;
  isJobEditOrCreate = false;
  isJobCreate = false;
  notificationId: number;
  isJobEdit: boolean;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  get dinamicForm(): FormArray {
    return this.form.controls['dinamicForm'] as FormArray;
  }

  get platforms(): FormControl {
    return this.form.controls['platforms'] as FormControl;
  }

  @HostBinding('class.isModal') isModal = false;

  constructor(
    modalService: ModalService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private requesitionService: RequisitionService,
    private publishingPlatformsService: PublishingPlatformsService,
    private setupService: SetupService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private requisitionService: RequisitionService,
    private jobStore: JobStoreService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {
    super(modalService);
    this.notificationId = this.router.getCurrentNavigation()?.extras.state?.notificationId;
  }

  ngOnInit(): void {
    this.platforms.disable();
    this.loaderService.show();
    this.companyName = this.setupService.currentCompany.name;
    this.isJobCreate = this.router.url.includes('job-create');
    this.isJobEditOrCreate = this.router.url.includes('job-edit') || this.isJobCreate;
    this.isJobEdit = this.router.url.includes('job-edit');

    if (
      this.router.url.includes('requisition-form-create') ||
      this.router.url.includes('requisition-form-edit') ||
      this.isJobEditOrCreate
    ) {
      this.mode = RequisitionMode.preview;
      this.isModal = true;
      this.data = this.modal.data;
      this.form.get('comment').disable();
      this.setDinamicForm();

      if (this.isJobEditOrCreate) {
        this.publishingPlatformsService
          .getPlatforms(this.setupService.companyId)
          .pipe(takeUntil(this._ngUnsubscribe$))
          .subscribe((platforms) => {
            this.platformsList = platforms;
            this.platformsList.push({
              id: 0,
              name: 'Higher'
            });

            this.platformsList = this.platformsList.map((platform) => {
              return { ...platform, disabled: true };
            });

            this.platforms.setValue(this.data.platforms);
            this.platforms.enable();
          });
      }
    }

    if (this.router.url.includes('new-requisition')) {
      this.mode = RequisitionMode.blank;
      const requisitionFormId = +this.route.snapshot.params.id;
      this.requesitionService
        .getRequisitionForm(requisitionFormId)
        .pipe(takeUntil(this._ngUnsubscribe$))
        .subscribe((requisitionForm: RequisitionFormClass) => {
          if (!requisitionForm.usersThatApprove.length) {
            this.toastr.info(
              this.translateService.instant('REQUISITION_FORM.NO_ONE_TO_APPROVE_MESSAGE')
            );
          }
          this.data = requisitionForm;
          this.setDinamicForm();
          this.cdr.markForCheck();
        });
    }

    if (this.router.url.includes('review-requisition')) {
      this.mode = RequisitionMode.review;
      this.getRequisitionData();
    }

    if (this.router.url.includes('correct-requisition')) {
      this.mode = RequisitionMode.correct;
      this.getRequisitionData();
    }

    if (this.notificationId) {
      this.removeNotification();
    }
  }

  removeNotification(): void {
    this.loaderService.show();
    this.notificationService.deleteRequisitionNotification(this.notificationId)
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe(() => {
        this.loaderService.hide();
      });
  }

  setDinamicForm(): void {
    this.data.dinamicForm.forEach((control) => {

      let values = [];

      if (control.values) {
        values = control.values.map((value) => {
          if (this.isModal || this.mode === this.REQUISITION_MODE.review ||
              (this.mode === this.REQUISITION_MODE.correct && this.data.status !== this.REQUISITION_STATUS.denied)) {
            return {value: value, disabled: true};
          } else {
            return {value: value};
          }
        });
      }

      const controlValue = this.fb.group({
        elementType: [control.elementType],
        elementLabel: [control.elementLabel],
        insertedValue: [
          {
            value: control.insertedValue,
            disabled: this.mode === RequisitionMode.preview || this.mode === RequisitionMode.review
                        || (this.mode === RequisitionMode.correct && this.data.status !== RequisitionStatus.denied)
          }
        ],
        values: [values],
        selectedValues: [control.selectedValues || null]
      });

      if (
        control.elementType === ControlType.dropdown &&
        this.mode !== RequisitionMode.preview &&
        this.mode !== RequisitionMode.review
      ) {
        controlValue.get('selectedValues').addValidators([Validators.required]);
      }

      if (control.elementType === ControlType.text) {
        controlValue.get('insertedValue').addValidators([Validators.required]);
      }

      this.dinamicForm.push(controlValue);
    });
    this.loaderService.hide();
  }

  getRequisitionData(): void {
    const requisitionId = +this.route.snapshot.params.id;

    forkJoin([
      this.requesitionService.getRequisition(requisitionId),
      this.publishingPlatformsService.getPlatforms(this.setupService.companyId)
    ]).subscribe(([requisition, platforms]) => {
      this.data = requisition;
      this.platformsList = platforms;
      this.platformsList.push({
        id: 0,
        name: 'Higher'
      });

      if (
        (this.mode === RequisitionMode.review && this.data.status !== RequisitionStatus.pending) ||
        this.mode === RequisitionMode.correct
      ) {
        this.platformsList = this.platformsList.map((platform) => {
          return { ...platform, disabled: true };
        });
      }

      this.platforms.setValue(this.data.platforms);

      this.platforms.enable();
      this.setDinamicForm();
      this.cdr.markForCheck();
    });
  }

  cancel(): void {
    if (this.notificationId) {
      this.router.navigate(['/dashboard/company'], {queryParamsHandling: 'merge' });
      return;
    }

    if (this.mode === RequisitionMode.review) {
      this.router.navigate(['/requisitions/others-requisitions'], {
        state: { status: this.data.status },
        queryParamsHandling: 'merge' });
    } else if (this.mode === RequisitionMode.correct) {
      this.router.navigate(['/requisitions/your-requisitions'], {
        state: { status: this.data.status },
        queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/requisitions/create-new-requisition'], { queryParamsHandling: 'merge' });
    }
  }

  submitForm(): void {

    if (this.router.url.includes('new-requisition') && !this.data.usersThatApprove.length) {
      this.toastr.info(
        this.translateService.instant('REQUISITION_FORM.NO_ONE_TO_APPROVE_MESSAGE')
      );
      return;
    }

    const { value, valid } = this.form;
    this.form.markAllAsTouched();

    if (!valid) {
      return;
    }

    this.loaderService.show();

    value.dinamicForm.forEach((item) => {
      Object.keys(item).forEach((key) => {
        (!item[key] || !item[key].length) && delete item[key];
        if (key === 'values' && item[key]) {
          item.values = item.values.map(i => i.value);
        }
      });
    });

    const id = +this.route.snapshot.params.id;

    const submit$ =
      this.mode === RequisitionMode.blank
        ? this.requisitionService.createRequisition(value, id)
        : this.requisitionService.editRequisition(value, id);

    submit$
      .pipe(
        finalize(() => {
          this.router.navigate(['/requisitions/your-requisitions'], {
            state: { status: this.data.status },
            queryParamsHandling: 'merge' });
          this.loaderService.hide();
        })
      )
      .subscribe();
  }

  reviewRequisition(requisitionStatus: string): void {

    const { value, valid } = this.form;

    if (!valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loaderService.show();

    const id = +this.route.snapshot.params.id;

    this.requisitionService.reviewRequisition(value, id, requisitionStatus).subscribe(() => {
      this.loaderService.hide();
      this.router.navigate(['/requisitions/others-requisitions/'], {
        state: { status: this.data.status },
        queryParamsHandling: 'merge' });
    });
  }

  addRequisition(): void {
    this.jobStore.updateJob({requisition: this.data});
    this.toastr.success(
      this.translateService.instant('REQUISITION_LIST.ATTACH_REQUISITION_SUCCESS_MSG')
    );
    this.data.confirm();
    this.closeModal();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
