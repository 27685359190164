import { ModalService } from '../../services/modal.service';

export class AbstractModal {

  modal: any = null;

  constructor(
    protected modalService: ModalService,
    ...args: any
  ) { }

  closeModal(): void {
    this.modalService.removeModal(this.modal);
  }
}
