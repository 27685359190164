import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CaptureVideoPosterModalData } from 'src/app/model/modal.interface';
import { AbstractModal } from 'src/app/modules/modal/abstract-modal';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-capture-video-poster',
  templateUrl: './capture-video-poster.component.html',
  styleUrls: ['./capture-video-poster.component.scss'],
})
export class CaptureVideoPosterComponent extends AbstractModal implements OnInit, AfterViewInit {

  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

  poster: string;
  posterCanvasElement: HTMLCanvasElement;
  posterControl = new FormControl(null, [Validators.required]);

  get data(): CaptureVideoPosterModalData {
    return this.modal.data;
  }

  get title(): string {
    return this.data.title;
  }
  get videoMp4Url(): string {
    return this.data.videoMp4Url;
  }
  get videoWebmUrl(): string {
    return this.data.videoWebmUrl;
  }
  get videoPosterUrl(): string {
    return this.data.videoPosterUrl;
  }

  get videoPosterCachedUrl(): string {
    return this.data.videoPosterCachedUrl;
  }

  get videoElement(): HTMLVideoElement {
    return this.videoPlayer.nativeElement;
  }

  constructor(
    modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private toastr: ToastrService
  ) {
    super(modalService);
  }


  ngOnInit(): void {
    this.posterCanvasElement = document.createElement('canvas');
    this.poster = this.videoPosterCachedUrl || this.videoPosterUrl;
  }

  ngAfterViewInit(): void {
    this.videoElement.crossOrigin = '*';
  }

  captureVideoPoster(): void {
    this.posterCanvasElement.width = this.videoElement.videoWidth;
    this.posterCanvasElement.height = this.videoElement.videoHeight;
    this.posterCanvasElement
      .getContext('2d')
      .drawImage(this.videoElement, 0, 0, this.videoElement.videoWidth, this.videoElement.videoHeight);
    this.posterCanvasElement.toBlob(
      (blob) => {
        const file = new File([blob], this.videoPosterUrl, { type: 'image/jpeg' });
        this.posterControl.patchValue(file);
        this.convertImage(file);
      },
      'image/jpeg'
    );
  }

  convertImage(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result.toString();
      this.poster = result;
      this.cdr.detectChanges();
    };
  }

  changeVideoPoster(): void {
    if (this.posterControl.valid) {
      const videoUrl = this.videoMp4Url || this.videoWebmUrl;
      const videoSplitted = videoUrl.split('/');
      const video = videoSplitted[videoSplitted.length - 1];
      this.data.confirm(video, this.posterControl.value);
      this.closeModal();
    } else{
      this.toastr.error(
        this.translateService.instant('VIDEO_RECORDER.CAPTURE_POSTER_ERR_MSG')
      );
    }
  }
}
