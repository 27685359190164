import { User, UserCommunicator } from './user.interface';
import { PublishingPlatform } from './publishing-platform.interface';
export interface IRequisition {
  id?: number;
  requisitionStatus?: string;
  creatorCustomer?: UserCommunicator;
  creatorEnterpriseManager?: UserCommunicator;
  requisitionTitle?: string,
  filledInRequisitionFormElements?: RequisitionDinamicControl[];
  customersWhoCanApproveRequisition?: [];
  enterpriseManagersWhoCanApproveRequisition?: UserCommunicator;
  requisitionComments?: IRequisitionComments[],
  approvedByCustomer?: UserCommunicator;
  approvedByEnterpriseManager?: UserCommunicator;
  completelyDeniedByCustomer?: UserCommunicator;
  completelyDeniedByEnterpriseManager?: UserCommunicator;
  lastDeniedByCustomer?: UserCommunicator;
  lastDeniedByEnterpriseManager?: UserCommunicator;
  allowedJobPlatforms?: PublishingPlatform[] | number[];
  publishOnHigher?: boolean;
  createdAt?: Date;
  requisitionForm?: number;
  requisitionCommentContent?: string;
  context?: string;
  correctedFilledInRequisitionFormElements?: RequisitionDinamicControl[];
  utcOfApproval?: string;
}

export enum RequisitionMode {
  preview = 'preview',
  blank = 'blank',
  review = 'review',
  correct = 'correct'
}
export interface IRequisitionForm {
  createdAt?: Date;
  creatorCustomer?: UserCommunicator;
  creatorEnterpriseManager?: UserCommunicator;
	customersWhoCanApproveCreatedRequisitionsFromForm: Partial<User>[] | number[];
	enterpriseManagersWhoCanApproveCreatedRequisitionFromForm: Partial<User>[] | number[];
  id?: number;
  lastEditedByEnterpriseManager?: UserCommunicator;
  lastEditedByCustomer?: UserCommunicator;
  requisitionFormTitle: string;
	requisitionFormElements: RequisitionDinamicControl[];
  company?: number;
}

export interface RequisitionDinamicControl {
  elementType: string;
  elementLabel: string;
  values?: string[];
  insertedValue: string;
  selectedValues: string[];
}
export interface IRequisitionComments {
  id: number;
  content: string;
  creatorCustomer: UserCommunicator;
  creatorEnterpriseManager: UserCommunicator;
  createdAt: string;
  anonymousCustomer?: boolean;
  anonymousEnterpriseManager?: boolean;
}

export interface RequisitionComments {
  content: string;
  creatorName: string;
  createdAt: string;
  anonymousCreator: boolean;
}
export interface IRequisitionNotification {
  id: number;
  requisition: Partial<IRequisition>;
  requisitionNotificationType: string;
  createdAt: Date;
}

export enum RequisitionStatus {
  pending = 'pending',
  approved = 'approved',
  denied = 'denied',
  completelyDenied = 'completelyDenied'
}

export class RequisitionTab {
  active: boolean;
  tabTitle: string;
  tabStatus: RequisitionStatus;
}

export enum ControlType {
  dropdown = 'dropdown',
  text = 'textField'
}
