import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, forkJoin } from 'rxjs';
import { ListJob, Question } from 'src/app/model/job.interface';
import { GeneralTemplate } from 'src/app/model/templates.interface';
import { TranslationKeys } from 'src/app/model/translation-object.interface';
import { UniversalOption } from 'src/app/model/universal-option.interface';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { JobStoreService } from 'src/app/services/job-store.service';
import { JobsService } from 'src/app/services/jobs.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuizService } from 'src/app/services/quiz.service';
import { SetupService } from 'src/app/services/setup.service';
import { TemplatesService } from 'src/app/services/templates.service';
import { take } from 'rxjs/operators';
import { AbstractSkillsLibrary } from 'src/app/model/abstract-skills-library.model';
import { Pagination } from 'src/app/model/pagination.interface';
import { HiddenSkillsQuestionListModalData } from 'src/app/model/modal.interface';
import { HiddenSkillsQuestionListModal } from 'src/app/classes/modal.class';
import { QuestionListComponent } from '../question-list/question-list.component';

type ControlType = 'job' | 'jobTemplate' | 'category' | 'language';

@Component({
  selector: 'app-skills-library',
  templateUrl: './skills-library.component.html',
  styleUrls: ['./skills-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkillsLibraryComponent extends AbstractSkillsLibrary implements OnInit {
  possibleLanguages: TranslationKeys[] = [];
  categories: UniversalOption[];
  jobs: ListJob[];
  jobTemplates: GeneralTemplate[];
  hiddenQuestionsList: Question[] = [];
  @ViewChild('questionList') questionList: QuestionListComponent;

  filterForm: FormGroup = this.fb.group({
    category: [null],
    language: [null],
    job: [null],
    jobTemplate: [null]
  });

  params = {
    page: 1,
    ...this.filterForm.value,
    limit: 100000
  };

  constructor(
    modalService: ModalService,
    cdr: ChangeDetectorRef,
    toastr: ToastrService,
    translateService: TranslateService,
    quizService: QuizService,
    errorHandlingService: ErrorHandlingService,
    private fb: FormBuilder,
    private jobService: JobsService,
    private loaderService: LoaderService,
    private setupService: SetupService,
    private templateService: TemplatesService,
    private jobStore: JobStoreService
  ) {
    super(modalService, cdr, toastr, translateService, quizService, errorHandlingService);
  }

  get category(): FormControl {
    return this.filterForm.get('category') as FormControl;
  }

  get job(): FormControl {
    return this.filterForm.get('job') as FormControl;
  }

  get jobTemplate(): FormControl {
    return this.filterForm.get('jobTemplate') as FormControl;
  }

  get language(): FormControl {
    return this.filterForm.get('language') as FormControl;
  }

  ngOnInit(): void {
    this.translationLanguage = this.translateService.currentLang;
    this.possibleLanguages = this.setupService.companyPossibleLanguages;

    if (this.data.isExpressInterest) {
      this.language.patchValue(this.data.expressInterestLanguage);
    } else {
      const { jobInfo } = this.jobStore.getJobFromSessionStorage();

      this.category.patchValue(jobInfo.jobCategory);
      this.language.patchValue(jobInfo.language);
    }

    this.getCategoriesWithSkillsQuestions();
  }

  getCategoriesWithSkillsQuestions(): void {
    this.loaderService.show();

    this.jobService.getCategoriesWithSkillsQuestions().subscribe((categories: UniversalOption[]) => {
      this.categories = categories;

      if (this.category.value && this.categories.findIndex((cat) => cat.id === this.category.value) === -1) {
        this.category.patchValue(null);
      }

      this.getData(true);
      this.loaderService.hide();
    });
  }

  getData(isOnInit?: boolean): void {
    this.params = {
      page: 1,
      ...this.filterForm.value,
      limit: 100000
    };
    this.pagination.page = 1;
    this.loaderService.show();
    this.questionsList = [];

    forkJoin([
      this.jobService.getAllCompanyJobs(this.params),
      this.templateService.getJobTemplatesForBussinessQuestions(this.params),
      this.quizService.getSkillsQuestions(this.filterForm.value, this.pagination.page, this.pagination.perPage)
    ])
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) => this.errorHandlingService.handleBackendError(error))
      )
      .subscribe(([jobs, jobTemplates, questions]) => {
        this.jobTemplates = jobTemplates;
        this.jobs = jobs;
        this.questionsList = questions.data.map((item) => this.mapReceivedQuestions(item));

        if (isOnInit && this.questionsList.length === 0) {
          this.category.patchValue(null);
          this.getData();
        }

        this.pagination.total = questions.total;
        this.cdr.detectChanges();
        this.loaderService.hide();
      });
  }

  loadMoreQuestions(toSetScroll?: boolean): void {
    this.quizService
      .getSkillsQuestions(this.filterForm.value, this.pagination.page, this.pagination.perPage)
      .pipe(catchError((error: HttpErrorResponse) => this.errorHandlingService.handleBackendError(error)))
      .subscribe((questions: Pagination<{ question: Question }>) => {
        this.questionsList = this.questionsList.concat(questions.data.map((item) => this.mapReceivedQuestions(item)));
        this.pagination.total = questions.total;
        if (toSetScroll) {
          this.questionList.scrollToTop();
        }
        this.cdr.detectChanges();
      });
  }

  setFormValues(controlName: ControlType): void {
    const controlValue = this.filterForm.get(controlName).value;

    if (controlName === 'category' || controlName === 'language') {
      if (controlValue) {
        this.job.patchValue(null);
        this.jobTemplate.patchValue(null);
        this.job.enable();
        this.jobTemplate.enable();
      }
      return;
    }

    if (controlName === 'jobTemplate') {
      if (controlValue) {
        const selectedTemplate = this.jobTemplates.filter((template) => template.id === controlValue);
        const selectedTemplateCategory = selectedTemplate[0].jobCategory;
        this.category.patchValue(selectedTemplateCategory.id);
        this.job.disable();
      } else {
        this.job.enable();
      }

      return;
    }

    if (controlName === 'job') {
      if (controlValue) {
        const selectedJob = this.jobs.filter((job) => job.guid === controlValue);
        const selectedJobCategoryId = selectedJob[0].category;
        this.category.patchValue(selectedJobCategoryId);
        this.jobTemplate.disable();
      } else {
        this.jobTemplate.enable();
      }
    }
  }

  onHideQuestion(): void {
    this.loaderService.show();
    this.questionsList = [];
    this.pagination.page = 1;

    this.quizService
      .getSkillsQuestions(this.filterForm.value, this.pagination.page, this.pagination.perPage)
      .pipe(catchError((error: HttpErrorResponse) => this.errorHandlingService.handleBackendError(error)))
      .subscribe((questions: Pagination<{ question: Question }>) => {
        this.questionsList = this.questionsList.concat(questions.data.map((item) => this.mapReceivedQuestions(item)));
        this.pagination.total = questions.total;
        this.loaderService.hide();
        this.cdr.detectChanges();
      });
  }

  openHiddenQuestionsModal(): void {
    this.hiddenQuestionsList = [];
    this.quizService
      .getSkillsQuestions(this.filterForm.value, 1, this.pagination.perPage, true)
      .pipe(catchError((error: HttpErrorResponse) => this.errorHandlingService.handleBackendError(error)))
      .subscribe((questions: Pagination<{ question: Question }>) => {
        this.hiddenQuestionsList = questions.data.map((item) => this.mapReceivedQuestions(item));
        this.pagination.total = questions.total;
        const data: HiddenSkillsQuestionListModalData = {
          hiddenQuestionList: this.hiddenQuestionsList,
          total: questions.total,
          filter: this.filterForm.value,
          close: () => this.onClose()
        };
        this.modalService.addModal(new HiddenSkillsQuestionListModal(data));
      });
  }

  onClose(): void {
    this.questionsList = [];
    this.params = {
      page: 1,
      ...this.filterForm.value,
      limit: 100000
    };
    this.pagination.page = 1;

    this.loadMoreQuestions(true);
  }
}
