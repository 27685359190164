<div class="cancel-interview-wrapper" [ngClass]="{'custom-modal-wrapper': !!modal}">
  <div class="close-icon-wrapper" *ngIf="modal">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <div class="custom-modal" *ngIf="interviewToReject || (modal && data.interviewToCancel)">
    <h3 class="modal-title">
      {{ modal ?
        ('INTERVIEW.CANCEL_INTERVIEW_TITLE' | translate) :
        ('INTERVIEW.REJECT_INTERVIEW_TITLE') | translate:{ interviewName: interviewToReject.subject } }}
    </h3>
    <div class="modal-body">
      <ng-container *ngIf="!isGroup; else groupInterview">
        <div class="modal-body-content">
          {{ modal ?
            ('INTERVIEW.CANCEL_INTERVIEW_QUESTION' | translate:{ interviewName: data.interviewToCancel.subject }) :
            ('INTERVIEW.REJECT_INTERVIEW_QUESTION' | translate) }}
        </div>
        <div class="modal-body-content">
          {{ (modal ? 'INTERVIEW.CANCEL_INTERVIEW_INFO' : 'INTERVIEW.REJECTION_INTERVIEW_INFO') | translate }}
        </div>

        <form [formGroup]="form" class="rejection-form">
          <div class="form-group">
            <label>{{ (modal ? 'INTERVIEW.CANCELATION_REASON_LABEL' : 'INTERVIEW.REJECTION_REASON_LABEL') | translate }}</label>
            <textarea [formControl]="infoForCancellation"
                      rows="9"
                      class="form-control"
                      [ngClass]="{
                          'is-invalid': infoForCancellation.errors?.required && infoForCancellation.touched
                        }">
            </textarea>
            <div *ngIf="infoForCancellation.errors?.required && infoForCancellation.touched" class="invalid-feedback">
              {{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <div class="button-wrapper" [ngClass]="{'in-modale': modal}">
      <button (click)="closeModal()"
              *ngIf="modal"
              class="btn btn-outline-primary">
        <i class="icon-arrow-left"></i>
      </button>
      <button (click)="goToAccept()"
              *ngIf="!modal"
              class="btn btn-outline-primary">
        {{ 'INTERVIEW.GO_TO_ACCEPT' | translate }}
      </button>
      <button class="btn btn-primary border-white"
              (click)="confirmCancelation()">
        {{ (modal ? 'INTERVIEW.CANCEL_INTERVIEW_TITLE' : 'INTERVIEW.REJECT_INTERVIEW_BUTTON') | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #groupInterview>
  <div class="modal-body-content">
    {{ groupInterviewInfo }}
  </div>
  <div class="modal-body-content">
    {{ 'INTERVIEW.REJECT_INTERVIEW_QUESTION' | translate }}
  </div>
</ng-template>
