<form [formGroup]="form" class="phone-number-form">

    <div class="form-group">
      <select formControlName="networks"
              class="form-control custom-select"
              (change)="setNetwork()">
        <option class="form-control"
                *ngFor="let network of countryCallingCodes"
                [ngValue]="network">
          {{network.callingCode}}
        </option>
      </select>
    </div>

    <div class="form-group input-form-group">
      <input type="text"
             class="form-control"
             formControlName="phone"
             [ngClass]="{
               'is-invalid': phone.invalid && phone.touched
             }">
      <div class="invalid-feedback">
        <div *ngIf="phone.errors?.required">
          {{ 'SHARED.PHONE_ERROR_REQUIRED_MSG' | translate }}
        </div>
        <div *ngIf="phone.errors?.pattern">
          {{ 'SHARED.PHONE_ERROR_PATTERN_MSG' | translate }}
        </div>
        <div *ngIf="!phone.errors?.pattern && (phone.errors?.minlength || phone.errors?.maxlength)">
          <ng-container *ngIf="(phoneNumberMinLength !== 0) && (phoneNumberMinLength < phoneNumberMaxLength)">
            {{ 'SHARED.PHONE_ERROR_LENGTH_MSG' | translate: {minLength: phoneNumberMinLength, maxLength: phoneNumberMaxLength}  }}
          </ng-container>
          <ng-container *ngIf="phoneNumberMinLength === 0">
            {{ 'SHARED.PHONE_ERROR_LENGTH_MSG_MAX_LENGTH' | translate: {maxLength: phoneNumberMaxLength}  }}
          </ng-container>
          <ng-container *ngIf="phoneNumberMinLength === phoneNumberMaxLength">
            {{ 'SHARED.PHONE_ERROR_LENGTH_MSG_FIXED_LENGTH' | translate: {length: phoneNumberMaxLength}  }}
          </ng-container>
        </div>
      </div>
    </div>
</form>
