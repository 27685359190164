import { AgreementDinamicControl, AgreementPayload, ControlType, IAgreement } from '../model/agreement.model';

export class AgreementClass {
  id?: number;
  guid?: string;
  status?: string;
  terms: string;
  dinamicForm: AgreementDinamicControl[];
  title: string;

  constructor(agreement: IAgreement) {
    this.id = agreement.id;
    this.guid = agreement.guid;
    this.title = agreement.title;
    this.dinamicForm = agreement.agreementFormElements;
    this.terms = agreement.terms;
    this.status = agreement.status;

    if (agreement.filledInAgreementFormElements) {
      this.dinamicForm.forEach((control, index) => {
        if (control.elementType === ControlType.dropdown) {
          control.selectedValues = agreement.filledInAgreementFormElements[index].selectedValues as number[];
        } else if (control.elementType === ControlType.text) {
          control.insertedValue = agreement.filledInAgreementFormElements[index].insertedValue as string;
        } else if (control.elementType === ControlType.signature) {
          control.insertedSignature = agreement.filledInAgreementFormElements[index].insertedSignature as string;
        }
      });
    }
  }

  static prepareForEdit(agreementClass: AgreementClass): AgreementPayload {
    const payload = agreementClass.dinamicForm.map((control) => {
      if (control.elementType === ControlType.dropdown) {
        return {selectedValues: control.selectedValues};
      } else if (control.elementType === ControlType.text) {
        return {insertedValue: control.insertedValue};
      } else if (control.elementType === ControlType.signature) {
        return {insertedSignature: control.insertedSignature};
      } else {
        return {};
      }
    });

    const agreement: AgreementPayload = {
      filledInAgreementFormElements: payload,
    };

    return agreement;
  }
}
