import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

@Pipe({
  name: 'domSanitizer'
})
export class DomSanitizerPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(value: string, type: 'html' | 'url'): SafeValue {
    if (!value) {
      return '';
    }

    if (type === 'html') {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    if (type === 'url') {
      return this.sanitizer.bypassSecurityTrustUrl(value);
    }
  }

}
