import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { Pagination } from '../model/pagination.interface';
import { SetupService } from './setup.service';
import { HiringStatus } from '../classes/hiring-status.class';
import { HiringStatusPayload, IHiringStatus } from '../model/hiring-status.interface';

@Injectable({
  providedIn: 'root',
})
export class StatusFlowService {

  private _refreshStatuses$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  constructor(
    private http: HttpClient,
    private setupService: SetupService
  ) {}

  get refreshStatuses$(): Observable<void> {
    return this._refreshStatuses$.asObservable();
  }

  refreshStatuses(): void {
    this._refreshStatuses$.next();
  }

  getHiringStatuses(): Observable<HiringStatus[]> {
    return this.http.get(`${environment.companies}/${this.setupService.companyGuid}/application_hiring_statuses`)
      .pipe(
        map(({data}: Pagination<IHiringStatus>) => {
          return data.map((status: IHiringStatus) => new HiringStatus(status));
        }),
        map((hiringStatuses: HiringStatus[]) =>
          hiringStatuses.sort((a: HiringStatus, b: HiringStatus) => (a.order > b.order) ? 1 : (b.order > a.order) ? -1 : 0)
        ),
      );
  }

  createHiringStatus(status: HiringStatusPayload, hiringStatuses: HiringStatus[]): Observable<HiringStatus[]> {
    const payload = {
      ...status,
      company: this.setupService.companyId
    };

    return this.http.post(environment.hiringStatuses, payload)
      .pipe(
        switchMap((response: any) => {
          hiringStatuses.splice(hiringStatuses.length - 1, 0, (response.data[0] as HiringStatus));
          return this.reorderStatus(hiringStatuses);
        })
      );
  }

  editHiringStatus(id: number, payload: {color: string, name?: string}): Observable<HiringStatus[]> {
    return this.http.put(`${environment.hiringStatuses}/${id}`, payload)
      .pipe(
        switchMap(() => this.getHiringStatuses())
      );
  }

  removeStatus(statusId: number): Observable<HiringStatus[]> {
    return this.http
      .delete(`${environment.hiringStatuses}/${statusId}` )
      .pipe(
        switchMap(() => this.getHiringStatuses())
      );
  }

  reorderStatus(hiringStatuses: HiringStatus[]): Observable<HiringStatus[]> {
    const applicationHiringStatuses = hiringStatuses.map(({id}: HiringStatus) => id);

    const order = {
      applicationHiringStatuses
    };

    return this.http.post(`${environment.hiringStatuses}/${this.setupService.companyGuid}/order`, order)
      .pipe(
        switchMap(() => this.getHiringStatuses())
      );
  }
}
