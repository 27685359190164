import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Observable,
  timer,
  forkJoin,
  Subject,
  BehaviorSubject,
  of
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { INotificationEmailResponse } from '../model/email-response.interface';
import { ICommentResponse } from '../model/comment.interface';
import {
  TagNotification,
  Notification,
  EmailNotification,
  RefAppNotification,
  UploadedCVNotification,
  CandidateProvidedInfoNotification,
  SriBackgroundCheckNotification,
  AdwayNotification,
  RequisitionNotification,
  AlvaNotification
} from '../classes/notification.class';
import { SetupService } from './setup.service';
import { IRefAppResponse } from '../model/ref-app.interface';
import { IUploadedCVResponse } from '../model/uploaded-cv-response.interface';
import { CandidateApplication } from '../classes/application.class';
import { RecruitmentService } from './recruitment.service';
import { ICandidateProvidedInfo } from '../model/candidate-provided-info.interface';
import { ISriBackgroundCheckResponse } from '../model/sri.interface';
import { Pagination } from '../model/pagination.interface';
import { ListJob } from '../model/job.interface';
import { IRequisitionNotification } from '../model/requisition.model';
import { IAlvaResponse } from '../model/alva-labs.interface';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  private _notifications$: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
  private _refreshEmailNotifications$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private _refreshTagNotifications$: Subject<void> = new Subject<void>();
  private _refreshRefAppNotifications$: Subject<void> = new Subject<void>();
  private _refreshUploadedCVNotifications$: Subject<void> = new Subject<void>();
  private _refreshCandidateProvidedInfoNotifications$: Subject<void> = new Subject<void>();
  private _refreshSriBgCheckNotifications$: Subject<void> = new Subject<void>();
  private _refreshAdwayNotifications$: Subject<void> = new Subject<void>();
  private _refreshAlvaNotifications$: Subject<void> = new Subject<void>();

  private _resetTimer$: Subject<void> = new Subject<void>();
  private _timedObservable: Observable<EmailNotification[]>;

  constructor(
    private http: HttpClient,
    private setupService: SetupService,
    private recruitmentService: RecruitmentService
  ) {}

  get notifications$(): Observable<Notification[]> {
    return this._notifications$.asObservable();
  }

  get refreshTagNotifications$(): Observable<void> {
    return this._refreshTagNotifications$.asObservable();
  }

  get refreshEmailNotifications$(): Observable<void> {
    return this._refreshEmailNotifications$.asObservable();
  }

  get refreshRefAppNotifications$(): Observable<void> {
    return this._refreshRefAppNotifications$.asObservable();
  }

  get refreshUploadedCVNotifications$(): Observable<void> {
    return this._refreshUploadedCVNotifications$.asObservable();
  }

  get refreshCandidateProvidedInfoNotifications$(): Observable<void> {
    return this._refreshCandidateProvidedInfoNotifications$.asObservable();
  }

  get refreshSriBgCheckNotifications$(): Observable<void> {
    return this._refreshSriBgCheckNotifications$.asObservable();
  }

  get refreshAdwayNotifications$(): Observable<void> {
    return this._refreshAdwayNotifications$.asObservable();
  }

  get refreshAlvaNotifications$(): Observable<void> {
    return this._refreshAlvaNotifications$.asObservable();
  }

  setNotifications(responses: Notification[]): void {
    this._notifications$.next(responses);
  }

  refreshCommentNotifications(): void {
    this._refreshTagNotifications$.next();
  }

  refreshEmailNotifications(): void {
    this._refreshEmailNotifications$.next();
  }

  refreshRefAppNotifications(): void {
    this._refreshRefAppNotifications$.next();
  }

  refreshUploadedCVNotifications(): void {
    this._refreshUploadedCVNotifications$.next();
  }

  refreshCandidateProvidedInfoNotifications(): void {
    this._refreshCandidateProvidedInfoNotifications$.next();
  }

  refreshSriBgCheckNotifications(): void {
    this._refreshSriBgCheckNotifications$.next();
  }

  refreshAdwayNotifications(): void {
    this._refreshAdwayNotifications$.next();
  }

  refreshAlvaNotifications(): void {
    this._refreshAlvaNotifications$.next();
  }

  getEmailNotificationsForDashboard(): Observable<Notification[]> {
    let params = new HttpParams();
    params = params.append('unreadResponses', 'true');
    params = params.append('candidatePersonalDataRemoved', 'false');

    return this.getEmailNotifications(params);
  }

  getEmailNotificationsForRecBoard(jobIds: number[], universalJobId: number): Observable<EmailNotification[]> {
    let params = new HttpParams();

    if (jobIds && jobIds.length > 0) {
      params = params.append('jobIds', jobIds.join());
    }

    if (universalJobId) {
      params = params.append('universalJob', universalJobId.toString());
    }

    params = params.append('unreadResponses', 'true');
    params = params.append('unreadResponsesFromOthers', 'true');
    params = params.append('candidatePersonalDataRemoved', 'false');

    const delay = this._timedObservable ? 0 : 300000;

    if (this._timedObservable) {
      this._resetTimer$.next();
    }

    this._timedObservable = timer(delay, 300000)
      .pipe(
        takeUntil(this._resetTimer$),
        switchMap(() => this.getEmailNotifications(params))
      );

    return this._timedObservable;
  }

  getEmailNotifications(params: HttpParams): Observable<EmailNotification[]> {
    return this.http
      .get<INotificationEmailResponse[]>(
        `${environment.companies}/${this.setupService.companyGuid}/application_email_responses`,
        { params }
      )
      .pipe(
        map((emailResponses: INotificationEmailResponse[]) =>
          emailResponses.map((emailResponse: INotificationEmailResponse) => new EmailNotification(emailResponse))
        )
      );
  }

  markEmailResponsesAsRead(responseId: number, applicationId: number): Observable<CandidateApplication> {
    return this.http
      .put(
        environment.markEmailResponsesAsRead,
        { applicationEmailResponseIds: [responseId], application: applicationId }
      )
      .pipe(
        switchMap(() => this.recruitmentService.getApplication(applicationId)),
        tap((application: CandidateApplication) => {
          this.recruitmentService.setRefreshedApplication(application);
          this.refreshEmailNotifications();
        })
      );
  }

  getTagNotifications(): Observable<Notification[]> {
    return this.http
      .get<ICommentResponse[]>(
        `${environment.comments}/get_unseen_tagged_comments/${this.setupService.companyGuid}`
      )
      .pipe(
        map((commentResponses: ICommentResponse[]) => {
          return commentResponses.map((commentResponse: ICommentResponse) => {
            return new TagNotification(commentResponse, this.setupService.currentCompany);
          });
        })
      );
  }

  getRefAppNotifications(): Observable<Notification[]> {
    let params = new HttpParams();

    params = params.append('company', `${this.setupService.companyId}`);
    params = params.append('updateSeen', 'false');

    return this.http
      .get(
        environment.refAppResponses,
        { params }
      )
      .pipe(
        map((refAppResponses: IRefAppResponse[]) =>
          refAppResponses.map((response: IRefAppResponse) => new RefAppNotification(response))
        )
      );
  }

  getUploadedCVNotifications(): Observable<Notification[]> {
    return this.http
      .get(
        `${environment.companies}/${this.setupService.companyGuid}/application_ask_for_cv_requests/with_unseen_cvs`
      )
      .pipe(
        map((uploadedCVResponses: IUploadedCVResponse[]) =>
          uploadedCVResponses.map((response: IUploadedCVResponse) => new UploadedCVNotification(response))
        )
      );
  }

  markSeenUploadedCVNotification(applicationGuid: string): Observable<any> {
    return this.http
      .put(
        `${environment.applications}/${applicationGuid}/application_ask_for_cv_requests/seen_by_request_sender`,
        null
      );
  }

  getCandidateProvidedInfoNotifications(): Observable<Notification[]> {
    return this.http
      .get(
        // eslint-disable-next-line max-len
        `${environment.companies}/${this.setupService.companyGuid}/application_ask_for_ssn_and_full_name_requests/with_unseen_ssn_and_full_names`
      )
      .pipe(
        map((candidateProvidedInfos: ICandidateProvidedInfo[]) =>
          candidateProvidedInfos.map((info: ICandidateProvidedInfo) => new CandidateProvidedInfoNotification(info))
        )
      );
  }

  markSeenCandidateProvidedInfoNotification(applicationGuid: string): Observable<string> {
    return this.http
      .put<string>(
        `${environment.applications}/${applicationGuid}/application_ask_for_ssn_and_full_name_requests/seen_by_request_sender`,
        null
      );
  }

  getSriBackgroundCheckNotifications(): Observable<Notification[]> {
    let params = new HttpParams();

    params = params.append('company', `${this.setupService.companyId}`);
    params = params.append('updateSeen', 'false');

    return this.http
      .get(
        environment.sri.notifications,
        { params }
      )
      .pipe(
        map((sriBackgroundCheckResponses: ISriBackgroundCheckResponse[]) =>
          sriBackgroundCheckResponses.map((response: ISriBackgroundCheckResponse) => new SriBackgroundCheckNotification(response))
        )
      );
  }

  getAdwayNotifications(): Observable<Notification[]> {
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('limit', '10000');
    params = params.append('adwayCampaignInfoVisible', 'true');

    return this.http
      .get<Pagination<ListJob>>(
        `${environment.companies}/${this.setupService.companyGuid}/jobs`,
        { params }
      )
      .pipe(
        map(({data}: Pagination<ListJob>) => data),
        map((jobs: ListJob[]) => {
          jobs = jobs.filter(job => job.adwayCampaignInfoVisible);
          return jobs.map((job: ListJob) => new AdwayNotification(job));
        }
        )
      );
  }

  getRequisitionForReviewingNotifications(): Observable<Notification[]> {
    let params = new HttpParams();

    params = params.append('requisitionNotificationType', 'requisitionForReviewing');

    return this.http
    .get<IRequisitionNotification[]>(environment.requisitionNotifications, { params }).pipe(
      map((requisitions: IRequisitionNotification[]) => {
        return requisitions.map((requisition: IRequisitionNotification) => new RequisitionNotification(requisition));
      })
    );
  }

  getReviewedRequisitionNotifications(): Observable<Notification[]> {
    let params = new HttpParams();

    params = params.append('requisitionNotificationType', 'reviewedRequisition');

    return this.http.get<IRequisitionNotification[]>(environment.requisitionNotifications, { params }).pipe(
      map((requisitions: IRequisitionNotification[]) => {
        return requisitions.map((requisition: IRequisitionNotification) => new RequisitionNotification(requisition));
      })
    );
  }

  getRequisitionNotifications(): Observable<Notification[]> {
    return forkJoin([
      this.getRequisitionForReviewingNotifications(),
      this.getReviewedRequisitionNotifications()
    ]).pipe(
      map(([requisitionsForReview, reviewedRequisitions]) => {
        return [...requisitionsForReview, ...reviewedRequisitions];
      })
    );
  }

  getAlvaNotifications(): Observable<Notification[]> {
    let params = new HttpParams();

    params = params.append('company', `${this.setupService.companyId}`);
    params = params.append('updateSeen', 'false');

    return this.http
      .get(
        environment.alvaNotifications,
        { params }
      )
      .pipe(
        map((alvaAssessments: IAlvaResponse[]) =>
          alvaAssessments.map((response: IAlvaResponse) => new AlvaNotification(response))
        )
      );
  }

  getAllNotifications(): Observable<Notification[][]> {
    return timer(0, 300000)
      .pipe(
        switchMap(() => forkJoin([
          this.getEmailNotificationsForDashboard(),
          this.getTagNotifications(),
          this.getRefAppNotifications(),
          this.getUploadedCVNotifications(),
          this.getCandidateProvidedInfoNotifications(),
          this.getSriBackgroundCheckNotifications(),
          this.getAdwayNotifications(),
          this.getRequisitionNotifications(),
          this.getAlvaNotifications()
        ]))
      );
  }

  deleteRequisitionNotification(notificationId: number): Observable<Object> {
    return this.http.delete(`${environment.requisitionNotifications}/${notificationId}`);
  }
}
