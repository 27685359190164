import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { QUIZ_MODULES } from 'src/app/resources/quiz-modules';
import { JobStoreService } from 'src/app/services/job-store.service';
import { FormGroup } from '@angular/forms';
import { QuizModule } from 'src/app/model/quiz-module.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { expandAnimation } from 'src/app/modules/shared/animations/expand.animation';
import { ModalService } from 'src/app/services/modal.service';
import { PreviewDraftModal } from 'src/app/classes/modal.class';
import { AbstractModal } from 'src/app/modules/modal/abstract-modal';
import { PreviewDraftModalData } from 'src/app/model/modal.interface';

@Component({
  selector: 'app-job-preview',
  templateUrl: './job-preview.component.html',
  styleUrls: ['./job-preview.component.scss'],
  animations: [expandAnimation]
})
export class JobPreviewComponent extends AbstractModal implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  modal: PreviewDraftModal;

  quizModules: QuizModule[] = [
    {
      name: QUIZ_MODULES.INHOUSE_QUESTION,
      title: 'JOBS.INHOUSE_QUESTION',
      expand: false,
      enableEdit: false
    },
    {
      name: QUIZ_MODULES.KNOCKOUT_QUESTIONS,
      title: 'SHARED.KNOCKOUT_QUESTIONS',
      expand: false,
      enableEdit: false
    },
    {
      name: QUIZ_MODULES.BUSINESS_QUESTIONS,
      title: 'SHARED.SKILLS',
      expand: false,
      enableEdit: false
    },
    {
      name: QUIZ_MODULES.PERSONALITY_TEST,
      title: 'JOBS.PERSONALITY_TEST_TITLE',
      expand: false,
      enableEdit: false
    },
    {
      name: QUIZ_MODULES.VIDEO_QUESTIONS,
      title: 'JOBS.VIDEO_QUESTIONS_TITLE',
      expand: false,
      enableEdit: false
    }
  ];

  readonly quizModuleNames = QUIZ_MODULES;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    modalService: ModalService,
    private jobStore: JobStoreService
  ) {
    super(modalService);
   }

  get orderOfQuestionsModules(): string[] {
    return this.form?.get('orderOfQuestionsModules').value;
  }

  get inhouseQuestion(): FormGroup {
    return this.form.get('inhouseQuestion') as FormGroup;
  }

  get knockoutQuestions(): FormGroup {
    return this.form.get('knockoutQuestions') as FormGroup;
  }

  get businessQuestions(): FormGroup {
    return this.form.get('businessQuestions') as FormGroup;
  }

  get videoQuestions(): FormGroup {
    return this.form.get('videoQuestions') as FormGroup;
  }

  get personalityTest(): FormGroup {
    return this.form.get('personalityTest') as FormGroup;
  }

  get data(): PreviewDraftModalData {
    return this.modal?.data;
  }

  ngOnInit(): void {
    if (!this.form) {
      this.form = this.data.form;
    }

    this.quizModules.forEach(({name}: QuizModule) => {
      const moduleForm: FormGroup = this.form.get(name) as FormGroup;

      if (moduleForm) {
        this.trackQuizChanges(moduleForm, name);
      }
    });
  }

  findModuleIndex(moduleName: string): number {
    return this.orderOfQuestionsModules
      .findIndex((module: string) => module === moduleName);
  }

  trackQuizChanges(form: FormGroup, quizType: string): void {
    form.valueChanges
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((data) => {
        const quizModules = {
          ...this.jobStore.getJobFromSessionStorage()?.quizModules,
          ...{[quizType]: data},
        };
        this.jobStore.updateJob({quizModules});
      });
  }

  enableEdit(module: QuizModule): void {
    if (module.enableEdit && !this.form.get(module.name).valid) {
      return;
    }
    module.enableEdit = !module.enableEdit;
  }

  closeModal(): void {
    this.modalService.removeModal(this.modal);
    this.data.close();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
