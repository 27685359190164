<section [ngClass]="{'not-preview': isModal}">
  <div class="button-close-wrapper" *ngIf="isModal">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <header class="header">
    <h3 class="header-title">
      {{data?.title}}
    </h3>
  </header>
  <div class="panel">
    <div class="requisition-info-wrapper" *ngIf="mode === REQUISITION_MODE.review || isJobEditOrCreate">
      <div class="requisition-info">
        <i class="icon-avatar icon"></i>
        <span class="info">
          {{(isJobEditOrCreate ? 'REQUISITION_LIST.APPROVED_BY' :  'REQUISITION_LIST.FILLED_IN_BY') | translate}}
          {{ isJobEditOrCreate ?  data?.approvedBy : data?.creator }}
        </span>
      </div>
      <div class="requisition-info" *ngIf="isJobEditOrCreate">
        <i class="icon-avatar icon"></i>
        <span class="info">
          {{'REQUISITION_LIST.FILLED_IN_BY' | translate}} {{ data?.creator }}
        </span>
      </div>
      <div *ngIf="isJobEditOrCreate && data?.utcOfApproval" class="requisition-info">
        <i class="icon-calendar icon"></i>
        <span class="info" *ngIf="isJobCreate;else isEdit">
          {{'REQUISITION_LIST.APPROVED_DATE_LABEL' | translate}}
          {{ data?.utcOfApproval | dateDayMonth }}
        </span>
        <ng-template #isEdit>
          <span class="info">
            {{'REQUISITION_LIST.APPROVED_DATE_LABEL' | translate}}
            {{ data?.utcOfApproval }}
          </span>
        </ng-template>
      </div>
      <div *ngIf="mode === REQUISITION_MODE.review" class="requisition-info">
        <i class="icon-calendar icon"></i>
        <span class="info">
          {{ data?.createdAt | dateDayMonth }}
        </span>
      </div>
    </div>
    <form [formGroup]="form">
      <div class="dinamic-form-wrapper" formArrayName="dinamicForm">
          <div class="row">
            <div class="dinamic-form col-md-6"  *ngFor="let control of dinamicForm.controls; let i = index">
              <div [formGroup]="control">
                <div class="form-group" *ngIf="control.get('elementType').value === CONTROL_TYPE.dropdown">
                  <label>{{ control.get('elementLabel').value }}</label>
                  <ng-select
                    #select
                    bindLabel="value"
                    bindValue="value"
                    [ngClass]="{'is-invalid': control.get('selectedValues').invalid && control.get('selectedValues').touched}"
                    [items]="control.get('values').value"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [notFoundText] = "'USER.NO_ITEMS_FOUND_MESSAGE' | translate"
                    formControlName="selectedValues"
                    (blur)="select.close()">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <label class="custom-checkbox">
                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" [disabled]="item$.selected"/>
                          {{item.value}}
                        <span class="checkmark  square"></span>
                      </label>
                    </ng-template>
                  </ng-select>
                  <div class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.SELECT_OPTION' | translate }}</div>
                </div>
                <app-quill-editor
                  [label]="control.get('elementLabel').value"
                  *ngIf="control.get('elementType').value === CONTROL_TYPE.text"
                  [form]="control"
                  controlName='insertedValue'
                  [toolbarPositionBottom]="true">
                </app-quill-editor>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" *ngIf="(mode === REQUISITION_MODE.review && data?.status === REQUISITION_STATUS.pending) || (mode === REQUISITION_MODE.correct && data?.status === REQUISITION_STATUS.denied) || mode === REQUISITION_MODE.blank || (isModal && !isJobEditOrCreate)">
            <div class="form-group">
              <label>{{ 'SHARED.ADD_COMMENT' | translate }} <em>({{ 'SHARED.OPTIONAL' | translate }})</em></label>
              <textarea class="form-control"
                        formControlName="comment"
                        rows="3">
              </textarea>
            </div>
          </div>
          <div class="col-md-6" *ngIf="(platformsList && (mode === REQUISITION_MODE.review || (mode === REQUISITION_MODE.correct && data.platforms?.length)) || (isJobEditOrCreate && data.platforms?.length))">
            <div class="form-group">
              <label>{{ 'REQUISITION_FORM.PLATFORMS_LABEL' | translate }}</label>

              <ng-select #select
                          [items]="platformsList"
                          [multiple]="true"
                          bindLabel="name"
                          bindValue="id"
                          [closeOnSelect]="false"
                          [notFoundText] = "'USER.NO_ITEMS_FOUND_MESSAGE' | translate"
                          [formControl]="platforms"
                          (blur)="select.close()">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <label class="custom-checkbox">
                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" [disabled]="item$.selected"/>
                      {{item.name}}
                    <span class="checkmark  square"></span>
                  </label>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="data?.requisitionComments?.length">
          <div class="comments-wrapper">
            <p>{{ 'REQUISITION_FORM.PREVIOUS_COMMENT_TITLE' | translate }}</p>
            <div class="comment-container"
                  *ngFor="let comment of data.requisitionComments">
               <div class="d-flex">
                 <div class="comment-image-holder">
                   {{ (comment.anonymousCreator ? companyName : comment.creatorName) | initials }}
                 </div>
                 <div class="comment-content-wrapper">
                   <div class="comment-info-line">
                     <div>
                      <p class="mb-0 comment-recruiter-text">{{ comment.anonymousCreator ? companyName : comment.creatorName }}</p>
                      <p *ngIf="!isJobEdit" class="comment-recruiter-date">{{ comment.createdAt | dateDayMonth }}</p>
                      <p *ngIf="isJobEdit" class="comment-recruiter-date">{{ comment.createdAt }}</p>
                     </div>
                   </div>
                   <div class="content-wrapper">
                     <p>{{comment.content}}</p>
                     <div class="actions-wrapper">
                     </div>
                   </div>
                 </div>
               </div>
            </div>
          </div>
        </div>
    </form>
  </div>
  <button *ngIf="isJobCreate" type="button" (click)="addRequisition()" class="btn btn-primary button-in-modal">
    <span>{{'REQUISITION_LIST.ATTACH_REQUISITION' | translate}}</span>
  </button>
</section>
<div class="panel control-panel" *ngIf="!isModal">
  <button (click)="cancel()"
        type="button"
        class="btn btn-outline-primary">{{ 'JOBS.JOB_EDIT_CANCEL_BUTTON' | translate}}</button>
  <button type="button"
          (click)="submitForm()"
          class="btn btn-primary"  *ngIf="mode === REQUISITION_MODE.blank || (mode === REQUISITION_MODE.correct && data?.status === REQUISITION_STATUS.denied)">
    <span>{{'BUTTONS.SAVE' | translate}}</span>
  </button>
  <ng-container *ngIf="mode === REQUISITION_MODE.review && data?.status === REQUISITION_STATUS.pending">
    <button type="button"
    (click)="reviewRequisition('approved')"
    class="btn btn-primary">
    {{'BUTTONS.APPROVE' | translate}}
    </button>
    <button type="button"
    (click)="reviewRequisition('denied')"
    class="btn btn-primary">
    {{'BUTTONS.DENY' | translate}}
    </button>
    <button type="button"
    (click)="reviewRequisition('completelyDenied')"
    class="btn btn-primary">
    {{'BUTTONS.COMPLETELY_DENY' | translate}}
  </button>
  </ng-container>
</div>
