import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

export interface PsykometrikaQuestion {
  id: number;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class PsykometrikaService {
  constructor(
    private http: HttpClient,
  ) { }

  getPsykometrika(language: string): Observable<PsykometrikaQuestion[]> {
    return this.http.get(`${environment.psykometrika}/${language}/random/test`)
      .pipe(
        map(response => Object.values(response)[0])
      );
  }

}
