import { ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Question, TypeOfSkillsQuestion } from 'src/app/model/job.interface';
import { SkillsLibraryModalData } from 'src/app/model/modal.interface';
import { PaginationData } from 'src/app/model/pagination.interface';
import { AbstractModal } from 'src/app/modules/modal/abstract-modal';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuizService } from 'src/app/services/quiz.service';

export abstract class AbstractSkillsLibrary extends AbstractModal {

  protected questionsList: Question[];
  protected selectedQuestions: Question[] = [];
  protected pagination: PaginationData = { page: 1, perPage: 10, total: null };
  protected filterForm: FormGroup;
  protected translationLanguage: string;

  get data(): SkillsLibraryModalData {
    return this.modal.data;
  }

  protected constructor(
    protected modalService: ModalService,
    protected cdr: ChangeDetectorRef,
    protected toastr: ToastrService,
    protected translateService: TranslateService,
    protected quizService: QuizService,
    protected errorHandlingService: ErrorHandlingService
  ) {
    super(modalService);
  }

  abstract getData(): void;
  abstract setFormValues(controlName: string): void;
  abstract loadMoreQuestions(): void;

  mapReceivedQuestions(item: {question: Question, id?: number, hideable?: boolean}): Question {
    const question = item.question;
    if (question.maxNumberOfAnswersCandidateCanChoose && question.maxNumberOfAnswersCandidateCanChoose > 1) {
      question.questionType = TypeOfSkillsQuestion.multipleAnswersQuestion;
    } else if (question.answers.findIndex(answer => answer.followUpQuestion) > -1) {
      question.questionType = TypeOfSkillsQuestion.questionWithFollowUpQuestions;
    } else {
      question.questionType = TypeOfSkillsQuestion.singleAnswerQuestion;
    }
    if (item.id) {
      question.id = item.id;
    }

    if (item.hideable) {
      question.hideable = item.hideable;
    }
    return question;
  }

  resetControl(controlName: string): void {
    this.filterForm.get(controlName).patchValue(null);
    this.selectedFilter(controlName);
  }

  selectedFilter(controlName: string): void {
    this.setFormValues(controlName);
    this.getData();
  }

  onScroll(): void {
    if (this.pagination.page * this.pagination.perPage >= this.pagination.total) {
      return;
    }

    this.pagination.page++;

    this.loadMoreQuestions();
  }

  useQuestions(): void {
    if (!this.selectedQuestions.length) {
      this.toastr.error(
        this.translateService.instant('JOBS.BUSINESS_QUESTIONS.YOU_HAVE_TO_SELECT_QUESTION_YOU_WANT_TO_ADD')
      );
      return;
    }

    this.data.confirm(this.selectedQuestions);
    this.closeModal();
  }

  onChosenItem( questions: Question[]): void {
    this.selectedQuestions = questions;
  }
}
