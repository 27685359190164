import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { changeVideoExtension } from 'src/app/resources/video-url-transformation-functions';
import { maxVideoMainMessageLength, maxVideoMessageLength } from 'src/app/resources/video-intro-content';


@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
})
export class VideoPreviewComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() enableEdit: boolean;

  videoPosterUrl: string;
  videoWebmUrl: string;
  videoMp4Url: string;
  maxVideoMessageLength = maxVideoMessageLength;
  maxVideoMainMessageLength = maxVideoMainMessageLength;

  constructor() { }

  get video(): FormGroup {
    return this.form.get('video') as FormGroup;
  }

  get questions(): FormControl[] {
    return (this.form.get('questions') as FormArray).controls as FormControl[];
  }

  get videoMessage(): FormControl {
    return this.form.get('videoMessage') as FormControl;
  }

  get videoMainMessage(): FormControl {
    return this.form.get('videoMainMessage') as FormControl;
  }

  ngOnInit(): void {
    if (this.video.value) {
      this.videoPosterUrl = changeVideoExtension(this.video.value, 'jpg');
      this.videoMp4Url = changeVideoExtension(this.video.value, 'mp4');
      this.videoWebmUrl = changeVideoExtension(this.video.value, 'webm');
    }
  }
}
