<div class="custom-modal-wrapper">
  <div class="close-icon-wrapper">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <div class="custom-modal">
    <h3 class="modal-title">
      {{ 'JOBS.USE_PREVIOUSLY_ADDED_QUESTION' | translate }}
    </h3>
    <div class="modal-body">

      <!-- FILTERS -->
      <form [formGroup]="filterForm">
        <!-- CATEGORY -->
        <div class="col-md-12 col-lg-6 display-inline">
          <div class="form-group">
            <h4>{{ 'JOBS.CATEGORY_LABEL' | translate }}</h4>
            <div class="optional-select-wrapper">
              <select formControlName="category"
                      class="form-control"
                      (change)="selectedFilter('category')">
                <option selected
                        [ngValue]="null"
                        class="d-none"></option>
                <option disabled
                        *ngIf="categories?.length === 0"
                        [value]="">{{ 'SHARED.NO_DATA' | translate }}</option>
                <option *ngFor="let category of categories"
                        [ngValue]="category.id">{{ category.name[translationLanguage] }}</option>
              </select>
              <span *ngIf="category.value" class="remove-wrapper" (click)="resetControl('category')">
                ×
              </span>
            </div>
          </div>
        </div>

        <!-- LANGUAGE -->
        <div class="col-md-12 col-lg-6 display-inline">
          <div class="form-group">
            <h4>{{ 'JOBS.JOB_LANGUAGE_LABEL' | translate }}</h4>
            <div class="optional-select-wrapper">
              <select formControlName="language"
                      class="form-control"
                      (change)="selectedFilter('language')">
                <option selected
                        [ngValue]="null"
                        class="d-none"></option>
                <option *ngFor="let language of possibleLanguages"
                        [ngValue]="language">{{ ('COUNTRY.LANGUAGES.' + language | uppercase) | translate }}</option>
              </select>
              <span *ngIf="language.value" class="remove-wrapper" (click)="resetControl('language')">
                ×
              </span>
            </div>
          </div>
        </div>

        <!-- JOB -->
        <div class="col-md-12 col-lg-6 display-inline">
          <div class="form-group">
            <h4>{{ 'REPLY_EMAIL.JOB' | translate }}</h4>
            <div class="optional-select-wrapper">
              <select formControlName="job"
                      class="form-control"
                      (change)="selectedFilter('job')">
                <option selected
                        [ngValue]="null"
                        class="d-none"></option>
                <option disabled
                        *ngIf="jobs?.length === 0"
                        [value]="">{{ 'SHARED.NO_DATA' | translate }}</option>
                <option *ngFor="let job of jobs"
                        [ngValue]="job.guid">{{ job.jobTitle }}</option>
              </select>
              <span *ngIf="job.value" class="remove-wrapper" (click)="resetControl('job')">
                ×
              </span>
            </div>
          </div>
        </div>

        <!-- JOB TEMPLATE -->
        <div class="col-md-12 col-lg-6 display-inline">
          <div class="form-group">
            <h4>{{ 'TEMPLATES.NEW_TEMPLATE_TITLE' | translate }}</h4>
            <div class="optional-select-wrapper">
              <select formControlName="jobTemplate"
                      class="form-control"
                      (change)="selectedFilter('jobTemplate')">
                <option selected
                        [ngValue]="null"
                        class="d-none"></option>
                <option disabled
                        *ngIf="jobTemplates?.length === 0"
                        [value]="">{{ 'SHARED.NO_DATA' | translate }}</option>
                <option *ngFor="let template of jobTemplates"
                        [ngValue]="template.id">{{ template.jobTitle }}</option>
              </select>
              <span *ngIf="jobTemplate.value" class="remove-wrapper" (click)="resetControl('jobTemplate')">
                ×
              </span>
            </div>
          </div>
        </div>
      </form>

      <!-- LIST OF QUESTIONS -->
      <app-question-list [questionsList]="questionsList"
                         (callScroll)="onScroll()"
                         (hideUnhideQuestion)="onHideQuestion()"
                         #questionList
                         [selectQuestionWithId]="true"
                         (chosenItem)="onChosenItem($event)">
      </app-question-list>
    </div>

    <div class="button-wrapper">
      <button
        class="btn btn-outline-primary"
        (click)="openHiddenQuestionsModal()">
        {{ "BUTTONS.SHOW_HIDDEN_QUESTIONS" | translate }}
      </button>
      <button (click)="closeModal()"
              class="btn btn-outline-primary">
        {{ "BUTTONS.CANCEL" | translate }}
      </button>
      <button class="btn btn-primary border-white"
              (click)="useQuestions()">
        {{ "BUTTONS.APPLY" | translate }}
      </button>
    </div>

  </div>
</div>
