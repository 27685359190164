
export interface IAgreement {
  id?: number;
  guid?: string;
  status?: string;
  title: string,
  filledInAgreementFormElements?: ({[key: string]: number[]} | {[key: string]: string})[];
  agreementFormElements?: AgreementDinamicControl[];
  terms: string;
}

export interface IAgreementListItem extends IAgreement {
  createdAt: string;
  applicationEmail: {
    createdAt: string;
    changedReceiverEmail: string;
  }
}

export interface AgreementPayload {
  filledInAgreementFormElements: ({[key: string]: number[]} | {[key: string]: string})[];
}

export enum AgreementMode {
  preview = 'preview',
  blank = 'blank',
}

export enum AgreementFormMode {
  preview = 'preview',
  edit = 'edit',
  create = 'create'
}
export interface IAgreementForm {
  guid?: string;
  createdAt?: string;
  creator?: AgreementFormUser;
  id?: number;
  lastUpdatedBy?: AgreementFormUser;
  title: string;
	agreementFormElements: AgreementDinamicControl[];
  customersAllowed: AgreementFormUser[] | number[];
  terms: string;
  branch: AgreementFormBranch | number;
  status?: string;
  updatedAt?: string;
}

export enum AgreementStatus {
  pending = 'pending',
  filledIn = 'filledIn'
}

export enum ControlType {
  dropdown = 'dropdown',
  text = 'textField',
  info = 'info',
  signature = 'signature'
}

export interface AgreementDinamicControl {
  elementType: string;
  elementLabel?: string;
  values?: string[] | string | {id: number, value: string}[];
  textFieldEditorValue?: string;
  selectedValues?: number[];
  required: boolean;
  insertedValue?: string;
  insertedSignature?: string;
}

export interface AgreementFormBranch {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface AgreementFormUser {
  id?: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface historyAgreementForm {
  id: number;
  guid: string;
  content: IAgreementForm;
  creator: AgreementFormUser | string;
  createdAt: string;
  createdFromProcess: agreementFormCreatedFrom;
}

export enum agreementFormCreatedFrom {
  customer_edit_or_created = 'AGREEMENT_FORM.AGREEMENT_FORM_EDITOR.CUSTOMER_EDITED_OR_CREATED',
  removed_branch_from_customer = 'AGREEMENT_FORM.AGREEMENT_FORM_EDITOR.REMOVED_BRANCH_FROM_CUSTOMER',
  removed_access_from_customer = 'AGREEMENT_FORM.AGREEMENT_FORM_EDITOR.REMOVED_ACCESS_FROM_CUSTOMER',
  customer_deleted ='AGREEMENT_FORM.AGREEMENT_FORM_EDITOR.CUSTOMER_DELETED'
}
