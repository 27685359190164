import { AiSkillsQuestions, IJob, JobLocation, JobStatistics, JobStatus, ListJob, ListTemplate } from '../model/job.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { JobStoreService } from './job-store.service';
import { Pagination } from '../model/pagination.interface';
import { Job, JobTemplate } from '../classes/job.class';
import { UniversalOption } from '../model/universal-option.interface';
import { JobsOverview } from '../model/jobs-overview.interface';
import { UserService } from './user.service';
import { SetupService } from './setup.service';
import { ENTERPRISE_ROLES } from '../resources/roles';
import { TranslationKeys } from '../model/translation-object.interface';
import { Branch } from '../model/branch.interface';
import { BindingPayload } from '../model/binding.interface';
import { AdwayService } from './adway.service';
import { ErrorHandlingService } from './handle-error.service';
import { PublishingPlatforms } from '../model/publishing-platform.interface';
import { PublishingPlatformsService } from './publishing-platforms/publishing-platforms.service';

@Injectable({
  providedIn: 'root',
})
export class JobsService {

  private _categories: UniversalOption[];
  private _employmentTypes: UniversalOption[];
  private _employmentTypesAddition: UniversalOption[];
  private _jobWorkLocations: UniversalOption[];

  get language(): TranslationKeys {
    return this.setupService.companyLanguage;
  }

  constructor(
    private http: HttpClient,
    private jobStore: JobStoreService,
    private userService: UserService,
    private setupService: SetupService,
    private adwayService: AdwayService,
    private errorService: ErrorHandlingService,
    private publishingPlatformsService: PublishingPlatformsService,
  ) { }

  get isEnterpriseUser(): boolean {
    return ENTERPRISE_ROLES.includes(this.userService.role);
  }

  getAllJobs(queryParams: any): Observable<Pagination<ListJob>> {
    let params = new HttpParams();

    if (queryParams.page) {
      params = params.append('page', queryParams.page.toString());
    } else {
      params = params.append('page', '1');
    }

    if (queryParams.limit) {
      params = params.append('limit', queryParams.limit.toString());
    }

    if (queryParams.status) {
      if (queryParams.status === 'active') {
        params = params.append('status', queryParams.status);
      } else if (queryParams.status === 'inactive') {
        params = params.append('status', queryParams.status);
        params = params.append('archived', '0');
      } else if (queryParams.status === 'archived') {
        params = params.append('archived', '1');
        params = params.delete('status');
      } else if (queryParams.status === 'activeAndInactive') {
        params = params.delete('status');
        params = params.append('archived', '0');
      }
    }

    if (queryParams.responsibleEnterpriseManagers && queryParams.responsibleEnterpriseManagers.length > 0) {
      params = params.append('responsibleEnterpriseManagers', queryParams.responsibleEnterpriseManagers);
    }

    if (queryParams.responsibleCustomers && queryParams.responsibleCustomers.length > 0) {
      params = params.append('responsibleCustomers', queryParams.responsibleCustomers);
    }

    if (queryParams.locations && queryParams.locations.length !== 0) {
      params = params.append('locations', queryParams.locations);
    }

    if (queryParams.branches && queryParams.branches.length !== 0) {
      params = params.append('branches', queryParams.branches);
    }

    if (queryParams.closeToEndDate) {
      params = params.append('closeToEndDate', '1');
    }

    if (queryParams.pageType === 'handle-candidates') {
      params = params.append('allPersonalCandidateDateRemoved', '0');
    }

    if (queryParams.pageType === 'bind-jobs') {
      params = params.append('onlyEligibleForBinding', '1');
    }

    if (queryParams.searchBy) {
      params = params.append('searchBy', queryParams.searchBy);
    }

    const userId = this.userService.userId;

    const endpoint = this.isEnterpriseUser
      ? `${environment.managers}/${userId}/companies/${this.setupService.companyId}/jobs`
      : `${environment.companyUsers}/${userId}/jobs`;

    return this.http
      .get<Pagination<ListJob>>(
        endpoint,
        { params }
      )
      .pipe(
        map((response: Pagination<ListJob>) => {
          response.data.forEach((job: ListJob) => {
            job.jobLocationName = JSON.parse(job.jobLocationName.toString());
          });

          return response;
        })
      );
  }

  getAllCompanyJobs(queryParams: any): Observable<ListJob[]> {
    let params = new HttpParams();

    if (queryParams.page) {
      params = params.append('page', queryParams.page.toString());
    } else {
      params = params.append('page', '1');
    }

    if (queryParams.limit) {
      params = params.append('limit', queryParams.limit.toString());
    }

    if (queryParams.category) {
      params = params.append('category', queryParams.category);
    }

    if (queryParams.language) {
      params = params.append('languageCode', queryParams.language);
    }

    params = params.append('hasBusinessQuestions', 1);

    const userId = this.userService.userId;
    const endpoint = this.isEnterpriseUser
      ? `${environment.managers}/${userId}/companies/${this.setupService.companyId}/jobs`
      : `${environment.companyUsers}/${userId}/jobs`;

    return this.http
      .get( endpoint, { params } )
      .pipe(
        map(({data}: Pagination<ListJob>) => data)
      );
  }

  getCompanyActiveJobs(id: number): Observable<number> {
    let params = new HttpParams();

    params = params.append('limit', 1);
    params = params.append('status', 'active');

    return this.http
      .get<Pagination<ListJob>>(
        `${environment.managers}/${this.userService.userId}/companies/${id}/jobs`,
        { params }
      )
      .pipe(
        map(({total}: Pagination<ListJob>) => total)
      );
  }

  getJob(id: number): Observable<Job> {
    return this.http.get(`${environment.job}/${id}`)
      .pipe(
        map(({data}: Pagination<IJob>) => data[0]),
        map((job: IJob) => new Job(job, this.language, this.setupService.companyName))
      );
  }

  createJob(): Observable<IJob> {
    const job = Job.prepareForPublish(this.jobStore.getJobFromSessionStorage(),
                                      this.language,
                                      this.publishingPlatformsService.alljobPlatforms);

    if (job.adwayJob) {
      return this.adwayService.validateAdwayForNewJob(job)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          return this.errorService.handleAdwayError(response);
        }),
        switchMap(() => {
          return this.http.post(environment.job, {...job, company: this.setupService.companyId})
            .pipe(
              map(({data}: Pagination<IJob>) => data[0]),
            );
        })
      );
    }

    return this.http.post(environment.job, {...job, company: this.setupService.companyId})
      .pipe(
        map(({data}: Pagination<IJob>) => data[0]),
      );
  }

  prepareForEditJob(): IJob {
    const job = Job.prepareForPublish(this.jobStore.getJobFromSessionStorage(), this.language, this.publishingPlatformsService.alljobPlatforms);
    if (job.status === JobStatus.inactive || job.status === JobStatus.archive ) {
      job.jobPlatforms = [];
    }
    delete job.allowAnonymousFlow;
    delete job.requisition;
    return job;
  }

  editJob(id: number, payload?: {jobsToBeBounded: number[]}): Observable<IJob> {
    const jobPayload = payload ? payload : this.prepareForEditJob();

    if (('adwayJob' in jobPayload) && jobPayload.adwayJob) {
      return this.adwayService.validateAdwayForExistingJob(jobPayload, id)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          return this.errorService.handleAdwayError(response);
        }),
        switchMap(() => {
          return this.http.put(`${environment.job}/${id}`, jobPayload)
            .pipe(
              map(({data}: Pagination<IJob>) => data[0]),
            );
        })
      );

    }

    return this.http
      .put(`${environment.job}/${id}`, jobPayload)
      .pipe(
        map(({data}: Pagination<IJob>) => data[0]),
      );
  }

  editJobStatus(id: number, status: string): Observable<IJob> {
    const payload: {status?: string, archived?: boolean, jobPlatforms?: PublishingPlatforms} = {};

    switch (status) {
      case JobStatus.archive:
        payload.status = 'inactive';
        payload.archived = true;
        payload.jobPlatforms = [];
        break;
      case JobStatus.inactive:
        payload.status = status;
        payload.archived = false;
        payload.jobPlatforms = [];
        break;
      case JobStatus.active:
        payload.status = status;
        payload.archived = false;
        break;

      default:
        break;
    }

    return this.http
    .put(`${environment.job}/${id}`, payload)
    .pipe(
      map(({data}: Pagination<IJob>) => data[0]),
    );
  }

  getJobTemplate(id: number, isCreate: boolean = false): Observable<Job> {
    return this.http.get<Pagination<IJob>>(`${environment.jobTemplates}/${id}`)
      .pipe(
        map(({data}: Pagination<IJob>) => data[0]),
        map((data: IJob) => isCreate ? new Job(data, this.language)
                               : new JobTemplate(data, this.language))
      );
  }

  createJobTemplate(): Observable<IJob> {
    const template = JobTemplate.prepareForPublish(this.jobStore.getJobFromSessionStorage(),
                                                   this.language,
                                                   this.publishingPlatformsService.alljobPlatforms);
    return this.http.post<IJob>(
      environment.jobTemplates,
      { ...template, company: this.setupService.companyId }
    );
  }

  editJobTemplate(templateId: number): Observable<IJob> {
    const job: JobTemplate = this.jobStore.getJobFromSessionStorage();
    const template = JobTemplate.prepareForPublish(job, this.language, this.publishingPlatformsService.alljobPlatforms);

    return this.http.put(`${environment.jobTemplates}/${templateId}`, template)
      .pipe(
        map(({data}: Pagination<IJob>) => data[0]),
      );
  }

  getCategories(): Observable<UniversalOption[]> {
    if (this._categories) {
      return of(this._categories);
    }

    return this.http
      .get(`${environment.jobCategories}?limit=1000`)
      .pipe(
        map(({data}: Pagination<UniversalOption>) => data),
        tap((categories: UniversalOption[]) => this._categories = categories)
      );
  }

  getCategoriesWithSkillsQuestions(): Observable<UniversalOption[]> {
    let params = new HttpParams();

    params = params.set('limit', '1000');
    params = params.set('companyIds', this.setupService.companyId);
    params = params.set('hasBusinessQuestions', '1');

    return this.http.get(`${environment.jobCategories}`, { params })
      .pipe(
        map(({data}: Pagination<UniversalOption>) => data),
      );
  }

  getEmploymentTypes(): Observable<UniversalOption[]> {
    if (this._employmentTypes) {
      return of(this._employmentTypes);

    }

    return this.http
      .get(`${environment.jobEmploymentTypes}?limit=1000`)
      .pipe(
        map(({data}: Pagination<UniversalOption>) => data),
        tap((employmentTypes: UniversalOption[]) => this._employmentTypes = employmentTypes)
      );
  }

  getEmploymentTypesAddition(): Observable<UniversalOption[]> {
    if (this._employmentTypesAddition) {
      return of(this._employmentTypesAddition);

    }

    return this.http
      .get(`${environment.jobEmploymentTypesAddition}?limit=1000`)
      .pipe(
        map(({data}: Pagination<UniversalOption>) => data),
        tap((employmentTypesAddition: UniversalOption[]) => this._employmentTypesAddition = employmentTypesAddition)
      );
  }

  getJobWorkLocations(): Observable<UniversalOption[]> {
    if (this._jobWorkLocations) {
      return of(this._jobWorkLocations);
    }

    return this.http
      .get(environment.jobWorkLocations)
      .pipe(
        map(({data}: Pagination<UniversalOption>) => data),
        tap((jobWorkLocations: UniversalOption[]) => this._jobWorkLocations = jobWorkLocations)
      );
  }

  uploadImage(image: string, isDuunitori?: boolean): Observable<string> {
    const picture_enc = image.split(',')[1];
    return this.http.post<string>(isDuunitori ? environment.jobDuunitoriBanner : environment.jobPicture, { picture_enc });
  }

  getJobStatisticOverview(): Observable<JobStatistics> {
    return this.http.get<JobStatistics>(`${environment.jobsOverview}/${this.setupService.companyGuid}`);
  }

  getJobsApplicationsOverview(): Observable<JobsOverview> {
    const endpoint = this.isEnterpriseUser
      ? `${environment.jobsApplicationOverviewManager}/${this.setupService.companyGuid}`
      : environment.jobsApplicationOverview;

    return this.http.get<JobsOverview>(endpoint);
  }

  getJobsByIds(jobIds: string[]): Observable<ListJob[]> {
    let params = new HttpParams();

    if (jobIds.length) {
      params = params.append('jobs', jobIds.toString());
    }

    params = params.append('limit', '1000');

    const userID = this.userService.userId;

    const endpoint = this.isEnterpriseUser
      ? `${environment.managers}/${userID}/companies/${this.setupService.companyId}/jobs`
      : `${environment.companyUsers}/${userID}/jobs`;

    return this.http
      .get(endpoint, { params })
      .pipe(
        map(({data}: Pagination<ListJob>) => data),
      );
  }

  getJobsToBeBouned(jobGuid: string, branches?: Branch[], locations?: JobLocation[]): Observable<IJob[]> {
    let params = new HttpParams();
    if (locations && locations.length) {
      const locationIds = locations.map(item => item.id);
      params = params.append('locations', locationIds.toString());
    }
    if (branches && branches.length) {
      const branchIds = branches.map(item => item.id);
      params = params.append('branches', branchIds.toString());
    }
    return this.http.get(`${environment.job}/${jobGuid}/eligible_jobs`, {params})
      .pipe(
        mergeMap((eligibleJobIds: number[]) => {
          if (eligibleJobIds.length) {
            return forkJoin(
              eligibleJobIds.map(
                id => {
                  return this.http.get(`${environment.job}/${id['id']}`).pipe(
                    catchError((error: HttpErrorResponse) => {
                      if ( error.status === 403) {
                        return of(null);
                      }
                    }),
                    map((data: Pagination<IJob>) => {
                      if (data) {
                        return data.data[0];
                      }
                    })
                  );
                }
              )
            );
          } else {
            return of(null);
          }
        })
      );
  }

  getEligibleJobs(jobGuid: string, branches?: Branch[], locations?: JobLocation[]): Observable<{id: number}[]> {
    let params = new HttpParams();
    if (locations && locations.length) {
      const locationIds = locations.map(item => item.id);
      params = params.append('locations', locationIds.toString());
    }
    if (branches && branches.length) {
      const branchIds = branches.map(item => item.id);
      params = params.append('branches', branchIds.toString());
    }
    return this.http.get<{id: number}[]>(`${environment.job}/${jobGuid}/eligible_jobs`, {params});
  }

  getJobById(id: number): Observable<IJob> {
    return this.http.get(`${environment.job}/${id}`)
      .pipe(
        map(({data}: Pagination<IJob>) => data[0])
      );
  }

  bindJobs(payload: BindingPayload): Observable<string> {
    return this.http.post<string>(environment.bindJobs, payload);
  }

  getAISkillsQuestions(payload: AiSkillsQuestions): Observable<any> {
    return this.http.post(environment.aiSkills, payload);
  }
}
