import { ISms } from '../model/sms.interface';
import { Company } from './organization.class';
import { linkify } from '../resources/shared-functions';

export class SmsMessage {

  id: number;
  createdAt: string;
  content: string;
  senderName: string;

  constructor({
    id,
    createdAt,
    content,
    senderCustomer,
    senderEnterpriseManager,
  }: ISms,
    company: Company
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.content = linkify(content);

    const sender = senderCustomer || senderEnterpriseManager;
    const anonymousSender = sender.anonymousCustomer || sender.anonymousEnterpriseManager;
    this.senderName = anonymousSender ? company.name : sender.name;
  }
}
