<form class="form" [formGroup]="form">
  <input class="input-helper" [formControlName]="controlName">
  <canvas
    class="canvas"
    [ngClass]="{
      'is-invalid': form.get(controlName).invalid && form.get(controlName).touched}"
    #canvas
    height="200"
    width="420"
  ></canvas>
  <button type="button" class="btn btn-primary" *ngIf="!isPreview" (click)="clearSignature()">{{ 'AGREEMENT_FORM.CLEAR_SIGNATURE' | translate }}</button>
  <div class="dinamic-form-error" *ngIf="form.get(controlName).invalid && form.get(controlName).touched">{{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}</div>
</form>
