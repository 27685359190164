import { Component, ViewChild,ElementRef, AfterViewInit, Input, HostListener, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { CheckDeviceService } from 'src/app/services/check-device.service';
@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements AfterViewInit {
  @Input() form: FormGroup;
  @Input() controlName: string;
  signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;
  @Output() signature: EventEmitter<any> = new EventEmitter<any>();
  @Input() isPreview = false;
  savedImg: string;

  get isMobile(): boolean {
    return this.checkDevice.isMobile();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private checkDevice: CheckDeviceService
  ) {}

  @HostListener('window:resize', ['$event'])
    onResize(): void {
      if (!this.isMobile && !this.isPreview) {
        this.handleSignatureCanvasSize();
      }
  }

  @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(): void {
      this.savedImg = this.signaturePad.toDataURL();
      if (!this.isPreview) {
        this.handleSignatureCanvasSize();
      }
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.addEventListener('endStroke', this.endStroke.bind(this));
    if (this.isPreview) {
      this.signaturePad.off();
    } else {
      this.handleSignatureCanvasSize();
    }
  }

  //To correctly handle canvas on low and high DPI (dot per inch) screens one has to take devicePixelRatio ('density' of the pixels)
    // into account and scale the canvas accordingly.
   handleSignatureCanvasSize(): void {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.canvasEl.nativeElement.width = this.canvasEl.nativeElement.offsetWidth * ratio;
    this.canvasEl.nativeElement.height = this.canvasEl.nativeElement.offsetHeight * ratio;
    this.canvasEl.nativeElement.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear();
    this.form.get(this.controlName).setValue('');
    if (this.savedImg) {
      this.signaturePad.fromDataURL(this.savedImg);
      this.form.get(this.controlName).setValue(this.savedImg);
    }
  }

  endStroke(): void {
    this.form.get(this.controlName).setValue(this.signaturePad.toDataURL());
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  clearSignature(): void {
    this.signaturePad.clear();
    this.form.get(this.controlName).setValue('');
  }
}
