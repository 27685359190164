export interface RecorderCountdownSettings {
  enable: boolean;
  time: number; // countdown time in seconds
  currentTime: number;
  displayOverlay: boolean;
}

export enum RecorderMode {
  record = 'RECORD',
  play = 'PLAY',
  void = 'VOID'
}

export const DEFAULT_COUNTDOWN_TIME = 3;

export enum VideoExtensions {
  upload = 'upload_',
  stream = 'stream-',
  merge = 'merge_'
}
