import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PsykometrikaService, PsykometrikaQuestion } from 'src/app/services/psykometrika.service';
import { LANGUAGES } from 'src/app/resources/languages';
import { takeUntil, catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-psykometrika-preview',
  templateUrl: './psykometrika-preview.component.html',
  styleUrls: ['./psykometrika-preview.component.scss'],
})
export class PsykometrikaPreviewComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() enableEdit: boolean;

  questions: Map<string, PsykometrikaQuestion[]> = new Map();
  languages = LANGUAGES;
  selectedLanguage: string;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  get psykometrikaLanguage(): FormControl {
    return this.form.get('psykometrikaLanguage') as FormControl;
  }

  constructor(
    private psykometrikaService: PsykometrikaService,
    private errorHandlingService: ErrorHandlingService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
  ) { }

  ngOnInit(): void {
    this.setLanguage(this.psykometrikaLanguage.value);
    this.psykometrikaLanguage.valueChanges
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((value: string) => {
        this.setLanguage(value);
      });
  }

  setLanguage(language: string): void {
    this.loaderService.show();
    this.selectedLanguage = this.languages.find(lng => lng.key === language).name;
    if (this.questions.has(language)) {
      this.loaderService.hide();
      return;
    }
    this.psykometrikaService.getPsykometrika(language)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      )
      .subscribe(
        response => {
          this.questions.set(language, response);
          this.cdr.markForCheck();
          this.loaderService.hide();
        }
      );
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
