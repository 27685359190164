import { Injectable } from '@angular/core';
import { playFirstVideo } from '@flashphoner/websdk';
import { STREAM_STATUS } from '@flashphoner/websdk/src/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseVideoUtilsService {
  private _apiServerUrl = `https://${environment.mediaServerUrl}`;

  constructor() { }

  isPlaying(streamStatus: string): boolean {
    switch(streamStatus) {
      case STREAM_STATUS.PLAYING:
      case STREAM_STATUS.RESIZE:
      case STREAM_STATUS.SNAPSHOT_COMPLETE:
      case STREAM_STATUS.NOT_ENOUGH_BANDWIDTH:
        return true;
      default:
        return false;
    }
  }

  playFirstVideo(recorder: HTMLDivElement): Promise<any> {
    return playFirstVideo(
      recorder,
      true,
      'https://customer.uhigher.com/assets/videos/preloader.mp4'
    );
  }

  createUUID(length: number): string {
    const uuidArray = [];
    const hexDigits = '0123456789abcdef';
    let start: number;

    for (let i = 0; i < 36; i++) {
      start = Math.floor(Math.random() * 0x10);
      uuidArray[i] = hexDigits.substring(start, start + 1);
    }

    uuidArray[14] = '4';
    // eslint-disable-next-line no-bitwise
    start = (uuidArray[19] & 0x3) | 0x8;
    uuidArray[19] = hexDigits.substring(start, start + 1);
    uuidArray[8] = uuidArray[13] = uuidArray[18] = uuidArray[23] = '-';

    return uuidArray.join('').substring(0, length);
  }

  stopCameraAndMic(userMedia: MediaStream): void {
    userMedia?.getTracks()
      .forEach(track => track.stop());
  }

  generateMixedFileName(interviewGuid: string, prefix: boolean = true): string {
    const fileName = `stream-multi-recorder___${interviewGuid}-multi-recorder___${interviewGuid}_mixed.mp4`;
    return prefix ? `${this._apiServerUrl}/records/${fileName}` : fileName;
  }

  addPrefixToFileName(fileName: string): string {
    return `${this._apiServerUrl}/records/${fileName}`;
  }
}
