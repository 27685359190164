import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { TranslationKeys } from 'src/app/model/translation-object.interface';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuizService } from 'src/app/services/quiz.service';
import { take } from 'rxjs/operators';
import { AbstractSkillsLibrary } from 'src/app/model/abstract-skills-library.model';
import { UniversalOption } from 'src/app/model/universal-option.interface';
import { Pagination } from 'src/app/model/pagination.interface';
import { Question } from 'src/app/model/job.interface';

type ControlType = 'category' | 'subcategory' | 'language';

@Component({
  selector: 'app-global-library',
  templateUrl: './global-library.component.html',
  styleUrls: ['./global-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalLibraryComponent extends AbstractSkillsLibrary implements OnInit {

  possibleLanguages: TranslationKeys[] = [];
  categories: UniversalOption[] = [];
  subcategories: UniversalOption[] = [];

  filterForm: FormGroup = this.fb.group({
    category: [null],
    subcategory: [null]
  });

  params = {
    page: 1,
    ...this.filterForm.value,
    limit: 100000
  };

  constructor(
    modalService: ModalService,
    cdr: ChangeDetectorRef,
    toastr: ToastrService,
    translateService: TranslateService,
    quizService: QuizService,
    errorHandlingService: ErrorHandlingService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
  ) {
    super(modalService, cdr, toastr, translateService, quizService, errorHandlingService);
  }

  get category(): FormControl {
    return this.filterForm.get('category') as FormControl;
  }

  get subcategory(): FormControl {
    return this.filterForm.get('subcategory') as FormControl;
  }

  ngOnInit(): void {
    this.subcategory.disable();

    this.translationLanguage = 'sv';

    this.getPredefinedCategories();
    this.getData();
  }

  getPredefinedCategories(): void {
    this.loaderService.show();

    this.quizService.getPredefinedCategories()
      .subscribe((categories: UniversalOption[]) => {
        this.categories = categories;
        if (this.categories.length > 0) {
          this.category.patchValue(this.categories[0].id);
          this.setFormValues('category');
        }
        this.cdr.detectChanges();
        this.loaderService.hide();
      });
  }

  getPredefinedSubcategories(): void {
    this.loaderService.show();
    this.quizService.getPredefinedSubcategories(this.filterForm.value.category)
    .pipe(
      catchError((error: HttpErrorResponse) =>
        this.errorHandlingService.handleBackendError(error)
      )
    ).subscribe((subcategories) => {
      this.subcategories = subcategories;
      this.cdr.detectChanges();
      this.loaderService.hide();
    });
  }

  getData(): void {
    this.questionsList = [];

    if (this.category.value) {
      this.params = {
        page: 1,
        ...this.filterForm.value,
        limit: 100000
      };
      this.pagination.page = 1;
      this.loaderService.show();

      this.quizService.getPredefinedSkillsQuestions(this.filterForm.value, this.pagination.page, this.pagination.perPage)
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(error)
        )
      ).subscribe((questions) => {
        this.questionsList = questions.data.map((item) => {
          item.question.questionImportance = item.questionImportance;
          return this.mapReceivedQuestions(item);
        });
        this.pagination.total = questions.total;
        this.cdr.detectChanges();
        this.loaderService.hide();
      });
    }
  }

  loadMoreQuestions(): void {
    this.quizService
      .getPredefinedSkillsQuestions(this.filterForm.value, this.pagination.page, this.pagination.perPage)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(error)
        )
      )
      .subscribe((questions: Pagination<{question: Question}>) => {
        this.questionsList = this.questionsList.concat(questions.data.map(item => this.mapReceivedQuestions(item)));
        this.pagination.total = questions.total;
        this.cdr.detectChanges();
      });
  }

  setFormValues(controlName: ControlType): void {
    const controlValue = this.filterForm.get(controlName).value;

    if (controlName === 'category') {
      if (controlValue) {
        this.getPredefinedSubcategories();
        this.subcategory.enable();
        this.subcategory.patchValue(null);
        this.selectedFilter('subcategory');
        return;
      }

      this.subcategories = [];
      this.subcategory.disable();
      this.subcategory.patchValue(null);
      this.selectedFilter('subcategory');
    }
  }
}

