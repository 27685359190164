import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { JobStoreService } from 'src/app/services/job-store.service';
import { Job } from 'src/app/classes/job.class';
import { LoaderService } from 'src/app/services/loader.service';
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';
import { JobsService } from 'src/app/services/jobs.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/model/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { changeVideoExtension } from 'src/app/resources/video-url-transformation-functions';
import { UniversalOption } from 'src/app/model/universal-option.interface';
import { JobLocation } from 'src/app/model/job.interface';
import { PublishingPlatformsService } from 'src/app/services/publishing-platforms/publishing-platforms.service';
import { SetupService } from 'src/app/services/setup.service';
import { expandAnimation } from 'src/app/modules/shared/animations/expand.animation';
import { Company } from 'src/app/classes/organization.class';

@Component({
  selector: 'app-job-ad-preview',
  templateUrl: './job-ad-preview.component.html',
  styleUrls: ['./job-ad-preview.component.scss'],
  animations: [expandAnimation]
})
export class JobAdPreviewComponent implements OnInit, OnDestroy {

  @Input() backButton = true;
  job: Job;
  fullDescriptionShown = false;
  company: Company;

  categories: UniversalOption[] = [];
  locations: JobLocation[] = [];
  jobWorkLocations: UniversalOption[] = [];
  typesOfEmployment: UniversalOption[] = [];
  typesOfEmploymentAddition: UniversalOption[] = [];
  users: User[];

  responsiblePerson: User;
  preferredLanguage: string;
  categoryName: string;
  locationName: string;
  typeOfEmploymentName: string;
  typeOfEmploymentAdditionName: string;
  jobWorkLocationName: string;
  videoPosterUrl: string;
  videoMp4Url: string;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private jobStore: JobStoreService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private companyService: CompanyService,
    private userService: UserService,
    private jobService: JobsService,
    private setupService: SetupService
  ) { }

  ngOnInit(): void {
    this.loaderService.show();
    this.job = this.jobStore.job;
    this.setVideoDetails();
    this.company = this.setupService.currentCompany;

    combineLatest([
      this.jobService.getCategories(),
      this.companyService.getLocations(),
      this.jobService.getEmploymentTypes(),
      this.jobService.getEmploymentTypesAddition(),
      this.jobService.getJobWorkLocations(),
      this.userService.getResponsiblePersonsByBranch([this.job.jobInfo.branch])
    ]).pipe(
      takeUntil(this._ngUnsubscribe$)
    ).subscribe(([categories, locations, typesOfEmployment, typesOfEmploymentAddition, jobWorkLocations, users]) => {
        this.categories = categories;
        this.locations = locations;
        this.typesOfEmployment = typesOfEmployment;
        this.typesOfEmploymentAddition = typesOfEmploymentAddition;
        this.jobWorkLocations = jobWorkLocations;
        this.users = users;
        this.preferredLanguage = this.translateService.currentLang;
        this.trackJobStoreChanges();
        this.loaderService.hide();
      });
  }

  setVideoDetails(): void {
    if (this.job.jobDetails.video) {
      this.videoPosterUrl = changeVideoExtension(this.job.jobDetails.video, 'jpg');
      this.videoMp4Url = changeVideoExtension(this.job.jobDetails.video, 'mp4');
    }
  }

  trackJobStoreChanges(): void {
    this.jobStore.job$
      .pipe(
        takeUntil(this._ngUnsubscribe$),
      )
      .subscribe((job: Job) => {
        if (job) {
          this.job = job;
          this.setInfo();
        }
      });
  }

  setInfo(): void {
    const {jobInfo} = this.job;
    this.categoryName = this.categories
      ?.find(({id}) => id === jobInfo.jobCategory)?.name[this.preferredLanguage];
    this.locationName = this.locations
      ?.find(({id}) => id === jobInfo.jobLocation)?.name[this.company.language];
    this.typeOfEmploymentName = this.typesOfEmployment
      ?.find(({id}) => id === jobInfo.typeOfEmployment)?.name[this.preferredLanguage];
    this.typeOfEmploymentAdditionName = this.typesOfEmploymentAddition
      ?.find(({id}) => id === jobInfo.typeOfEmploymentAddition)?.name[this.preferredLanguage];
    this.jobWorkLocationName = this.jobWorkLocations
      ?.find(({id}) => id === jobInfo.jobWorkLocation)?.name[this.preferredLanguage];
    this.responsiblePerson = this.users
      ?.find(({email}) => email === jobInfo.responsiblePerson.email);
  }

  toggleFullDescription(): void {
    this.fullDescriptionShown = !this.fullDescriptionShown;
  }

  back(): void {
    this.jobStore.setActiveStep(1);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

}
