import { RequisitionDinamicControl, IRequisitionForm } from '../model/requisition.model';
import { User, UserCommunicator } from '../model/user.interface';
import { ENTERPRISE_ROLES } from '../resources/roles';

export class RequisitionFormClass {

  usersThatApprove?: Partial<User>[];
  title: string;
  dinamicForm: RequisitionDinamicControl[];
  id?: number;
  creator?: UserCommunicator | string;
  createdAt?: Date;
  lastEditedBy?: UserCommunicator | string;

  constructor(requisitionForm: IRequisitionForm, companyName: string) {
    const customersToApprove = requisitionForm.customersWhoCanApproveCreatedRequisitionsFromForm as Partial<User>[] || [];
    const enterpriseUserToApprove = requisitionForm.enterpriseManagersWhoCanApproveCreatedRequisitionFromForm as Partial<User>[] || [];
    this.usersThatApprove = [...customersToApprove, ...enterpriseUserToApprove];
    this.title = requisitionForm.requisitionFormTitle;
    this.dinamicForm = requisitionForm.requisitionFormElements;
    this.id = requisitionForm.id;
    this.createdAt = requisitionForm.createdAt;

    this.lastEditedBy = requisitionForm.lastEditedByCustomer || requisitionForm.lastEditedByEnterpriseManager;

    if (this.lastEditedBy) {
      this.lastEditedBy.anonymous = this.lastEditedBy.anonymousCustomer || this.lastEditedBy.anonymousEnterpriseManager;
      this.lastEditedBy = this.lastEditedBy.anonymous ? companyName : this.lastEditedBy.name;
    }

    this.creator = (requisitionForm.creatorCustomer?.anonymousCustomer || requisitionForm.creatorEnterpriseManager?.anonymousEnterpriseManager) ?
                      companyName : (requisitionForm.creatorCustomer?.name || requisitionForm.creatorEnterpriseManager?.name);
  }

  static prepareForPublish(requisitionFormClass: RequisitionFormClass, companyId?: number): IRequisitionForm {
    const customersWhoCanApproveCreatedRequisitionsFromForm = [];
    const enterpriseManagersWhoCanApproveCreatedRequisitionFromForm = [];

    requisitionFormClass.usersThatApprove.forEach(user => {
      if (ENTERPRISE_ROLES.includes(user.roles[0])) {
        enterpriseManagersWhoCanApproveCreatedRequisitionFromForm.push(user.id);
      } else {
        customersWhoCanApproveCreatedRequisitionsFromForm.push(user.id);
      }
    });

    const requisitionForm: IRequisitionForm = {
      requisitionFormTitle: requisitionFormClass.title,
      requisitionFormElements: requisitionFormClass.dinamicForm,
      customersWhoCanApproveCreatedRequisitionsFromForm: customersWhoCanApproveCreatedRequisitionsFromForm,
      enterpriseManagersWhoCanApproveCreatedRequisitionFromForm: enterpriseManagersWhoCanApproveCreatedRequisitionFromForm
    };

    if (companyId) {
      requisitionForm.company = companyId;
    }

    return requisitionForm;
  }
}
