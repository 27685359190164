import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JobStoreService } from 'src/app/services/job-store.service';
import { QUIZ_MODULES } from 'src/app/resources/quiz-modules';
import { CheckDeviceService } from 'src/app/services/check-device.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { UploadImageService } from 'src/app/services/upload-image.service';
import { expandAnimation } from 'src/app/modules/shared/animations/expand.animation';
import { Answer } from 'src/app/model/job.interface';
import { changeVideoExtension } from 'src/app/resources/video-url-transformation-functions';

@Component({
  selector: 'app-quiz-preview',
  templateUrl: './quiz-preview.component.html',
  styleUrls: ['./quiz-preview.component.scss'],
  animations: [expandAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizPreviewComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() enableEdit: boolean;
  @Input() quizType: QUIZ_MODULES;

  @ViewChild('tagInput') tagInput: ElementRef;

  questions: FormGroup[];
  quizModules = QUIZ_MODULES;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private dragulaService: DragulaService,
    private jobStore: JobStoreService,
    private checkDevice: CheckDeviceService,
    private uploadImageService: UploadImageService,
    private loaderService: LoaderService,
    private uploadFilesService: UploadFilesService,
    private cdr: ChangeDetectorRef
  ) { }

  get isMobile(): boolean {
    return this.checkDevice.isMobile();
  }

  get questionsForm(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  get inhouseQuestion(): FormGroup {
    return this.form.get('question') as FormGroup;
  }

  ngOnInit(): void {
    this.questions = this.quizType === QUIZ_MODULES.INHOUSE_QUESTION
      ? [this.inhouseQuestion]
      : this.questionsForm.controls as FormGroup[];

    if (this.quizType === QUIZ_MODULES.BUSINESS_QUESTIONS) {
      this.setupDragAndDrop();
    }
  }

  setupDragAndDrop(): void {
    this.dragulaService.createGroup('QUIZPREVIEWQUESTIONS', {
      moves: (el: Element, container: Element, handle: Element) => {
        return handle.className.includes('handle-drag-and-drop');
      }
    });

    this.dragulaService.dropModel('QUIZPREVIEWQUESTIONS')
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((response) => {
        const questions = response.targetModel.map(control => control.value);
        const data = {
          ...this.form.value,
          ...{questions}
        };
        const quizModules = {
          ...this.jobStore.getJobFromSessionStorage()?.quizModules,
          ...{[QUIZ_MODULES.BUSINESS_QUESTIONS]: data},
        };
        this.jobStore.updateJob({quizModules});
        this.cdr.detectChanges();
      });
  }

  toggleAddTag(answer: Answer): void {
    if (!this.enableEdit) {
      return;
    }

    answer.isTagVisible = true;
    this.cdr.detectChanges();

    setTimeout(() => {
      this.tagInput.nativeElement.focus();
      this.cdr.detectChanges();
    });
  }

  focusOutFunction(answer: Answer, event: Event): void {
    event.stopPropagation();
    answer.isTagVisible = false;
    this.cdr.detectChanges();
  }

  getPosterImage(videoUrl: string): string {
    return changeVideoExtension(videoUrl, 'jpg');
  }

  ngOnDestroy(): void {
    if (this.quizType === QUIZ_MODULES.BUSINESS_QUESTIONS) {
      this.dragulaService.destroy('QUIZPREVIEWQUESTIONS');
    }

    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
