import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
@Directive({ selector: '[allowForRoles]' })
export class AllowForRolesDirective implements OnInit {

  @Input() set allowForRoles(roles: string[]) {
    if (!!roles || roles.length) {
      this.allowedRoles = roles;
    }
  }

  allowedRoles: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    if (!this.allowedRoles.length) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }

    if (this.viewContainer.length) {
      return;
    }

    const isAllowed = this.allowedRoles.includes(this.userService.role);
    isAllowed ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}
