import { NgClass, NgFor, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CountryCallingCode } from 'src/app/model/country-calling-codes.model';
import { ContactUsService } from 'src/app/services/contact-us.service';

@Component({
  selector: 'app-phone-edit',
  templateUrl: './phone-edit.component.html',
  styleUrls: ['./phone-edit.component.scss'],
  imports: [TranslateModule, ReactiveFormsModule, NgClass, NgIf, NgFor],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneEditComponent implements OnInit {

  @Input() phoneNumber = '';
  @Input() form: FormGroup;
  @Input() defaultCountry: string;

  @Output() callingCodes: EventEmitter<CountryCallingCode[]> = new EventEmitter<CountryCallingCode[]>();

  phoneNumberMaxLength: number;
  phoneNumberMinLength: number;
  countryCallingCodes: CountryCallingCode[];

  constructor(
    private fb: FormBuilder,
    private contactUsService: ContactUsService,
    private cdr: ChangeDetectorRef
  ) { }

  get phone(): FormControl {
    return this.form.get('phone') as FormControl;
  }

  get networks(): FormControl {
    return this.form.get('networks') as FormControl;
  }

  ngOnInit(): void {
    this.getCountryCallingCodes();
  }

  getCountryCallingCodes(): void {
    this.contactUsService.getCountryCallingCodes()
      .subscribe((codes: CountryCallingCode[]) => {
        this.countryCallingCodes = codes;
        this.callingCodes.emit(codes);
        this.initializeCountryCodes();
        this.setNetwork();
        this.cdr.detectChanges();
      });
  }

  initializeCountryCodes(): void {
    const countryCode = this.countryCallingCodes
      .find(({callingCode, country}: CountryCallingCode) => {
        if (this.defaultCountry) {
          return country === this.defaultCountry;
        }

        return this.phoneNumber.startsWith(callingCode);
      });

    if (!countryCode) {
      this.form.patchValue({networks: this.countryCallingCodes[0]});
      return;
    }

    const phone = this.phoneNumber.replace(countryCode.callingCode, '');

    this.form.patchValue({ networks: countryCode, phone });
  }

  setNetwork(): void {
    this.phoneNumberMaxLength = this.networks.value.maxLength;
    this.phoneNumberMinLength = this.networks.value.minLength;

    this.phone.setValidators([
      Validators.minLength(this.phoneNumberMinLength),
      Validators.maxLength(this.phoneNumberMaxLength),
      Validators.required,
      Validators.pattern('^[1-9][0-9]*$'),
    ]);

    this.phone.updateValueAndValidity();
  }
}
