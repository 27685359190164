import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, Subject, takeUntil } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { AbstractModal } from '../../modal/abstract-modal';
import { LoaderService } from 'src/app/services/loader.service';
import { AgreementModal } from 'src/app/classes/modal.class';
import { AgreementMode, AgreementStatus, ControlType } from 'src/app/model/agreement.model';
import { AgreementModalData } from 'src/app/model/modal.interface';
import { AgreementService } from 'src/app/services/agreement.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgreementComponent extends AbstractModal implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren('textarea') textarea: QueryList<ElementRef>;
  form: FormGroup = this.fb.group({
    dinamicForm: this.fb.array([])
  });

  modal: AgreementModal;
  mode: AgreementMode;
  data: AgreementModalData;
  AGREEMENT_MODE = AgreementMode;
  CONTROL_TYPE = ControlType;
  AGEREEMENT_STATUS = AgreementStatus;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  get dinamicForm(): FormArray {
    return this.form.controls['dinamicForm'] as FormArray;
  }

  constructor(
    modalService: ModalService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private agreementService: AgreementService,
    private loaderService: LoaderService,
    protected errorHandlingService: ErrorHandlingService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.data = this.modal.data;

    if (this.router.url.includes('email-reply')) {
      this.mode = AgreementMode.blank;
    } else {
      this.mode = AgreementMode.preview;
    }

    this.setDinamicForm();
  }

  ngAfterViewInit(): void {
    if (this.mode === AgreementMode.preview) {
      const textareaList = this.textarea.toArray();
      textareaList.forEach((text) => {
        this.onTextAreaInput(text.nativeElement);
      });
    }
  }

  setDinamicForm(): void {
    this.data.dinamicForm.forEach((control) => {

      let values = [];

      if (Array.isArray(control.values) && control.values?.length && control.elementType === ControlType.dropdown) {
        values = control.values.map((value, index) => {
          if (this.mode === AgreementMode.preview) {
            return { id: index, value: value, disabled: true };
          } else {
            return { id: index, value: value };
          }
        });
      }

      const controlValue = this.fb.group({
        elementType: [control.elementType],
        elementLabel: [control.elementLabel],
        insertedValue: [
          {
            value: control.insertedValue || '',
            disabled: this.mode === AgreementMode.preview
          }
        ],
        values: [control.elementType === ControlType.dropdown ? values : control.values],
        selectedValues: [control.selectedValues || []],
        insertedSignature: [control.insertedSignature ? control.insertedSignature : ''],
        required: control.required
      });

      if (
        control.elementType === ControlType.dropdown && control.required && this.mode !== AgreementMode.preview
      ) {
        controlValue.get('selectedValues').addValidators([Validators.required]);
      }

      if (control.elementType === ControlType.text && control.required) {
        controlValue.get('insertedValue').addValidators([Validators.required]);
      }

      if (control.elementType === ControlType.signature &&
        this.mode !== AgreementMode.preview && control.required) {
        controlValue.get('insertedSignature').addValidators([Validators.required]);
      }
      this.dinamicForm.push(controlValue);
    });
  }

  submitForm(): void {

    const { value, valid } = this.form;
    this.form.markAllAsTouched();

    if (!valid) {
      return;
    }

    this.loaderService.show();

    this.agreementService.editAgreement(value, this.modal.data.guid)
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        finalize(() => {
          this.loaderService.hide();
          this.modal.data.close();
          this.closeModal();
        })
      )
      .subscribe(() => {
        this.toastr.success(
          this.translateService.instant(
            'AGREEMENT_FORM.AGREEMENT_CREATED_SUCCESSFULLY'
          )
        );
      });
  }

  onTextAreaInput(textarea: HTMLElement): void {
    if (textarea) {
      textarea.style.overflow = 'hidden';
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
