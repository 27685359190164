import { ICandidateApplication } from './application.interface';
import { IHiringStatus } from './hiring-status.interface';
import { UserCommunicator } from './user.interface';

export interface AlvalabsJob extends IBaseAlvaJob {
  created: string;
  createdFrom: string;
  externalId: string;
  id: number;
  name: string;
}

export interface AlvalabsCandidate {
  candidatePageUrl: string;
  created: Date;
  createdFrom: string;
  externalUserId: string;
  id: number;
  job: AlvalabsJob;
  roleFit: AlvalabsRolefit;
  user: AlvalabsUser;
}

export interface AlvalabsRolefit {
  isCompleted: boolean;
  label: string;
  score: number;
}

export interface AlvalabsUser {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  logicTestResult: AlvalabsLogicTestResult;
  personalityTestResult: AlvaPersonalityTestResult;
}

export interface AlvalabsLogicTestResult {
  completedAt: Date;
  mu: number;
  sigma: number;
}

export interface AlvaPersonalityTestResult {
  completedAt: Date;
  a: number;
  ai: number;
  aii: number;
  aiii: number;
  c: number;
  ci: number;
  cii: number;
  ciii: number;
  e: number;
  ei: number;
  eii: number;
  eiii: number;
  es: number;
  esi: number;
  esii: number;
  esiii: number;
  o: number;
  oi: number;
  oii: number;
  oiii: number;
}

export interface AlvaTestProfile {
  created: Date;
  id: string;
  modified: Date;
  name: string;
}

export interface AlvaNewCandidate {
  createdAt: Date;
  createdFrom: string;
  externalJobId: string;
  externalUserId: string;
  jobId: number;
  signupLink: string;
  userId: number;
}

export interface AlvaNewRoleFit {
  externalJobId: string;
  externalUserId: string;
  isCompleted: boolean;
  jobId: number;
  label: 'VERY_LOV'| 'LOW' | 'MEDIUM'| 'HIGH' | 'VERY_HIGH';
  score: number;
  userId: number;
}

export enum AlvaScoreMatch {
  'VERY_LOW' = 'ALVALABS.SCORE_MATCHES.VERY_LOW',
  'LOW' = 'ALVALABS.SCORE_MATCHES.LOW',
  'MEDIUM' = 'ALVALABS.SCORE_MATCHES.MEDIUM',
  'HIGH' = 'ALVALABS.SCORE_MATCHES.HIGH',
  'VERY_HIGH' = 'ALVALABS.SCORE_MATCHES.VERY_HIGH'
}

export interface AlvaAssessment {
  candidateInfo: AlvaNewCandidate;
  createdAt: Date;
  exportedByCustomer: UserCommunicator;
  exportedByEnterpriseManager: UserCommunicator;
  guid: string;
  hiringStatusDependentOnScore: IHiringStatus;
  id: number;
  newRoleFitCompletedAtUtc: Date;
  roleFitResult: AlvaNewRoleFit;
  scoreLimit: number;
  scoreThreshold: 'above' | 'below';
  updateSeen: boolean;
}

export interface IAlvaResponse extends AlvaAssessment {
  application: Partial<ICandidateApplication>;
  updatedAt: Date;
}

export interface AlvaLabsData extends IBaseAlvaJob {
  generateSignupLink: boolean;
  newHiringStatusId: number;
  scoreLimit: number;
  scoreThreshold: string;
}

export interface IBaseAlvaJob {
  testProfileId: string;
  requireLogicTest: boolean;
  requirePersonalityTest: boolean;
}

export interface CreateAlvaCandidatePayload {
  alvaHiringStatus: number;
  alvaScoreLimit: number;
  alvaScoreThreshold: string;
}

export interface AlvaExportedInfo {
  numberOfCandidatesWhoWereExportedToAlva: number;
  numberOfCandidatesForExportingToAlva: number;
  applicationsThatWereNotExported: {
    id: number;
    candidate: string;
  }[];
}







