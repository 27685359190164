<section>
  <div class="button-close-wrapper">
    <i class="icon-cancel-in-circle" (click)="closeModal();"></i>
  </div>
  <header class="header">
    <h3 class="header-title">
      {{ 'SIDEBAR.YOUR_REQUISITIONS' | translate}}
    </h3>
  </header>
  <div class="panel">
    <div (click)="!requisition.attached ? openRequisition(requisition) : removeRequisition()" *ngFor="let requisition of requisitions" class="requisition-row">
      <div class="requisition-title">
      <span>
        {{ requisition.title }}
      </span>
      </div>
      <div>
        <i *ngIf="!requisition.attached"
          class="icon icon-plus-in-circle"></i>
        <i *ngIf="requisition.attached"
          class="icon icon-bin">
        </i>
      </div>
    </div>
  </div>
</section>
