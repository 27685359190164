import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Trigger } from '../model/trigger.interface';
import { environment } from '../../environments/environment';
import { TimeZone } from '../model/time-zone.interface';

@Injectable({
  providedIn: 'root'
})
export class TriggerService {

  private _timeZones: TimeZone[];

  constructor(
    private http: HttpClient,
  ) { }

  setTriggerPayload(trigger: Trigger): Trigger {
    return {
      smsContent: null,
      emailContent: null,
      exportedTilTidEmployeeSetting: null,
      alvaLabsData: null,
      refappConfiguration: null,
      ...trigger
    };
  }

  addTrigger(trigger: Trigger): Observable<any> {
    return this.http.post(environment.applicationsHiringStatusTrigger, this.setTriggerPayload(trigger));
  }

  removeTrigger(triggerId: number): Observable<any> {
    return this.http.delete(`${environment.applicationsHiringStatusTrigger}/${triggerId}`);
  }

  editTrigger(trigger: Trigger, triggerId: number): Observable<any> {
    return this.http.put(`${environment.applicationsHiringStatusTrigger}/${triggerId}`, this.setTriggerPayload(trigger));
  }

  getTrigger(triggerId: number): Observable<any> {
    return this.http.get(`${environment.applicationsHiringStatusTrigger}/${triggerId}`);
  }

  getTimeZones(): Observable<TimeZone[]> {
    const origin = window.location.origin;

    if (this._timeZones) {
      return of(this._timeZones);
    }

    return this.http.get<TimeZone[]>(`${origin}/assets/timezones.json`)
      .pipe(
        tap((timeZones: TimeZone[]) => this._timeZones = timeZones)
      );
  }
}
