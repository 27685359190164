<div class="custom-modal-wrapper">
  <div class="close-icon-wrapper">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <div class="custom-modal scrollable-flex-container">
    <h3 class="modal-title">
      {{ title | translate }}
    </h3>
    <div class="modal-body scrollable-flex-container">
      <ul class="tabs">
        <li *ngFor="let tab of tabs" class="tab" [ngClass]="{active: tab.active}" (click)="selectTab(tab)">
          {{tab.tabTitle}}
        </li>
      </ul>
      <div class="tab-content scrollable-flex-container" [ngClass]="{'no-tabs': !tabs || tabs.length === 0}">
        <div class="file-list">
          <span class="no-data-info" *ngIf="!libraryData || libraryData.length === 0">{{ 'SHARED.NO_DATA' | translate }}</span>
          <ng-container *ngFor="let data of libraryData; let i = index ">
            <div class="library-data" *ngIf="displayAll || i < 4">
              <div class="preview-image-wrapper">
                <video *ngIf="isVideo"
                        class="preview-image"
                        controlsList="nodownload"
                        disablePictureInPicture="true"
                        preload="none"
                        [poster]="data.videoPoster"
                        (click)="openInFullScreen($event.target)">
                  <source [src]="data.video">
                </video>
                <img *ngIf="!isVideo" [src]="data.picture" class="preview-image"/>
              </div>
              <button type="button"
                      class="btn-icon btn-remove-library-data"
                      (click)="removeData(data)"
                      *allowForRoles="[rolesEnum.companyHR, rolesEnum.enterpriseHR, rolesEnum.enterpriseRM]">
                <i class="icon-bin"></i>
              </button>
              <label class="custom-checkbox orange-checkbox">
                <input
                      type="radio"
                      name="language"
                      class="form-control radio-button"
                      (change)="selectFile(isVideo ? data.video : data.picture)" />
                <span class="checkmark"></span>
              </label>
            </div>
          </ng-container>

          <div class="show-more" *ngIf="!displayAll && libraryData && libraryData.length > 0">
            <button (click)="showMoreData()" class="btn-link">
              <i class="icon-chevron-right"></i>
              {{ 'BUTTONS.SHOW_MORE' | translate }}
            </button>
          </div>
        </div>
      </div>

    </div>
    <div class="button-wrapper">
      <button (click)="closeModal()"
              class="btn btn-outline-primary btn-back">
        <i class="icon icon-arrow-left"></i>
        <span>{{ "BUTTONS.CANCEL" | translate }}</span>
      </button>
      <button class="btn btn-primary border-white"
              (click)="useFile()">
        {{ "BUTTONS.APPLY" | translate }}
      </button>
    </div>

  </div>
</div>
