import { ChangeDetectionStrategy, Component } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractModal } from '../abstract-modal';
import { ModalService } from 'src/app/services/modal.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdatePhoneModalData } from 'src/app/model/modal.interface';
import { RecruitmentService } from 'src/app/services/recruitment.service';

@Component({
  selector: 'app-update-phone-modal',
  templateUrl: './update-phone-modal.component.html',
  styleUrls: ['./update-phone-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdatePhoneModalComponent extends AbstractModal {

  phoneNumberForm: FormGroup = this.fb.group({
    networks: [null, [Validators.required]],
    phone: [''],
  });

  constructor(
    modalService: ModalService,
    private loaderService: LoaderService,
    private recruitmentService: RecruitmentService,
    private errorHandlingService: ErrorHandlingService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {
    super(modalService);
  }

  get data(): UpdatePhoneModalData {
    return this.modal.data;
  }

  updatePhone(): void {
    this.phoneNumberForm.markAllAsTouched();

    if (!this.phoneNumberForm.valid) {
      return;
    }

    const { networks, phone } = this.phoneNumberForm.value;
    const newPhoneNumber = networks.callingCode + phone;

    if (newPhoneNumber === this.data.phoneNumber) {
      this.closeModal();
      return;
    }

    this.loaderService.show();

    this.recruitmentService
      .updatePhone(this.data.guid, newPhoneNumber)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      )
      .subscribe(() => {
        this.data.phoneUpdated(newPhoneNumber);
        this.loaderService.hide();
        this.closeModal();
        this.toastr.success(
          this.translateService.instant('UPLOAD_RESUME.PHONE_UPDATED_SUCCESSFULLY')
        );
      });
  }
}
