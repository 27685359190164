<div class="questions-container" *ngIf="quizType === quizModules.BUSINESS_QUESTIONS" dragula="QUIZPREVIEWQUESTIONS" [(dragulaModel)]="questions">
  <ng-container [ngTemplateOutlet]="quizPreviewQuestions"></ng-container>
</div>
<div class="questions-container" *ngIf="quizType !== quizModules.BUSINESS_QUESTIONS">
  <ng-container [ngTemplateOutlet]="quizPreviewQuestions"></ng-container>
</div>

<ng-template #quizPreviewQuestions>
  <div class="question-wrapper" [ngClass]="{ 'disable-edit': !enableEdit }" *ngFor="let question of questions">
    <ng-container [ngTemplateOutlet]="skillsQuestionNode"
                  [ngTemplateOutletContext]="{ $implicit: question }">
    </ng-container>
  </div>
</ng-template>


<ng-template #skillsQuestionNode let-questionform let-isfollowupquestion="isfollowupquestion">
  <div [ngClass]="{'is-following': isfollowupquestion}">
  <div class="media-wrapper" *ngIf="quizType === quizModules.BUSINESS_QUESTIONS">
    <ng-container *ngIf="!enableEdit">
      <img *ngIf="questionform.get('image').value as image"
           [src]="image"
           class="media-preview" />

      <video *ngIf="questionform.get('video').value as video"
             class="media-preview"
             [poster]="getPosterImage(video)"
             preload="metadata"
             controls>
        <source [src]="video" />
      </video>
    </ng-container>
    <app-media-upload [form]="questionform"
                      *ngIf="enableEdit"
                      [imageUploadContext]="'business_question_image'"
                      [picture]="questionform.get('image')"
                      [video]="questionform.get('video')"></app-media-upload>
  </div>
  <h2 class="question-title" *ngIf="!enableEdit">{{ questionform.get('text').value }}</h2>
  <div *ngIf="enableEdit" class="title-line">
    <div class="form-group question-title-edit">
      <input
        class="form-control"
        [formControl]="questionform.get('text')"
        [ngClass]="{
          'is-invalid': questionform.errors?.noImageOrText && questionform.get('text').touched === true
        }"
      />
      <div class="invalid-feedback">{{'JOBS.NO_IMAGE_OR_TEXT' | translate}}</div>
    </div>
    <span *ngIf="quizType === quizModules.BUSINESS_QUESTIONS && !isMobile && questions.length > 1 && !isfollowupquestion"
          class="btn-handle-drag-and-drop">
      <i class="icon-drag handle-drag-and-drop"></i>
    </span>
  </div>

  <ng-container *ngFor="let answer of questionform.get('answers').controls">
    <div class="form-group answer">
    <span>{{ answer.get('text').value }}</span>
    <input
      class="form-control answer-input"
      [formControl]="answer.get('text')"
      [ngClass]="{
        'is-invalid': answer.get('text').invalid &&
                      answer.get('text').touched
      }"
    />
    <i *ngIf="answer.get('tag')?.value || enableEdit"
       [ngClass]="[answer.get('tag')?.value ? 'icon-tag-filled' : 'icon-tag', enableEdit ? 'editing' : '']"
       [tooltip]="enableEdit ? '' : answer.get('tag')?.value"
       (click)="toggleAddTag(answer)"></i>

      <div class="form-group add-tag-container" *ngIf="answer.isTagVisible">
      <input type="text"
              #tagInput
              class="form-control add-tag-input"
              [placeholder]="'SHARED.ADD_TAG_HERE' | translate"
              [formControl]="answer.get('tag')"
              (focusout)="focusOutFunction(answer, $event)"
              (keydown.enter)="focusOutFunction(answer, $event)" />
      </div>
    <div class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}</div>
  </div>
  <ng-container *ngIf="answer.contains('followUpQuestion')"
                [ngTemplateOutlet]="skillsQuestionNode"
                [ngTemplateOutletContext]="{ $implicit: answer.get('followUpQuestion'), isfollowupquestion: true }">
  </ng-container>
  </ng-container>
</div>
</ng-template>
