import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { CountryCallingCode } from '../model/country-calling-codes.model';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {

  private callingCodes: CountryCallingCode[];

  constructor(
    private http: HttpClient,
  ) {}

  getCountryCallingCodes(): Observable<CountryCallingCode[]> {
    const origin = window.location.origin;

    if (this.callingCodes) {
      return of(this.callingCodes);
    }

    return this.http.get<CountryCallingCode[]>(`${origin}/assets/country-calling-codes.json`);
  }
}
