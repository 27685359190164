<section>
  <div class="button-close-wrapper">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <header class="header">
    <h3 class="header-title">
      {{data?.title}}
    </h3>
  </header>
  <div class="panel">
    <h2>{{'AGREEMENT_FORM.TERMS_OF_AGREEMENT_TITLE' | translate }}</h2>
    <p [innerHTML]="data?.terms | domSanitizer: 'html'"></p>
  </div>
  <div class="panel">
    <form [formGroup]="form">
      <div class="dinamic-form-wrapper" formArrayName="dinamicForm">
          <div>
            <div class="dinamic-form col-md-6"  *ngFor="let control of dinamicForm.controls; let i = index">
              <div [formGroup]="control">
                <label [ngClass]="{'required': control.get('required').value && control.get('elementType').value !== CONTROL_TYPE.info}">
                  {{ control.get('elementLabel').value }}<em *ngIf="control.get('required').value && control.get('elementType').value !== CONTROL_TYPE.info"> {{ 'SHARED.REQUIRED' | translate }}</em>
                </label>
                <div class="form-group" *ngIf="control.get('elementType').value === CONTROL_TYPE.dropdown">
                  <ng-select
                    #select
                    bindLabel="value"
                    bindValue="id"
                    [ngClass]="{'is-invalid': control.get('selectedValues').invalid && control.get('selectedValues').touched}"
                    [items]="control.get('values').value"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [notFoundText] = "'USER.NO_ITEMS_FOUND_MESSAGE' | translate"
                    formControlName="selectedValues"
                    (blur)="select.close()">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <label class="custom-checkbox">
                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" [disabled]="item$.selected"/>
                          {{item.value}}
                        <span class="checkmark  square"></span>
                      </label>
                    </ng-template>
                  </ng-select>
                  <div class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.SELECT_OPTION' | translate }}</div>
                </div>
                <div class="form-group" *ngIf="control.get('elementType').value === CONTROL_TYPE.text">
                  <textarea
                    #textarea
                    rows="1"
                    (input)="onTextAreaInput(textarea)"
                    class="form-control"
                    formControlName="insertedValue"
                    [ngClass]="{'is-invalid': control.get('insertedValue').invalid && control.get('insertedValue').touched}">
                  </textarea>
                  <div class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}</div>
                </div>
                <app-signature
                [form]="control"
                controlName='insertedSignature'
                [isPreview]="mode === AGREEMENT_MODE.preview"
                *ngIf="control.get('elementType').value === CONTROL_TYPE.signature && (data.status !== AGEREEMENT_STATUS.filledIn || (data.status === AGEREEMENT_STATUS.filledIn && !control.get('insertedSignature').value))"></app-signature>
                <img class="signature-image" *ngIf="control.get('elementType').value === CONTROL_TYPE.signature && control.get('insertedSignature').value && data.status === AGEREEMENT_STATUS.filledIn" [src]="control.get('insertedSignature').value" alt="signature">
                <p *ngIf="control.get('elementType').value === CONTROL_TYPE.info" [innerHTML]="control.get('values').value | domSanitizer: 'html'"></p>
              </div>
            </div>
          </div>
        </div>
    </form>
  </div>
  <div class="panel control-panel" *ngIf="mode === AGREEMENT_MODE.blank">
    <button type="button"
            (click)="submitForm()"
            class="btn btn-primary" >
      <span>{{'BUTTONS.SIGN' | translate}}</span>
    </button>
  </div>
</section>
