<section [ngClass]="{ 'modal': data }">
  <app-job-ad-preview [backButton]="!data"></app-job-ad-preview>

  <div class="quiz-modules-container">
    <ng-container *ngFor="let module of quizModules">
      <div class="quiz-module" *ngIf="orderOfQuestionsModules.includes(module.name)" [ngStyle]="{order: findModuleIndex(module.name)}">
        <div class="quiz-module-line"></div>
        <header class="quiz-module-header">
          <div class="quiz-module-title" (click)="module.expand = !module.expand">
            <div class="chevron-right" [ngClass]="{ open: module.expand }">
              <i class="icon-chevron-right"></i>
            </div>
            <h2 class="section-title">
              {{ module.title | translate }}
            </h2>
          </div>
          <i
            class="icon-pencil-outline"
            [ngClass]="{ 'pencil-red': module.enableEdit }"
            *ngIf="module.expand"
            (click)="enableEdit(module)"
          ></i>
        </header>

        <div [@expandAnimation]="module.expand">
          <div class="panel">
            <app-quiz-preview
              *ngIf="module.name === quizModuleNames.INHOUSE_QUESTION"
              [form]="inhouseQuestion"
              [enableEdit]="module.enableEdit"
              [quizType]="module.name"></app-quiz-preview>
            <app-quiz-preview
              *ngIf="module.name === quizModuleNames.KNOCKOUT_QUESTIONS"
              [form]="knockoutQuestions"
              [enableEdit]="module.enableEdit"
              [quizType]="module.name"></app-quiz-preview>
            <app-quiz-preview
              *ngIf="module.name === quizModuleNames.BUSINESS_QUESTIONS"
              [form]="businessQuestions"
              [enableEdit]="module.enableEdit"
              [quizType]="module.name"></app-quiz-preview>
            <app-psykometrika-preview
              *ngIf="module.name === quizModuleNames.PERSONALITY_TEST"
              [form]="personalityTest"
              [enableEdit]="module.enableEdit"></app-psykometrika-preview>
            <app-video-preview
              *ngIf="module.name === quizModuleNames.VIDEO_QUESTIONS"
              [form]="videoQuestions"
              [enableEdit]="module.enableEdit"></app-video-preview>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="button-wrapper" *ngIf="data">
    <button (click)="closeModal()"
    type="button"
    class="btn btn-outline-primary">{{ 'SIDEBAR.CLOSE_BUTTON' | translate}}</button>
  </div>
</section>
