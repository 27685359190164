import { PerformActionComponents } from '../model/perform-action.interface';
import { IHiringStatus, IHiringStatusTrigger } from '../model/hiring-status.interface';
import { IJob, BaseHiringStatus, JobIds } from '../model/job.interface';
import { FormArray } from '@angular/forms';
import { Trigger } from '../model/trigger.interface';

export class HiringStatus {

  color: string;
  companyTrigger: HiringStatusTrigger;
  id: number;
  jobTriggers: HiringStatusTrigger[] = [];
  name: string;
  order: number;
  nameModified: boolean;
  hasTriggers = false;
  activeJobTriggers: HiringStatusTrigger[];
  hasRefappTrigger = false;
  activeJobsExcluded: IJob[];
  draftTriggers: HiringStatusTrigger[] = [];
  templateTriggers: HiringStatusTrigger[] = [];

  private readonly excludedJobs: IJob[];
  private readonly excludedUniversalJob: IJob;

  constructor(status: IHiringStatus) {
    this.setTriggers(status);
    this.name = status.name;
    this.color = status.color;
    this.id = status.id;
    this.order = status.presentationOrder;
    this.nameModified = status.nameModified;
    this.excludedJobs = status.jobsThatIgnoreTriggers;
    this.excludedUniversalJob = status.universalJobsThatIgnoreTriggers[0];
  }

  setActiveJobsTriggerInfo(activeJobs: JobIds): void {
    this.setActiveJobTriggers(activeJobs);
    this.setHasTriggers();
    this.setHasRefappTrigger();
    this.setActiveJobsExcluded(activeJobs);
  }

  private setActiveJobTriggers(activeJobs: JobIds): void {
    this.activeJobTriggers = this.jobTriggers
      .filter((trigger: HiringStatusTrigger) => {
        const jobId = trigger.job.id.toString();

        if (trigger.isUniversalJobTrigger) {
          return activeJobs.universalJobId === jobId;
        }

        return activeJobs.jobIds?.includes(jobId);
      });
  }

  private setHasTriggers(): void {
    this.hasTriggers = !!this.companyTrigger || !!this.activeJobTriggers.length;
  }

  private setHasRefappTrigger(): void {
    this.hasRefappTrigger = this.companyTrigger?.actionType === PerformActionComponents.refapp ||
         !!this.activeJobTriggers.find(({actionType}: HiringStatusTrigger) => actionType === PerformActionComponents.refapp);
  }

  private excludedActiveJobs(activeJobsIds: string[]): IJob[] {
    return this.excludedJobs
      .filter(({id}: IJob) => {
        return !!activeJobsIds?.find((jobId: string) => jobId === id.toString());
      });
  }

  private setActiveJobsExcluded(activeJobs: JobIds): void {
    this.activeJobsExcluded = this.excludedActiveJobs(activeJobs.jobIds);

    if (!!activeJobs.universalJobId && this.excludedUniversalJob?.id.toString() === activeJobs.universalJobId) {
      this.activeJobsExcluded.push(this.excludedUniversalJob);
    }
  }

  private setTriggers(status: IHiringStatus): void {
    status.applicationHiringStatusTriggers
      .forEach((trigger: IHiringStatusTrigger) => {
        if (trigger.companyTrigger) {
          this.companyTrigger = {
            id: trigger.id,
            actionType: trigger.actionType,
            nextHiringStatus: trigger.nextHiringStatus,
            nextHiringStatusDelayInMinutes: trigger.nextHiringStatusDelayInMinutes
          };

          return;
        }

        const job = trigger.job || trigger.universalJob;

        if (job) {
          this.jobTriggers.push({
            job: job,
            id: trigger.id,
            actionType: trigger.actionType,
            isUniversalJobTrigger: !!trigger.universalJob,
            nextHiringStatus: trigger.nextHiringStatus,
            nextHiringStatusDelayInMinutes: trigger.nextHiringStatusDelayInMinutes
          });

          return;
        }
      });
  }
}

export class HiringStatusTrigger {
  id?: number;
  actionType?: PerformActionComponents;
  job?: IJob;
  isUniversalJobTrigger?: boolean;
  nextHiringStatus?: HiringStatus;
  nextHiringStatusDelayInMinutes?: number;
}

export class HiringStatusesLoop {

  static jobTriggersFormForSpecificJob: FormArray;

  constructor() { }

  static checkCompanyTriggersForLoop(statuses: HiringStatus[], newTrigger: HiringStatusTrigger, currentStatus: HiringStatus): boolean {

    const companyTriggersWithNextHiringStatus = this.setTriggersMapForCompanyTriggers(statuses, newTrigger, currentStatus);
    const company = this.checkIfLoop(companyTriggersWithNextHiringStatus, currentStatus);

    if (company) {
      return company;
    }

    const jobTriggersWithNextHiringStatus = this.setMapForEntity(statuses, 'jobTriggers', 'job');

    const job = this.checkIfMixedLoop(jobTriggersWithNextHiringStatus, companyTriggersWithNextHiringStatus, currentStatus);

    if (job) {
      return job;
    }

    return false;
  }

  static checkIfMixedLoop(map: Map<number, Map<number, number>>, companyTriggersWithNextHiringStatus: Map<number, number>, currentStatus: HiringStatus): boolean {
    let loop = false;
    if (map.size) {
      map.forEach((value) => {
        if (this.checkIfLoop(companyTriggersWithNextHiringStatus, currentStatus, value)) {
          loop = true;
        }
      });
    }
    return loop;
  }

  static checkJobTriggersForLoop(statuses: HiringStatus[], newTrigger: HiringStatusTrigger, currentStatus: HiringStatus): boolean {
    const companyTriggersWithNextHiringStatus = this.setTriggersMapForCompanyTriggers(statuses, newTrigger, currentStatus);
    const jobTriggersWithNextHiringStatus = this.setTriggersMapForSpecificJobTriggers(newTrigger, currentStatus);
    return this.checkIfLoop(companyTriggersWithNextHiringStatus, currentStatus, jobTriggersWithNextHiringStatus );
  }

  private static setTriggersMapForSpecificJobTriggers(
    newTrigger: HiringStatusTrigger,
    currentStatus: HiringStatus
  ): Map<number, number> {
    const jobTriggers: Trigger[] = this.jobTriggersFormForSpecificJob.value;
    const triggersWithNextHiringStatus: Map<number, number> = new Map<number, number>();

    jobTriggers
      .forEach((jobTrigger: Trigger) => {
        if (!jobTrigger.nextHiringStatus) {
          return;
        }

        const trigger: Trigger = { ...jobTrigger };
        const nextHiringStatus = trigger.nextHiringStatus as BaseHiringStatus;
        const triggerHiringStatus = trigger.applicationHiringStatus as BaseHiringStatus;

        if (triggerHiringStatus.id === currentStatus.id && nextHiringStatus?.id !== newTrigger.nextHiringStatus.id) {
          trigger.nextHiringStatus = newTrigger.nextHiringStatus;
        }

        triggersWithNextHiringStatus.set(triggerHiringStatus.id, (trigger.nextHiringStatus as BaseHiringStatus).id);
      });

    if (!triggersWithNextHiringStatus.has(currentStatus.id)) {
      triggersWithNextHiringStatus.set(currentStatus.id, newTrigger.nextHiringStatus.id);
    }

    return triggersWithNextHiringStatus;
  }

  private static setTriggersMapForCompanyTriggers(
    statuses: HiringStatus[],
    newTrigger: HiringStatusTrigger,
    currentStatus: HiringStatus
  ): Map<number, number> {
    const triggersWithNextHiringStatus: Map<number, number> = new Map<number, number>();

    statuses
      .forEach((hiringStatus: HiringStatus) => {
        if (!hiringStatus.companyTrigger?.nextHiringStatus) {
          return;
        }

        const trigger: HiringStatusTrigger = { ...hiringStatus.companyTrigger };

        if (hiringStatus.id === currentStatus.id && trigger.nextHiringStatus?.id !== newTrigger.nextHiringStatus.id) {
          trigger.nextHiringStatus = newTrigger.nextHiringStatus;
        }

        triggersWithNextHiringStatus.set(hiringStatus.id, trigger.nextHiringStatus.id);
      });

    if (!triggersWithNextHiringStatus.has(currentStatus.id)) {
      triggersWithNextHiringStatus.set(currentStatus.id, newTrigger.nextHiringStatus.id);
    }

    return triggersWithNextHiringStatus;
  }

  private static setMapForEntity(
    statuses: HiringStatus[],
    entityTrigger: string,
    entity: string
  ): Map<number, Map<number, number>> {

    const triggersWithNextHiringStatus: Map<number, Map<number, number>> = new Map<number, Map<number, number>>();

    statuses
    .forEach((hiringStatus: HiringStatus) => {

      if (!hiringStatus[entityTrigger]) {
        return;
      }

      hiringStatus[entityTrigger].forEach((triger: HiringStatusTrigger) => {
        if (!triger.nextHiringStatus) {
          return;
        }
        const triggerMap: Map<number, number> = new Map<number, number>();

        if (triggersWithNextHiringStatus.has(triger[entity].id)) {
          triggersWithNextHiringStatus.get(triger[entity].id).set(hiringStatus.id, triger.nextHiringStatus.id);
        } else {
          triggerMap.set(hiringStatus.id, triger.nextHiringStatus.id);
          triggersWithNextHiringStatus.set(triger[entity].id, triggerMap);
        }
      });
    });

    return triggersWithNextHiringStatus;
  }

  private static checkIfLoop(
    companyTriggersWithNextHiringStatus: Map<number, number>,
    currentStatus: HiringStatus,
    jobTriggersWithNextHiringStatus?: Map<number, number>
  ): boolean {
    const set = new Set<number>();
    let currentStatusId = currentStatus.id;
    let triggersWithNextHiringStatus = companyTriggersWithNextHiringStatus;

    while (currentStatusId) {
      if (set.has(currentStatusId)) {
        return true;
      }

      set.add(currentStatusId);

      currentStatusId = triggersWithNextHiringStatus.get(currentStatusId);

      if (!currentStatusId) {
        if (companyTriggersWithNextHiringStatus && triggersWithNextHiringStatus === companyTriggersWithNextHiringStatus) {
          triggersWithNextHiringStatus = jobTriggersWithNextHiringStatus;
        } else if (jobTriggersWithNextHiringStatus && triggersWithNextHiringStatus === jobTriggersWithNextHiringStatus) {
          triggersWithNextHiringStatus = companyTriggersWithNextHiringStatus;
        }
        const lastValue = Array.from(set).pop();
        currentStatusId = triggersWithNextHiringStatus?.get(lastValue);
      }
    }

    return false;
  }
}
