import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(dateString: string | Date): string {
    const date = new Date(dateString);
    return date.getHours() + ':' + (date.getMinutes()<10?'0':'') + date.getMinutes();
  }
}
