import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Question } from '../model/job.interface';
import { environment } from 'src/environments/environment';
import { Pagination } from '../model/pagination.interface';
import { SetupService } from './setup.service';
import { UniversalOption } from '../model/universal-option.interface';

export interface SkillsQuestionFilters {
  category?: string;
  language?: string;
  job?: string;
  jobTemplate?: string;
}

export interface PredefinedSkillsQuestionFilters {
  category?: string;
  subcategory?: string;
}

export interface PredefinedCategory {
  uuid: string;
  name: string;
  children: PredefinedCategory[];
  level?: number;
}

@Injectable({ providedIn: 'root' })
export class QuizService {
  constructor(private http: HttpClient, private setupService: SetupService) {}

  getSkillsQuestions(
    filters: SkillsQuestionFilters,
    page: number,
    perPage: number,
    hidden?: boolean
  ): Observable<Pagination<{ question: Question }>> {
    let params = new HttpParams();

    if (page) {
      params = params.append('page', page.toString());
    } else {
      params = params.append('page', '1');
    }

    if (perPage) {
      params = params.append('limit', perPage.toString());
    }

    if (filters.category) {
      params = params.append('jobCategory', filters.category);
    }

    if (filters.language) {
      params = params.append('languageCode', filters.language);
    }

    if (filters.job) {
      params = params.append('job', filters.job);
    }

    if (filters.jobTemplate) {
      params = params.append('jobTemplate', filters.jobTemplate);
    }

    if (hidden) {
      params = params.append('hidden', '1');
    }

    return this.http.get<Pagination<{ question: Question }>>(
      `${environment.companies}/${this.setupService.companyGuid}/job_business_questions_library`,
      { params }
    );
  }

  getPredefinedSkillsQuestions(
    filters: PredefinedSkillsQuestionFilters,
    page: number,
    perPage: number
  ): Observable<Pagination<{ question: Question; questionImportance: number }>> {
    let params = new HttpParams();

    if (page) {
      params = params.append('page', page.toString());
    } else {
      params = params.append('page', '1');
    }

    if (perPage) {
      params = params.append('limit', perPage.toString());
    }

    if (filters.category) {
      params = params.append('predefinedBusinessQuestionCategory', filters.category);
    }

    if (filters.subcategory) {
      params = params.append('predefinedBusinessQuestionSubcategory', filters.subcategory);
    }

    return this.http.get<Pagination<{ question: Question; questionImportance: number }>>(
      `${environment.companies}/${this.setupService.companyId}/predefined_business_questions`,
      { params }
    );
  }

  getPredefinedCategories(): Observable<UniversalOption[]> {
    return this.http.get<UniversalOption[]>(
      `${environment.companies}/${this.setupService.companyId}/predefined_business_question_categories`
    );
  }

  getPredefinedSubcategories(categoryId: number): Observable<UniversalOption[]> {
    let params = new HttpParams();
    params = params.append('predefinedBusinessQuestionCategory', categoryId);

    return this.http.get<UniversalOption[]>(
      `${environment.companies}/${this.setupService.companyId}/predefined_business_question_subcategories`,
      { params }
    );
  }

  hideUnhideQuestionFromLibrary(questionId: number): Observable<any> {
    return this.http.post(
      `${environment.companies}/${this.setupService.companyGuid}/job_business_questions_library/status/${questionId}`,
      null
    );
  }
}
