import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRequisition, IRequisitionForm} from '../model/requisition.model';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pagination } from '../model/pagination.interface';
import { RequisitionFormClass } from '../classes/requisition-form.class';
import { SetupService } from './setup.service';
import { RequisitionClass } from '../classes/requisition.class';

@Injectable({ providedIn: 'root' })
export class RequisitionService {

  constructor(
    private http: HttpClient,
    private setupService: SetupService
  ) {}

  getRequisitions(areOthersRequisitions: boolean,
                  status: string,
                  searchBy?: string,
                  page?: number,
                  limit?: number,
                  notConnectedToJob?: boolean): Observable<Pagination<RequisitionClass>> {
    let params = new HttpParams();

    if (page) {
      params = params.append('page', page.toString());
    } else {
      params = params.append('page', '1');
    }

    if (limit) {
      params = params.append('limit', limit.toString());
    } else {
      params = params.append('limit', '10');
    }

    params = params.append('requisitionStatus', status);

    params = params.append('company', this.setupService.companyId);

    if (searchBy) {
      params = params.append('searchByRequisitionTitle', searchBy);
    }

    params = params.append('collectionType', areOthersRequisitions ? 'forReview' : 'createdByEmployee');

    if (notConnectedToJob) {
      params = params.append('hasConnectionWithJob', false);
    }

    return this.http.get<Pagination<IRequisition>>(`${environment.requisitions}`, { params }).pipe(
      map((response: Pagination<IRequisition>) => {
        const newResponse: Pagination<RequisitionClass> = {
          page: response.page,
          total: response.total,
          timestamp: response.timestamp,
          perPage: response.perPage,
          data: []
        };
        response.data.forEach((requisition: IRequisition) => {
          newResponse.data.push(new RequisitionClass(requisition, this.setupService.companyName));
        });
        return newResponse;
      })
    );
  }

  getRequisition(requisitionId: number): Observable<RequisitionClass> {
    return this.http.get<IRequisition>(`${environment.requisitions}/${requisitionId}`).pipe(
      map((requisition: IRequisition) => new RequisitionClass(requisition, this.setupService.companyName))
    );
  }

  getRequisitionFormList(canEdit: boolean, searchBy?: string, page?: number, limit?: number): Observable<Pagination<RequisitionFormClass>> {
    let params = new HttpParams();

    if (page) {
      params = params.append('page', page.toString());
    } else {
      params = params.append('page', '1');
    }

    if (limit) {
      params = params.append('limit', limit.toString());
    } else {
      params = params.append('limit', '10');
    }

    if (searchBy) {
      params = params.append('searchByRequisitionFormTitle', searchBy);
    }

    params = params.append('collectionType', canEdit ? 'editableByEmployee' : 'availableForRequisitionCreation');

    params = params.append('company', this.setupService.companyId);

    return this.http.get<Pagination<IRequisitionForm>>(`${environment.requisitionForms}`, { params })
      .pipe(
        map((response: Pagination<IRequisitionForm>) => {
          const newResponse: Pagination<RequisitionFormClass> = {
            page: response.page,
            total: response.total,
            timestamp: response.timestamp,
            perPage: response.perPage,
            data: []
          };
          response.data.forEach((requisitionForm: IRequisitionForm) => {
            newResponse.data.push(new RequisitionFormClass(requisitionForm, this.setupService.companyName));
          });
          return newResponse;
        })
      );
  }

  getRequisitionForm(requisitionFormId: number): Observable<RequisitionFormClass> {
    return this.http.get<IRequisitionForm>(`${environment.requisitionForms}/${requisitionFormId}`).pipe(
      map((requisitionForm: IRequisitionForm) => new RequisitionFormClass(requisitionForm, this.setupService.companyName))
    );
  }

  deleteRequisitionForm(requisitionFormId: number): Observable<Object> {
    return this.http.delete(`${environment.requisitionForms}/${requisitionFormId}`);
  }

  createRequisitonForm(requisitionForm: RequisitionFormClass): Observable<IRequisitionForm> {
    return this.http.post<IRequisitionForm>(`${environment.requisitionForms}`, RequisitionFormClass.prepareForPublish(requisitionForm, this.setupService.companyId));
  }

  editRequisitonForm(requisitionForm: RequisitionFormClass, id: number): Observable<IRequisitionForm> {
    return this.http.put<IRequisitionForm>(`${environment.requisitionForms}/${id}`, RequisitionFormClass.prepareForPublish(requisitionForm));
  }

  createRequisition(requisition: RequisitionClass, requisitionFormId?: number): any {
    // eslint-disable-next-line max-len
    return this.http.post<IRequisition>(`${environment.requisitions}`, RequisitionClass.prepareForCreate(requisition, requisitionFormId));
  }

  editRequisition(requisition: RequisitionClass, id: number): Observable<IRequisition> {
    return this.http.put<IRequisition>(`${environment.requisitions}/${id}`, RequisitionClass.prepareForCorrecting(requisition));
  }

  reviewRequisition(requisition: RequisitionClass, id: number, requisitionStatus: string): Observable<IRequisition> {
    return this.http.put<IRequisition>(`${environment.requisitions}/${id}`, RequisitionClass.prepareForReviewing(requisition, requisitionStatus));
  }
}
